.region-content {
    .row {
        .col-md-11.title-right {
            margin-bottom: 10px;
            .pane-content {
                h1 {
                    margin-top: 0;
                    border-bottom: none;
                    margin-bottom: 10px;
                    padding-bottom: 0;
                }
            }
        }
        .col-md-1.title-left {
            padding-right: 0;
            // display: none;i
            .image-actual {
                margin-right: 0;
                padding-right: 0;
                // width: 100%;
                height: inherit;
                @include bp(s) {
                    // width: 100%;
                }
                @include bp(m) {
                    width: 100%;
                }
                @include bp(l) {
                    width: 100%;
                }
            }
        }
        .field-name-title-field.field-label-hidden {
            h2 {
                display: none;
            }
        }
    }
}