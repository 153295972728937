@charset "UTF-8";
@-webkit-keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%,
  100%,
  80% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.listings-view ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .listings-view ul li {
    display: block;
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid #ddd; }
    .listings-view ul li a {
      padding: 10px 20px 10px 0;
      display: block; }
    .listings-view ul li:first-child {
      padding-top: 0; }
    .listings-view ul li:last-child {
      padding-bottom: 0; }

body {
  background: #ffffff; }

iframe {
  max-width: 100%; }

.btn.btn-secondary {
  background: #E2A731;
  color: black;
  border-radius: 0;
  font-size: 1.8rem;
  padding: 10px 20px;
  margin-top: 10px; }

.front .homepage-chair {
  background: #480302;
  padding: 15px; }
  .front .homepage-chair img {
    width: 100%;
    height: auto; }
  .front .homepage-chair ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center; }
    .front .homepage-chair ul li {
      margin: 0;
      padding: 0;
      display: inline-block; }
      .front .homepage-chair ul li a {
        background: #E2A731;
        color: black;
        border-radius: 0;
        font-size: 1.8rem;
        padding: 10px 20px;
        display: block;
        margin: 10px 10px 0 10px;
        text-decoration: none; }

.front .cta-home-top .pane-bean-home-page-slider {
  display: block;
  padding: 5px;
  background: #ffffff; }

.front .cta-home-top .right a {
  display: block;
  padding: 5px;
  background: #ffffff;
  margin-bottom: 10px; }
  .front .cta-home-top .right a img {
    width: 100%; }

.front #wb-info {
  margin-top: 0; }

.front .bg-white {
  padding: 20px 0;
  background: #ffffff; }
  .front .bg-white h2 {
    /* font-size: 2rem; */ }
  .front .bg-white .btn.btn-primary {
    background: #E2A731;
    color: black;
    border-radius: 0;
    font-size: 1.8rem;
    border: none;
    padding: 10px 20px; }

.front .bg-gray {
  padding: 10px 0;
  background: #eee; }

.front .bg-red {
  padding: 50px 0;
  background: #7a0806; }
  .front .bg-red .btn.btn-primary {
    background: #E2A731;
    color: black;
    border-radius: 0;
    font-size: 1.8rem;
    border: none;
    padding: 10px 20px; }
  .front .bg-red blockquote {
    color: #ffffff;
    font-size: 3.2rem;
    line-height: 3.4rem;
    font-weight: bold;
    border-color: #EEDE6F; }

#wb-srch button,
.srchbox button {
  background: #7a0806; }

header .brand img,
header .brand object {
  max-height: 80px; }
  @media (max-width: 767px) {
    header .brand img,
    header .brand object {
      max-width: 100%; } }

header {
  background-color: rgba(194, 229, 244, 0.8); }
  @media (max-width: 767px) {
    header {
      padding-top: 25px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    header .brand {
      margin-top: 0px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    header .brand {
      margin-top: -20px; } }
  @media (min-width: 1200px) {
    header .brand {
      margin-top: -35px;
      margin-bottom: 25px; } }

.gc-nttvs {
  margin-top: 30px;
  margin-bottom: 30px; }

footer#wb-info {
  margin-top: 30px;
  background-color: #ebf8fa;
  border-top: none; }
  body footer#wb-info {
    border-top: inherit;
    color: black; }
  footer#wb-info .pane-bean-about-us,
  footer#wb-info .pane-bean-contact-us,
  footer#wb-info .pane-menu-wet-mid-footer {
    margin-bottom: 25px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .pane-bean-about-us,
      footer#wb-info .pane-bean-contact-us,
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    @media (min-width: 1200px) {
      footer#wb-info .pane-bean-about-us,
      footer#wb-info .pane-bean-contact-us,
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    footer#wb-info .pane-bean-about-us .pane-title,
    footer#wb-info .pane-bean-contact-us .pane-title,
    footer#wb-info .pane-menu-wet-mid-footer .pane-title {
      margin-bottom: 15px;
      font-size: 22px;
      font-weight: 700;
      overflow: unset;
      position: relative;
      height: inherit;
      width: inherit;
      color: black; }
    footer#wb-info .pane-bean-about-us .pane-content .field-name-field-bean-wetkit-body,
    footer#wb-info .pane-bean-contact-us .pane-content .field-name-field-bean-wetkit-body,
    footer#wb-info .pane-menu-wet-mid-footer .pane-content .field-name-field-bean-wetkit-body {
      font-size: 15px; }
  footer#wb-info .pane-menu-wet-mid-footer {
    margin-bottom: 25px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    @media (min-width: 1200px) {
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    footer#wb-info .pane-menu-wet-mid-footer .pane-title {
      margin-top: 0; }
    footer#wb-info .pane-menu-wet-mid-footer .menu-block-ctools-menu-wet-mid-footer-1 .nav li a {
      color: black;
      text-decoration: underline; }
  footer#wb-info .brand {
    border-top: none;
    background-color: white; }
    footer#wb-info .brand nav.ftr-urlt-lnk .links {
      list-style-type: none; }
      footer#wb-info .brand nav.ftr-urlt-lnk .links li {
        margin-left: 0; }
        footer#wb-info .brand nav.ftr-urlt-lnk .links li:before {
          content: none; }
        footer#wb-info .brand nav.ftr-urlt-lnk .links li a {
          list-style: none; }
    footer#wb-info .brand .col-xs-6.col-md-2.text-right {
      padding-top: 0px; }
      @media (min-width: 768px) and (max-width: 991px) {
        footer#wb-info .brand .col-xs-6.col-md-2.text-right {
          padding-top: 0px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        footer#wb-info .brand .col-xs-6.col-md-2.text-right {
          padding-top: 0px; } }
  footer#wb-info .openplus-50_25_25 {
    padding-bottom: 25px; }
    @media (max-width: 767px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        padding-right: 15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        padding-right: 55px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        padding-right: 55px;
        border-right: 1px solid #B9E0E3; } }
    @media (min-width: 1200px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        border-right: 1px solid #B9E0E3;
        padding-right: 55px; } }
    @media (max-width: 767px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        padding-left: 15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        padding-left: 15px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        padding-left: 55px;
        border-right: 1px solid #B9E0E3; } }
    @media (min-width: 1200px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        border-right: 1px solid #B9E0E3;
        padding-left: 55px; } }
    @media (max-width: 767px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 15px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 55px; } }
    @media (min-width: 1200px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 55px; } }
  footer#wb-info .field-item .coat-of-arms-logo {
    display: block;
    margin-bottom: 25px; }

.front main {
  padding-top: 70px; }

@media (max-width: 767px) {
  main .region-content {
    padding-left: 15px;
    padding-right: 15px; } }

.block-system .pane-bean-know-your-rights-header .field-name-field-bean-wetkit-body .field-item {
  padding-top: 10px; }
  .block-system .pane-bean-know-your-rights-header .field-name-field-bean-wetkit-body .field-item p {
    font-size: 18px; }

.block-system .pane-bean-know-your-rights-obligations {
  margin-bottom: 25px; }
  .block-system .pane-bean-know-your-rights-obligations .pane-title {
    display: none; }
  .block-system .pane-bean-know-your-rights-obligations .pane-content .field-name-field-bean-wetkit-body .field-item h3 {
    margin-bottom: 20px; }

.block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ebf8fa;
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 50px;
  font-size: 19px;
  color: black;
  list-style: none;
  border: none; }
  @media (max-width: 767px) {
    .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited {
      padding-left: 15px;
      font-size: 16px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited {
      padding-right: 120px; } }
  @media (min-width: 1200px) {
    .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited {
      padding-right: 120px; } }
  .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited:before {
    content: " "; }
  .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited:after {
    position: absolute;
    top: 23%;
    right: 15px;
    content: ' ';
    margin-left: 15px;
    width: 20px;
    height: 20px;
    background-image: url("../images/icons/up_arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block; }
    @media (min-width: 768px) and (max-width: 991px) {
      .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited:after {
        top: 40%;
        font-size: 19px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited:after {
        top: 43%;
        font-size: 19px; } }
    @media (min-width: 1200px) {
      .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited:after {
        top: 40%;
        font-size: 19px; } }
  .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited:hover {
    color: #802F2D; }
  .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited .image-actual {
    float: left;
    margin-right: 28px;
    width: 80px;
    height: auto;
    max-width: 40px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited .image-actual {
        max-width: 40px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited .image-actual {
        max-width: 60px; } }
    @media (min-width: 1200px) {
      .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited .image-actual {
        max-width: 80px; } }
  .block-system .view-rights-list .view-content .accordion .acc-group summary.wb-toggle-inited::-webkit-details-marker {
    display: none; }

.block-system .view-rights-list .view-content .accordion .acc-group .tgl-panel {
  padding-top: 15px;
  padding-bottom: 15px; }
  .block-system .view-rights-list .view-content .accordion .acc-group .tgl-panel p {
    font-size: 16px; }
  .block-system .view-rights-list .view-content .accordion .acc-group .tgl-panel a.btn-primary {
    color: black;
    font-weight: 700;
    width: 100%;
    background-color: #B9E0E3; }
    .block-system .view-rights-list .view-content .accordion .acc-group .tgl-panel a.btn-primary:hover {
      background-color: #9BD3DD; }

.block-system .view-rights-list .view-content .accordion .acc-group.on {
  border: none; }
  .block-system .view-rights-list .view-content .accordion .acc-group.on summary.wb-toggle-inited {
    color: black;
    background-color: #B9E0E3;
    border-bottom: 10px solid #802F2D; }
    .block-system .view-rights-list .view-content .accordion .acc-group.on summary.wb-toggle-inited:after {
      position: absolute;
      top: 22px;
      right: 15px;
      content: ' ';
      margin-left: 15px;
      width: 20px;
      height: 20px;
      background-image: url("../images/icons/down_arrow.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block; }
      @media (min-width: 768px) and (max-width: 991px) {
        .block-system .view-rights-list .view-content .accordion .acc-group.on summary.wb-toggle-inited:after {
          top: 30px;
          font-size: 19px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .block-system .view-rights-list .view-content .accordion .acc-group.on summary.wb-toggle-inited:after {
          top: 40px;
          font-size: 19px; } }
      @media (min-width: 1200px) {
        .block-system .view-rights-list .view-content .accordion .acc-group.on summary.wb-toggle-inited:after {
          top: 43px;
          font-size: 19px; } }

.region-content .row .col-md-11.title-right {
  margin-bottom: 10px; }
  .region-content .row .col-md-11.title-right .pane-content h1 {
    margin-top: 0;
    border-bottom: none;
    margin-bottom: 10px;
    padding-bottom: 0; }

.region-content .row .col-md-1.title-left {
  padding-right: 0; }
  .region-content .row .col-md-1.title-left .image-actual {
    margin-right: 0;
    padding-right: 0;
    height: inherit; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .region-content .row .col-md-1.title-left .image-actual {
        width: 100%; } }
    @media (min-width: 1200px) {
      .region-content .row .col-md-1.title-left .image-actual {
        width: 100%; } }

.region-content .row .field-name-title-field.field-label-hidden h2 {
  display: none; }

#wb-bc {
  background-color: white; }
  #wb-bc .container .row .breadcrumb {
    margin-top: 50px;
    padding-left: 15px; }
    @media (min-width: 768px) and (max-width: 991px) {
      #wb-bc .container .row .breadcrumb {
        padding-left: 0px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      #wb-bc .container .row .breadcrumb {
        padding-left: 0px; } }
    @media (min-width: 1200px) {
      #wb-bc .container .row .breadcrumb {
        padding-left: 0px; } }
    #wb-bc .container .row .breadcrumb li a {
      color: black;
      text-decoration: none; }
      #wb-bc .container .row .breadcrumb li a:hover {
        text-decoration: underline; }
    #wb-bc .container .row .breadcrumb li:first-child a {
      text-transform: uppercase; }

.pane-bean-contact-us .media-element-container {
  margin-right: 10px;
  margin-top: 0; }
  .pane-bean-contact-us .media-element-container .file-image .wetkit-image-quarter {
    max-width: 25px; }

header .field-name-field-bean-wetkit-body {
  padding-left: 20px;
  padding-right: 20px;
  min-height: 355px; }
  @media (min-width: 992px) and (max-width: 1199px) {
    header .field-name-field-bean-wetkit-body {
      min-height: 420px; } }
  @media (min-width: 1200px) {
    header .field-name-field-bean-wetkit-body {
      min-height: 300px; } }
  header .field-name-field-bean-wetkit-body .row .hero-header,
  header .field-name-field-bean-wetkit-body .row .hero-heading {
    margin-top: 25px;
    color: black;
    font-size: 46px;
    font-weight: bold;
    line-height: initial; }
  header .field-name-field-bean-wetkit-body .row p {
    margin-top: 25px;
    font-size: 18px; }
  header .field-name-field-bean-wetkit-body .row .col-md-6 .media-element-container,
  header .field-name-field-bean-wetkit-body .row .col-md-3 .media-element-container {
    position: relative; }
    header .field-name-field-bean-wetkit-body .row .col-md-6 .media-element-container .file-image img.img-responsive,
    header .field-name-field-bean-wetkit-body .row .col-md-3 .media-element-container .file-image img.img-responsive {
      margin-top: 40px;
      margin-bottom: 40px; }
      @media (min-width: 992px) and (max-width: 1199px) {
        header .field-name-field-bean-wetkit-body .row .col-md-6 .media-element-container .file-image img.img-responsive,
        header .field-name-field-bean-wetkit-body .row .col-md-3 .media-element-container .file-image img.img-responsive {
          position: absolute;
          top: -50px;
          margin-top: 40px;
          margin-bottom: inherit; } }
      @media (min-width: 1200px) {
        header .field-name-field-bean-wetkit-body .row .col-md-6 .media-element-container .file-image img.img-responsive,
        header .field-name-field-bean-wetkit-body .row .col-md-3 .media-element-container .file-image img.img-responsive {
          position: absolute;
          top: -135px;
          right: -80px;
          margin-top: 40px;
          margin-bottom: inherit;
          max-width: 110%;
          width: 110%; } }

header .row.hero-block {
  padding-left: 20px;
  padding-right: 20px;
  min-height: 220px; }
  @media (min-width: 992px) and (max-width: 1199px) {
    header .row.hero-block {
      min-height: 220px; } }
  @media (min-width: 1200px) {
    header .row.hero-block {
      min-height: 220px; } }
  header .row.hero-block .hero-left {
    padding-left: 0; }
  header .row.hero-block .hero-header,
  header .row.hero-block .hero-heading {
    color: black;
    font-size: 37px;
    font-weight: bold;
    line-height: initial;
    margin-top: 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      header .row.hero-block .hero-header,
      header .row.hero-block .hero-heading {
        font-size: 46px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      header .row.hero-block .hero-header,
      header .row.hero-block .hero-heading {
        font-size: 46px; } }
    @media (min-width: 1200px) {
      header .row.hero-block .hero-header,
      header .row.hero-block .hero-heading {
        font-size: 46px; } }
  header .row.hero-block p {
    font-size: 18px; }
  header .row.hero-block .col-md-6 .hero-wordmark {
    width: 100px;
    position: absolute;
    top: 120px;
    right: -40px;
    margin-top: 40px;
    margin-bottom: inherit; }
    @media (min-width: 768px) and (max-width: 991px) {
      header .row.hero-block .col-md-6 .hero-wordmark {
        float: right;
        position: static;
        margin-top: 0;
        margin-bottom: 20px; } }
    @media (max-width: 767px) {
      header .row.hero-block .col-md-6 .hero-wordmark {
        float: right;
        position: static;
        margin-top: 0;
        margin-bottom: 20px; } }
  header .row.hero-block .col-md-6 .media-element-container,
  header .row.hero-block .col-md-6 .field-name-field-hero-image {
    position: relative; }
    header .row.hero-block .col-md-6 .media-element-container .file-image img.img-responsive,
    header .row.hero-block .col-md-6 .media-element-container .field-item img.img-responsive,
    header .row.hero-block .col-md-6 .field-name-field-hero-image .file-image img.img-responsive,
    header .row.hero-block .col-md-6 .field-name-field-hero-image .field-item img.img-responsive {
      margin-top: 40px;
      margin-bottom: 40px; }
      @media (min-width: 992px) and (max-width: 1199px) {
        header .row.hero-block .col-md-6 .media-element-container .file-image img.img-responsive,
        header .row.hero-block .col-md-6 .media-element-container .field-item img.img-responsive,
        header .row.hero-block .col-md-6 .field-name-field-hero-image .file-image img.img-responsive,
        header .row.hero-block .col-md-6 .field-name-field-hero-image .field-item img.img-responsive {
          position: absolute;
          top: -50px;
          margin-top: 40px;
          margin-bottom: inherit; } }
      @media (min-width: 1200px) {
        header .row.hero-block .col-md-6 .media-element-container .file-image img.img-responsive,
        header .row.hero-block .col-md-6 .media-element-container .field-item img.img-responsive,
        header .row.hero-block .col-md-6 .field-name-field-hero-image .file-image img.img-responsive,
        header .row.hero-block .col-md-6 .field-name-field-hero-image .field-item img.img-responsive {
          position: absolute;
          top: -110px;
          right: -60px;
          margin-top: 40px;
          margin-bottom: inherit;
          max-width: 100%;
          width: 100%; } }

.media-element-container .file-video {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; }
  .media-element-container .file-video .media-youtube-player {
    width: 100%;
    height: auto; }
    @media (min-width: 768px) and (max-width: 991px) {
      .media-element-container .file-video .media-youtube-player {
        min-height: 397px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .media-element-container .file-video .media-youtube-player {
        min-height: 320px; } }
    @media (min-width: 1200px) {
      .media-element-container .file-video .media-youtube-player {
        min-height: 420px;
        margin-bottom: 20px; } }

.panel-pane.pane-node-body .field-name-body button.btn-primary {
  width: 100%;
  background-color: transparent;
  position: relative;
  background-color: #802F2D;
  color: white;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 19px;
  border: 2px solid transparent;
  background-color: white;
  color: #802F2D;
  font-size: 19px;
  border: 2px solid #802F2D;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 5px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .panel-pane.pane-node-body .field-name-body button.btn-primary {
      margin-top: 10px;
      margin-bottom: 5px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .panel-pane.pane-node-body .field-name-body button.btn-primary {
      margin-top: 10px;
      margin-bottom: 5px; } }
  @media (min-width: 1200px) {
    .panel-pane.pane-node-body .field-name-body button.btn-primary {
      margin-top: 5px;
      margin-bottom: 5px; } }
  .panel-pane.pane-node-body .field-name-body button.btn-primary:hover, .panel-pane.pane-node-body .field-name-body button.btn-primary:focus {
    border: 2px solid #802F2D;
    background-color: white;
    color: #802F2D; }
  .panel-pane.pane-node-body .field-name-body button.btn-primary:before {
    position: absolute;
    top: 11.5px;
    right: inherit;
    content: ' ';
    margin-left: -20px;
    width: 17px;
    height: 17px;
    background-image: url("../images/icons/left_arrow_red.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    font-size: 19px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .panel-pane.pane-node-body .field-name-body button.btn-primary:before {
        top: 12.5px;
        right: inherit; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .panel-pane.pane-node-body .field-name-body button.btn-primary:before {
        top: 12.5px;
        right: inherit; } }
    @media (min-width: 1200px) {
      .panel-pane.pane-node-body .field-name-body button.btn-primary:before {
        top: 12.5px;
        right: inherit; } }

.panel-pane.pane-node-body .field-name-body button.btn-default {
  width: 100%;
  background-color: #802F2D;
  position: relative;
  background-color: #802F2D;
  color: white;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 19px;
  border: 2px solid transparent;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 5px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .panel-pane.pane-node-body .field-name-body button.btn-default {
      margin-top: 10px;
      margin-bottom: 5px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .panel-pane.pane-node-body .field-name-body button.btn-default {
      margin-top: 10px;
      margin-bottom: 5px; } }
  @media (min-width: 1200px) {
    .panel-pane.pane-node-body .field-name-body button.btn-default {
      margin-top: 5px;
      margin-bottom: 5px; } }
  .panel-pane.pane-node-body .field-name-body button.btn-default:hover, .panel-pane.pane-node-body .field-name-body button.btn-default:focus {
    border: 2px solid #802F2D;
    background-color: white;
    color: #802F2D; }
    .panel-pane.pane-node-body .field-name-body button.btn-default:hover:after, .panel-pane.pane-node-body .field-name-body button.btn-default:focus:after {
      background-image: url("../images/icons/right_arrow_red.png"); }
  .panel-pane.pane-node-body .field-name-body button.btn-default:after {
    position: absolute;
    top: 11.5px;
    right: inherit;
    content: ' ';
    margin-left: 5px;
    width: 17px;
    height: 17px;
    background-image: url("../images/icons/right_arrow_white.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    font-size: 19px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .panel-pane.pane-node-body .field-name-body button.btn-default:after {
        top: 12.5px;
        right: inherit; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .panel-pane.pane-node-body .field-name-body button.btn-default:after {
        top: 12.5px;
        right: inherit; } }
    @media (min-width: 1200px) {
      .panel-pane.pane-node-body .field-name-body button.btn-default:after {
        top: 12.5px;
        right: inherit; } }

.pane-content .row figure.video .btn-default {
  width: inherit;
  height: inherit;
  padding: 0;
  margin: 0;
  background-color: inherit; }
  .pane-content .row figure.video .btn-default:after {
    content: none; }

.pane-content .row figure.video .tline {
  padding: 0; }

.row figcaption {
  margin-top: 10px; }

.pane-node-webform .webform-client-form .form-type-checkbox {
  margin-top: 15px;
  margin-bottom: 15px; }
  .pane-node-webform .webform-client-form .form-type-checkbox .control-label {
    position: relative;
    font-size: 20px; }

.help-block {
  color: #545454; }

nav#wb-sm {
  background-color: #ebf8fa; }
  nav#wb-sm .row .list-inline.menu {
    border-right: none; }
    nav#wb-sm .row .list-inline.menu .leaf {
      position: relative;
      border-left: none;
      border-right: none;
      font-size: 20px; }
      @media (min-width: 992px) and (max-width: 1199px) {
        nav#wb-sm .row .list-inline.menu .leaf {
          position: relative;
          border-left: none;
          border-right: none;
          font-size: 20px; } }
      @media (min-width: 1200px) {
        nav#wb-sm .row .list-inline.menu .leaf {
          font-size: 24px; } }
      nav#wb-sm .row .list-inline.menu .leaf a {
        position: relative;
        padding-top: 1em;
        padding-bottom: 1em;
        font-weight: 300;
        text-shadow: none;
        color: black !important;
        background-color: transparent !important; }
        @media (min-width: 768px) and (max-width: 991px) {
          nav#wb-sm .row .list-inline.menu .leaf a {
            padding-top: 0.9em;
            padding-bottom: 0.9em; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          nav#wb-sm .row .list-inline.menu .leaf a {
            padding-top: 0.9em;
            padding-bottom: 0.9em; } }
        @media (min-width: 1200px) {
          nav#wb-sm .row .list-inline.menu .leaf a {
            padding-top: 0.75em;
            padding-bottom: 0.75em; } }
        nav#wb-sm .row .list-inline.menu .leaf a.wb-navcurr {
          font-weight: 600;
          color: #802F2D !important; }
          nav#wb-sm .row .list-inline.menu .leaf a.wb-navcurr:before {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            content: ' ';
            border-bottom: 10px solid #802F2D; }
        nav#wb-sm .row .list-inline.menu .leaf a:hover {
          color: #802F2D !important; }
          nav#wb-sm .row .list-inline.menu .leaf a:hover:before {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            content: ' ';
            border-bottom: 10px solid #802F2D; }
        nav#wb-sm .row .list-inline.menu .leaf a:after {
          content: ' ';
          margin-left: 15px;
          width: 20px;
          height: 20px;
          background-image: url("../images/icons/down_arrow.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: inline-block; }
    nav#wb-sm .row .list-inline.menu .active {
      background: transparent !important; }

#gc-bar {
  margin-top: 25px; }
  #gc-bar li {
    font-size: 16px; }
    #gc-bar li a {
      color: black;
      margin-left: 20px;
      font-weight: 600;
      text-decoration: none; }
      #gc-bar li a:visited {
        color: black; }
      #gc-bar li a:hover {
        color: #284162; }

#mb-pnl .srch-pnl {
  display: none; }

#mb-pnl .lng-ofr {
  padding: 10px 22px 8px;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 0; }
  #mb-pnl .lng-ofr .list-inline {
    text-align: left;
    margin-bottom: -17px; }
    #mb-pnl .lng-ofr .list-inline li {
      margin-top: 9px;
      display: inline-block;
      width: 100%; }
      #mb-pnl .lng-ofr .list-inline li a {
        display: inline-block;
        padding: 0 6px;
        width: 100%; }

#mb-pnl .modal-footer .btn-primary:before {
  content: none; }

.glyphicon-search:before {
  content: none; }

.modal-footer {
  margin-top: -50px; }

body {
  font-family: 'Montserrat', sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  border-bottom: none; }

a {
  position: relative; }
  a:hover {
    color: #802F2D; }
  a:visited {
    color: inherit; }
  a.btn.active, a.btn.btn-default {
    border: 2px solid transparent;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #802F2D;
    color: white;
    padding-left: 20px;
    padding-right: 35px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: 500; }
    a.btn.active:hover, a.btn.active:focus, a.btn.btn-default:hover, a.btn.btn-default:focus {
      border: 2px solid #802F2D;
      background-color: white;
      color: #802F2D; }
      a.btn.active:hover:after, a.btn.active:focus:after, a.btn.btn-default:hover:after, a.btn.btn-default:focus:after {
        background-image: url("../images/icons/right_arrow_red.png"); }
    a.btn.active:after, a.btn.btn-default:after {
      position: absolute;
      top: 11.5px;
      right: inherit;
      right: 10px;
      content: ' ';
      margin-left: 5px;
      width: 17px;
      height: 17px;
      background-image: url("../images/icons/right_arrow_white.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      font-size: 19px; }
      @media (min-width: 768px) and (max-width: 991px) {
        a.btn.active:after, a.btn.btn-default:after {
          top: 10.5px;
          right: inherit;
          right: 10px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        a.btn.active:after, a.btn.btn-default:after {
          top: 10.5px;
          right: inherit;
          right: 10px; } }
      @media (min-width: 1200px) {
        a.btn.active:after, a.btn.btn-default:after {
          top: 10.5px;
          right: inherit;
          right: 10px; } }

details summary {
  color: black; }
  details summary:hover {
    color: #802F2D; }

.view-notices-list {
  margin-bottom: 50px; }
  .view-notices-list .view-header a {
    text-transform: uppercase;
    color: black;
    position: absolute;
    right: 0;
    top: 0; }
    .view-notices-list .view-header a:hover {
      color: #802F2D; }
  .view-notices-list .view-content .views-row {
    margin-bottom: 25px;
    background-color: #F2F8F8;
    padding-top: 15px;
    padding-bottom: 15px;
    padding: 15px; }
    .view-notices-list .view-content .views-row .views-field-title-field {
      font-weight: 600;
      margin-bottom: 15px;
      margin-top: 15px; }
      @media (max-width: 767px) {
        .view-notices-list .view-content .views-row .views-field-title-field {
          margin-top: inherit;
          margin-top: 15px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .view-notices-list .view-content .views-row .views-field-title-field {
          margin-top: inherit; } }
      @media (min-width: 1200px) {
        .view-notices-list .view-content .views-row .views-field-title-field {
          margin-top: inherit; } }
      .view-notices-list .view-content .views-row .views-field-title-field a {
        color: black; }
    .view-notices-list .view-content .views-row .views-field-field-featured-image .image-actual {
      width: 100%;
      display: none; }
      @media (min-width: 768px) and (max-width: 991px) {
        .view-notices-list .view-content .views-row .views-field-field-featured-image .image-actual {
          display: none; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .view-notices-list .view-content .views-row .views-field-field-featured-image .image-actual {
          display: inherit; } }
      @media (min-width: 1200px) {
        .view-notices-list .view-content .views-row .views-field-field-featured-image .image-actual {
          display: inherit; } }
    .view-notices-list .view-content .views-row .views-field-body span {
      line-height: 1.59; }
    .view-notices-list .view-content .views-row .views-field-field-published-date {
      font-weight: 700; }
    .view-notices-list .view-content .views-row .views-field-nid .btn-default {
      color: black;
      text-transform: uppercase;
      border: none;
      background-color: inherit;
      padding: 0;
      margin-top: 15px;
      text-decoration: underline; }
      .view-notices-list .view-content .views-row .views-field-nid .btn-default:hover {
        color: #802F2D; }
        .view-notices-list .view-content .views-row .views-field-nid .btn-default:hover:after {
          content: ' ';
          margin-left: 10px;
          width: 13px;
          height: 13px;
          background-image: url("../images/icons/right_arrow_red.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: inline-block; }
      .view-notices-list .view-content .views-row .views-field-nid .btn-default:after {
        content: ' ';
        margin-left: 10px;
        width: 13px;
        height: 13px;
        position: initial;
        background-image: url("../images/icons/right_arrow_black.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block; }

.pane-notices-list-panel-pane-1 {
  margin-top: 50px;
  position: relative; }
  .pane-notices-list-panel-pane-1 .pane-title {
    font-size: 22px;
    text-transform: uppercase;
    color: black; }

.page-air-travel-complaints .pane-page-title h1,
.node-type-form .pane-page-title h1 {
  border-bottom: none; }

.page-air-travel-complaints .webform-component-markup .well,
.node-type-form .webform-component-markup .well {
  background-color: white;
  border: none;
  padding: 0;
  box-shadow: none; }

.page-air-travel-complaints .webform-component-markup .alert,
.page-air-travel-complaints .webform-component-markup .alert.alert-info,
.node-type-form .webform-component-markup .alert,
.node-type-form .webform-component-markup .alert.alert-info {
  background-color: #ebf8fa;
  border: none;
  padding: 20px; }
  .page-air-travel-complaints .webform-component-markup .alert h2,
  .page-air-travel-complaints .webform-component-markup .alert.alert-info h2,
  .node-type-form .webform-component-markup .alert h2,
  .node-type-form .webform-component-markup .alert.alert-info h2 {
    line-height: 1.47;
    margin-left: 0; }
    .page-air-travel-complaints .webform-component-markup .alert h2:before,
    .page-air-travel-complaints .webform-component-markup .alert.alert-info h2:before,
    .node-type-form .webform-component-markup .alert h2:before,
    .node-type-form .webform-component-markup .alert.alert-info h2:before {
      content: ''; }

.page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
.node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1,
.node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 {
  margin-top: 15px;
  display: inline-block;
  float: right;
  margin-bottom: 50px;
  width: 100%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
    .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 {
      width: 100%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
    .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 {
      width: 50%;
      margin-right: -17px; } }
  @media (min-width: 1200px) {
    .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
    .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 {
      width: 50%;
      margin-right: -17px; } }
  .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default,
  .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default,
  .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default {
    position: relative;
    background-color: #802F2D;
    color: white;
    width: 100%;
    font-size: 19px;
    border: 2px solid transparent; }
    .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:hover, .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:focus, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:hover, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:focus,
    .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:hover,
    .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:focus,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:hover,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:focus {
      border: 2px solid #802F2D;
      background-color: white;
      color: #802F2D; }
      .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:hover:after, .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:focus:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:hover:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:focus:after,
      .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:hover:after,
      .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:focus:after,
      .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:hover:after,
      .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:focus:after {
        background-image: url("../images/icons/right_arrow_red.png"); }
    .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after,
    .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after {
      position: absolute;
      top: 11.5px;
      right: inherit;
      content: ' ';
      margin-left: 5px;
      width: 17px;
      height: 17px;
      background-image: url("../images/icons/right_arrow_white.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      font-size: 19px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after,
        .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after,
        .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after {
          top: 12.5px;
          right: inherit; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after,
        .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after,
        .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after {
          top: 12.5px;
          right: inherit; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after,
        .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-default:after,
        .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:after {
          top: 12.5px;
          right: inherit; } }
  .page-air-travel-complaints .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-white, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-white,
  .node-type-form .webform-component-markup.form-item.webform-component--wxt-group--complaint-subject-acc--links-1 .btn-white,
  .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-white {
    display: none; }

.page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3,
.node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
.node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
    .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 {
      width: 100%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
    .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 {
      width: 100%;
      margin-right: 0px; } }
  @media (min-width: 1200px) {
    .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2,
    .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 {
      width: 100%;
      margin-right: 0px; } }
  .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default,
  .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default,
  .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default {
    margin-right: inherit;
    position: relative;
    background-color: #802F2D;
    color: white;
    width: 48%;
    margin-right: 4%; }
    .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child,
    .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child {
      background-color: white;
      color: #802F2D;
      font-size: 19px;
      border: 2px solid #802F2D;
      padding-right: 15px; }
      .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:after, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:after,
      .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:after,
      .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:after {
        content: none; }
      .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:before, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:before,
      .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:before,
      .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:before {
        position: relative;
        top: 2.5px;
        content: ' ';
        margin-left: -20px;
        margin-right: 5px;
        width: 17px;
        height: 17px;
        background-image: url("../images/icons/left_arrow_red.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        font-size: 19px; }
      .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:hover, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:focus, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:hover, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:focus,
      .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:hover,
      .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:focus,
      .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:hover,
      .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:focus {
        border: 2px solid #802F2D;
        background-color: white;
        color: #802F2D; }
        .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:hover:before, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:focus:before, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:hover:before, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:focus:before,
        .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:hover:before,
        .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:first-child:focus:before,
        .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:hover:before,
        .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:first-child:focus:before {
          background-image: url("../images/icons/left_arrow_red.png"); }
    .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:last-child, .page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:last-child,
    .node-type-form .webform-component-markup.webform-component--wxt-group--communications-acc--links-2 .btn-default:last-child,
    .node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 .btn-default:last-child {
      margin-right: 0; }

.page-air-travel-complaints .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3,
.node-type-form .webform-component-markup.webform-component--wxt-group--complaint-details-acc--links-3 {
  margin-bottom: -62px; }

.page-air-travel-complaints .webform-component-markup button.btn-white,
.node-type-form .webform-component-markup button.btn-white {
  margin-top: 10px;
  font-size: 18px;
  background: white;
  font-weight: 600;
  padding: 10px;
  width: 100%;
  -webkit-appearance: none;
  border: 1px solid black;
  border-radius: 3px; }

.page-air-travel-complaints .accordion-open,
.node-type-form .accordion-open {
  margin-top: -47px;
  margin-top: -78px; }

.page-air-travel-complaints .pane-node-webform .form-actions,
.node-type-form .pane-node-webform .form-actions {
  display: inline-block;
  float: right;
  margin-top: 15px;
  margin-bottom: 50px;
  width: 50%; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .page-air-travel-complaints .pane-node-webform .form-actions,
    .node-type-form .pane-node-webform .form-actions {
      width: 50%; } }
  @media (min-width: 1200px) {
    .page-air-travel-complaints .pane-node-webform .form-actions,
    .node-type-form .pane-node-webform .form-actions {
      width: 50%;
      margin-right: -17px; } }
  .page-air-travel-complaints .pane-node-webform .form-actions .btn-default,
  .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary,
  .node-type-form .pane-node-webform .form-actions .btn-default,
  .node-type-form .pane-node-webform .form-actions .btn-primary {
    max-width: 570px;
    position: relative;
    background-color: #802F2D;
    color: white;
    width: 100%;
    font-size: 19px;
    border: 2px solid transparent; }
    .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:hover, .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:focus,
    .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:hover,
    .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:focus,
    .node-type-form .pane-node-webform .form-actions .btn-default:hover,
    .node-type-form .pane-node-webform .form-actions .btn-default:focus,
    .node-type-form .pane-node-webform .form-actions .btn-primary:hover,
    .node-type-form .pane-node-webform .form-actions .btn-primary:focus {
      border: 2px solid #802F2D;
      background-color: white;
      color: #802F2D; }
      .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:hover:after, .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:focus:after,
      .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:hover:after,
      .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:focus:after,
      .node-type-form .pane-node-webform .form-actions .btn-default:hover:after,
      .node-type-form .pane-node-webform .form-actions .btn-default:focus:after,
      .node-type-form .pane-node-webform .form-actions .btn-primary:hover:after,
      .node-type-form .pane-node-webform .form-actions .btn-primary:focus:after {
        background-image: url("../images/icons/right_arrow_red.png"); }
    .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:after,
    .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:after,
    .node-type-form .pane-node-webform .form-actions .btn-default:after,
    .node-type-form .pane-node-webform .form-actions .btn-primary:after {
      position: absolute;
      top: 11.5px;
      right: inherit;
      content: ' ';
      margin-left: 5px;
      width: 17px;
      height: 17px;
      background-image: url("../images/icons/right_arrow_white.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      font-size: 19px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:after,
        .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:after,
        .node-type-form .pane-node-webform .form-actions .btn-default:after,
        .node-type-form .pane-node-webform .form-actions .btn-primary:after {
          top: 12.5px;
          right: inherit; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:after,
        .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:after,
        .node-type-form .pane-node-webform .form-actions .btn-default:after,
        .node-type-form .pane-node-webform .form-actions .btn-primary:after {
          top: 12.5px;
          right: inherit; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .pane-node-webform .form-actions .btn-default:after,
        .page-air-travel-complaints .pane-node-webform .form-actions .btn-primary:after,
        .node-type-form .pane-node-webform .form-actions .btn-default:after,
        .node-type-form .pane-node-webform .form-actions .btn-primary:after {
          top: 12.5px;
          right: inherit; } }

.page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions,
.node-type-form .pane-node-webform .webform-client-form.preview .form-actions {
  width: 100%;
  margin-right: inherit; }
  .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-previous,
  .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-submit,
  .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-previous,
  .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-submit {
    width: 48%;
    margin-right: 0; }
    @media (min-width: 1200px) {
      .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-previous,
      .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-submit,
      .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-previous,
      .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-submit {
        width: 49%; } }
  .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-previous,
  .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-previous {
    margin-right: 4%;
    float: left;
    background-color: white;
    color: #802F2D;
    font-size: 19px;
    border: 2px solid #802F2D; }
    @media (min-width: 1200px) {
      .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-previous,
      .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-previous {
        margin-right: 2.5%; } }
    .page-air-travel-complaints .pane-node-webform .webform-client-form.preview .form-actions .webform-previous:after,
    .node-type-form .pane-node-webform .webform-client-form.preview .form-actions .webform-previous:after {
      content: none; }

.page-air-travel-complaints .pane-node-webform .webform-client-form.preview .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
.page-air-travel-complaints .pane-node-webform .webform-client-form.preview .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
.node-type-form .pane-node-webform .webform-client-form.preview .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
.node-type-form .pane-node-webform .webform-client-form.preview .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
  margin-top: 15px; }

.page-air-travel-complaints .pane-node-webform .webform-client-form.preview .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1,
.node-type-form .pane-node-webform .webform-client-form.preview .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 {
  margin-top: 10px; }

.page-air-travel-complaints .pane-node-webform .webform-client-form.preview .col-md-6,
.node-type-form .pane-node-webform .webform-client-form.preview .col-md-6 {
  width: 100%; }

.page-air-travel-complaints .pane-node-webform .webform-client-form.preview a.printbtn,
.node-type-form .pane-node-webform .webform-client-form.preview a.printbtn {
  margin-top: 10px;
  margin-bottom: 10px;
  float: right; }

.page-air-travel-complaints .accordion .acc-group,
.node-type-form .accordion .acc-group {
  clear: both;
  position: relative;
  visibility: inherit;
  background-color: #B9E0E3;
  background-color: #D8D8D8;
  border-bottom: 10px solid #919191;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 0;
  width: 100%; }
  .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited,
  .node-type-form .accordion .acc-group summary.wb-toggle-inited {
    font-size: 19px;
    list-style: none;
    color: black;
    font-weight: 600;
    border: none;
    padding: 0;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0;
    padding-right: 40px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited,
      .node-type-form .accordion .acc-group summary.wb-toggle-inited {
        padding-right: 20px; } }
    @media (min-width: 1200px) {
      .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited,
      .node-type-form .accordion .acc-group summary.wb-toggle-inited {
        padding-right: 20px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited,
      .node-type-form .accordion .acc-group summary.wb-toggle-inited {
        font-size: 22px; } }
    @media (min-width: 1200px) {
      .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited,
      .node-type-form .accordion .acc-group summary.wb-toggle-inited {
        font-size: 22px; } }
    .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited:before,
    .node-type-form .accordion .acc-group summary.wb-toggle-inited:before {
      content: " "; }
    .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited:after,
    .node-type-form .accordion .acc-group summary.wb-toggle-inited:after {
      position: absolute;
      top: 17px;
      right: 15px;
      content: ' ';
      margin-left: 15px;
      width: 20px;
      height: 20px;
      background-image: url("../images/icons/up_arrow.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      font-size: 19px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited:after,
        .node-type-form .accordion .acc-group summary.wb-toggle-inited:after {
          top: 17px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited:after,
        .node-type-form .accordion .acc-group summary.wb-toggle-inited:after {
          top: 16.5px; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited:after,
        .node-type-form .accordion .acc-group summary.wb-toggle-inited:after {
          top: 15px; } }
    .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited::-webkit-details-marker,
    .node-type-form .accordion .acc-group summary.wb-toggle-inited::-webkit-details-marker {
      display: none; }
    .page-air-travel-complaints .accordion .acc-group summary.wb-toggle-inited::-ms-expand,
    .node-type-form .accordion .acc-group summary.wb-toggle-inited::-ms-expand {
      display: none; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel,
  .node-type-form .accordion .acc-group .tgl-panel {
    padding: 20px;
    background-color: white; }
  .page-air-travel-complaints .accordion .acc-group.on,
  .node-type-form .accordion .acc-group.on {
    background-color: #B9E0E3;
    border: none; }
    .page-air-travel-complaints .accordion .acc-group.on summary.wb-toggle-inited,
    .node-type-form .accordion .acc-group.on summary.wb-toggle-inited {
      border: none;
      color: black;
      background-color: #B9E0E3;
      border-bottom: 10px solid #802F2D; }
      .page-air-travel-complaints .accordion .acc-group.on summary.wb-toggle-inited:after,
      .node-type-form .accordion .acc-group.on summary.wb-toggle-inited:after {
        position: absolute;
        top: 17px;
        right: 15px;
        content: ' ';
        margin-left: 15px;
        width: 20px;
        height: 20px;
        background-image: url("../images/icons/down_arrow.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        font-size: 19px; }
        @media (min-width: 768px) and (max-width: 991px) {
          .page-air-travel-complaints .accordion .acc-group.on summary.wb-toggle-inited:after,
          .node-type-form .accordion .acc-group.on summary.wb-toggle-inited:after {
            top: 17px; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          .page-air-travel-complaints .accordion .acc-group.on summary.wb-toggle-inited:after,
          .node-type-form .accordion .acc-group.on summary.wb-toggle-inited:after {
            top: 16.5px; } }
        @media (min-width: 1200px) {
          .page-air-travel-complaints .accordion .acc-group.on summary.wb-toggle-inited:after,
          .node-type-form .accordion .acc-group.on summary.wb-toggle-inited:after {
            top: 15px; } }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component-fieldset,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component-fieldset {
    box-shadow: none;
    padding-top: 30px;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component-fieldset .panel-heading,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component-fieldset .panel-heading {
      background-color: inherit;
      border: none; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component-fieldset .panel-heading .panel-title,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component-fieldset .panel-heading .panel-title {
        font-weight: 700; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component-fieldset .panel-body,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component-fieldset .panel-body {
      padding-top: 0;
      padding-bottom: 30px; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics-confirmation .webform-component-checkboxes .form-item .control-label,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation .webform-component-checkboxes .form-item .control-label,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics-confirmation .webform-component-checkboxes .form-item .control-label,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation .webform-component-checkboxes .form-item .control-label {
    font-size: 14px; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics-confirmation .form-item .control-label,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation .form-item .control-label,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics-confirmation .form-item .control-label,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation .form-item .control-label {
    padding-top: 5px;
    font-size: 14px; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics-confirmation .control-label.required,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation .control-label.required,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics-confirmation .control-label.required,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation .control-label.required {
    font-size: 14px;
    font-weight: 400; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--complaint-details-fieldset .control-label.required,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--complaint-details-fieldset .control-label.required {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
    margin-left: 12.5px; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--what-happened-title,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--what-happened-title {
    padding-top: 0; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--what-happened-title .form-textarea,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--what-happened-title .form-textarea {
      background-color: #ebf8fa; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body,
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body {
    width: 100%;
    padding-top: 0; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .control-label {
      font-size: 14px;
      font-weight: inherit;
      margin-bottom: 15px; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .form-control,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .form-control,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .form-control,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .form-control,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .form-control,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .form-control,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .form-control,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .form-control,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .form-control,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .form-control {
      background-color: #ebf8fa; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1 {
      margin-bottom: 39px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1 {
          margin-bottom: 39px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1 {
          margin-bottom: 29px; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1 {
          margin-bottom: 39px; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1 {
      position: absolute;
      display: inherit !important; }
      @media screen and (min-width: 300px) and (max-width: 767px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1 {
          width: 91.5%;
          max-width: inherit; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1 {
          width: 96%;
          max-width: inherit; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1 {
          width: 97%;
          max-width: inherit; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1 {
          width: 97.5%;
          max-width: inherit; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline {
      width: 100%; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline {
          width: 100%; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline {
          width: 40%; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline {
          width: 40%; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number {
      display: inline-block;
      max-width: 100%;
      width: 100%;
      margin-left: 0; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number {
          max-width: 40%; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number {
          max-width: 30%; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number {
          max-width: 20%; } }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .control-label {
        display: block; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-textfield .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-textfield .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-textfield .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-textfield .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-textfield .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number .form-text {
        width: 100%; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date {
      display: inline-block;
      max-width: 100%;
      margin-left: 0px; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select {
        max-width: 29%; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select {
            max-width: 100%; } }
        @media (min-width: 1200px) {
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .form-item .form-select,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .form-item .form-select {
            max-width: 100%; } }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker {
        margin-left: 0px;
        max-width: 35px; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker {
            margin-left: 10px;
            max-width: 35px; } }
        @media (min-width: 1200px) {
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .webform-datepicker .hasDatepicker,
          .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .webform-datepicker .hasDatepicker {
            margin-left: 10px;
            max-width: 35px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date {
          display: inline-block;
          max-width: 46%;
          margin-left: 40px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date {
          display: inline-block;
          max-width: 60%;
          width: 60%;
          margin-left: 40px; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date {
          display: inline-block;
          max-width: 60%;
          width: 60%;
          margin-left: 40px; } }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component-date .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date .control-label {
        display: block; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
      width: 100%;
      display: block;
      margin-left: 0; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
          width: 50%;
          display: inline-block;
          padding-right: 20px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
          width: 50%;
          display: inline-block;
          padding-right: 20px; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
          display: inline-block;
          width: 50%;
          padding-right: 20px;
          max-width: 35%; } }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from .form-text {
        width: 100%; }
    @media (min-width: 768px) and (max-width: 991px) {
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1 {
        margin-left: 40px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1 {
        margin-left: 40px; } }
    @media (min-width: 1200px) {
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1 {
        margin-left: 40px; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to {
      width: 100%;
      display: block;
      margin-left: 0; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to {
          width: 50%;
          display: inline-block; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to {
          width: 50%;
          display: inline-block; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to {
          display: inline-block;
          width: 50%;
          max-width: 35%; } }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to .form-text,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to .form-text {
        width: 100%; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .control-label {
      margin-top: 15px;
      font-size: 14px;
      font-weight: inherit;
      margin-bottom: 0px; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox {
      margin-top: 0; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 .form-type-checkbox .control-label {
        font-size: 20px; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label {
      font-size: 14px; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 .control-label:before {
        content: none; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline {
      max-width: 100%; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline {
          max-width: 70%; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline {
          max-width: 70%; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline {
          max-width: 70%; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single,
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single {
      width: 100% !important;
      padding: 0;
      border: none;
      box-shadow: none; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-single,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-single,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-single,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-single,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-single,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-single,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-single,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-single,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-single,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-single {
        background: none;
        padding-top: 6px;
        height: 37px; }
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-single > div,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-single > div,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-single > div,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-single > div,
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-single > div,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-single > div,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-single > div,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-single > div,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-single > div,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-single > div {
          top: 5px; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-drop,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-drop,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-drop,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-drop,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-drop,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-drop,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-drop,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-drop,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-drop,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-drop {
        background-color: #ebf8fa; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-search input,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-search input,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-search input,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-search input,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-search input,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .chosen-search input,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .chosen-search input,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .chosen-search input,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .chosen-search input,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .chosen-search input {
        background-color: white; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .no-results,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .no-results,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .no-results,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .no-results,
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .no-results,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-1 .panel-body .chosen-container-single .no-results,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-2 .panel-body .chosen-container-single .no-results,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-3 .panel-body .chosen-container-single .no-results,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-4 .panel-body .chosen-container-single .no-results,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--flight-details-5 .panel-body .chosen-container-single .no-results {
        background-color: white; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title {
    padding-top: 0; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body #addressfield-wrapper,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body #addressfield-wrapper {
      padding-top: 0; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .control-label {
      font-size: 14px;
      font-weight: inherit; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
      display: block;
      float: none; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item {
          max-width: 70%;
          margin-left: 0;
          display: block;
          float: none; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item {
          max-width: 70%;
          margin-left: 0;
          display: block;
          float: none; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item {
          max-width: 70%;
          margin-left: 0;
          display: block;
          float: none; } }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item .form-control,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item .form-control {
        width: 100%;
        background-color: #ebf8fa; }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area {
          max-width: 34%;
          margin-left: 0;
          display: inline-block;
          float: none; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area {
          max-width: 34%;
          margin-left: 0;
          display: inline-block;
          float: none; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area .form-control,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area .form-control {
          width: 100%; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area .form-control,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area .form-control {
          width: 100%; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code {
          max-width: 34%;
          margin-left: 0;
          display: inline-block; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code {
          max-width: 34%;
          margin-left: 0;
          display: inline-block; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code .form-control,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code .form-control {
          width: 100%; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code .form-control,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .panel-body .form-item.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code .form-control {
          width: 100%; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .op-required .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .op-required .control-label {
      margin-left: 12.5px; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .op-required .control-label:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--applicant-title .op-required .control-label:before {
        content: "* ";
        margin-left: -.665em;
        color: #d3080c;
        font-weight: 700; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information {
    padding-top: 0; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield {
      margin: 15px 0;
      max-width: 100%; }
      @media (min-width: 768px) and (max-width: 991px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield {
          max-width: 70%; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield {
          max-width: 70%; } }
      @media (min-width: 1200px) {
        .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield,
        .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .webform-component-textfield {
          max-width: 70%; } }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .control-label,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .control-label {
      font-size: 14px;
      font-weight: inherit; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .form-control,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--baggage-information .form-control {
      width: 100%;
      background-color: #ebf8fa; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents {
    border-bottom: none; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component-markup,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component-markup {
      max-width: 550px; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .help-block,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .help-block {
      margin-top: 15px;
      color: black; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .help-block strong,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .help-block strong {
        font-weight: 400; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .form-file,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .form-file {
      background-color: #ebf8fa;
      height: auto;
      padding: 40px;
      border: none; }
    .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit,
    .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit {
      border: 1px solid #802F2D;
      color: black;
      background-color: #9BD3DD;
      padding: 8px 20px;
      border-radius: 3px;
      font-size: 20px;
      margin-top: 15px; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit:hover, .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit:focus,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit:hover,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit:focus {
        background-color: #B9E0E3; }
      .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit .glyphicon-upload:before,
      .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--complaint-details-acc--details-documents .webform-component.webform-component-file .form-managed-file .btn-primary.form-submit .glyphicon-upload:before {
        content: none; }
  .page-air-travel-complaints .accordion .acc-group .tgl-panel .webform-component--wxt-group--communications-acc--t2-row1 .form-radio,
  .node-type-form .accordion .acc-group .tgl-panel .webform-component--wxt-group--communications-acc--t2-row1 .form-radio {
    padding: 20px;
    background-color: #ebf8fa; }
  .page-air-travel-complaints .accordion .acc-group:nth-child(3) .tgl-panel,
  .node-type-form .accordion .acc-group:nth-child(3) .tgl-panel {
    padding: 0; }

.page-air-travel-complaints .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-left .control-label,
.node-type-form .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-left .control-label {
  font-size: 20px; }

.page-air-travel-complaints .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right,
.node-type-form .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right {
  padding-right: 15px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .page-air-travel-complaints .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right,
    .node-type-form .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right {
      padding-right: 15px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .page-air-travel-complaints .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right,
    .node-type-form .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right {
      padding-right: 0; } }
  @media (min-width: 1200px) {
    .page-air-travel-complaints .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right,
    .node-type-form .accordion .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right {
      padding-right: 0; } }

.page-air-travel-complaints .form-checkboxes,
.node-type-form .form-checkboxes {
  box-sizing: border-box; }
  .page-air-travel-complaints .form-checkboxes .control-label,
  .node-type-form .form-checkboxes .control-label {
    position: relative;
    margin-left: 20px;
    padding-left: 28px;
    padding-top: 5px; }
  .page-air-travel-complaints .form-checkboxes label input[type="checkbox"],
  .node-type-form .form-checkboxes label input[type="checkbox"] {
    margin-left: -39px; }
  .page-air-travel-complaints .form-checkboxes label span,
  .node-type-form .form-checkboxes label span {
    position: absolute;
    top: 0;
    left: -19px;
    display: inline-block;
    display: inline-block;
    margin-top: 0px;
    width: 30px;
    height: 30px;
    background: #fcfcfc;
    background: #ebf8fa;
    border: 1px solid black;
    border-radius: 3px; }
  .page-air-travel-complaints .form-checkboxes label :checked + span,
  .node-type-form .form-checkboxes label :checked + span {
    position: absolute;
    left: -19px; }
  .page-air-travel-complaints .form-checkboxes label :checked + span:after,
  .node-type-form .form-checkboxes label :checked + span:after {
    content: ' ';
    font-size: 20px;
    position: absolute;
    top: 12px;
    left: 3px;
    color: #99a1a7;
    width: 4px;
    height: 4px;
    box-shadow: 4px 0 0 black, 8px 0 0 black, 8px -4px 0 black, 8px -8px 0 black, 8px -12px 0 black, 8px -16px 0 black;
    transform: rotate(45deg); }

.page-air-travel-complaints textarea,
.page-air-travel-complaints select,
.page-air-travel-complaints input,
.node-type-form textarea,
.node-type-form select,
.node-type-form input {
  background-color: #ebf8fa; }

.page-air-travel-complaints input.form-file,
.node-type-form input.form-file {
  height: 58px;
  padding-top: 17px; }

.page-air-travel-complaints .radio label,
.node-type-form .radio label {
  padding-left: 53px; }

.page-air-travel-complaints .webform-component-multiple-file .help-block,
.node-type-form .webform-component-multiple-file .help-block {
  color: #585858; }

.page-air-travel-complaints .form-group .composite-form-label,
.node-type-form .form-group .composite-form-label {
  font-size: 20px; }

.page-air-travel-complaints .form-radios,
.node-type-form .form-radios {
  box-sizing: border-box;
  margin-bottom: 25px; }
  .page-air-travel-complaints .form-radios .control-label,
  .node-type-form .form-radios .control-label {
    margin-top: 10px;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4; }
  .page-air-travel-complaints .form-radios .form-radio,
  .node-type-form .form-radios .form-radio {
    visibility: hidden; }
  .page-air-travel-complaints .form-radios .form-type-radio label,
  .node-type-form .form-radios .form-type-radio label {
    padding-left: 48px; }
  .page-air-travel-complaints .form-radios label input[type="radio"],
  .node-type-form .form-radios label input[type="radio"] {
    margin-left: -45px;
    margin-top: 5px; }
  .page-air-travel-complaints .form-radios label span,
  .node-type-form .form-radios label span {
    position: absolute;
    top: 0;
    left: 0px;
    display: inline-block;
    margin-top: 0px;
    width: 31.5px;
    height: 31.5px;
    background: #fcfcfc;
    background: #ebf8fa;
    border: 1px solid black;
    border-radius: 25px; }
  .page-air-travel-complaints .form-radios label :checked + span,
  .node-type-form .form-radios label :checked + span {
    position: absolute;
    left: 0px; }
  .page-air-travel-complaints .form-radios label :checked + span:after,
  .node-type-form .form-radios label :checked + span:after {
    content: ' ';
    font-size: 20px;
    position: absolute;
    top: 12px;
    left: 3px;
    color: #99a1a7;
    width: 4px;
    height: 4px;
    box-shadow: 4px 0 0 black, 8px 0 0 black, 8px -4px 0 black, 8px -8px 0 black, 8px -12px 0 black, 8px -16px 0 black;
    transform: rotate(45deg); }

.page-air-travel-complaints .messages.warning,
.node-type-form .messages.warning {
  margin-bottom: 15px; }

.page-air-travel-complaints strong.form-required,
.node-type-form strong.form-required {
  color: #820101 !important; }

.page-air-travel-complaints .control-label.op-required,
.node-type-form .control-label.op-required {
  margin-left: 12.5px; }
  .page-air-travel-complaints .control-label.op-required:before,
  .node-type-form .control-label.op-required:before {
    content: "* ";
    margin-left: -.665em;
    color: #820101;
    font-weight: 700; }

.page-air-travel-complaints .control-label,
.node-type-form .control-label {
  font-size: 14px;
  font-weight: inherit; }

@media (max-width: 767px) {
  .node-type-right .region-content .row .col-md-1.title-left .pane-node-field-icon .image-actual {
    margin: 0 auto;
    margin-bottom: 25px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .node-type-right .region-content .row .col-md-1.title-left .pane-node-field-icon .image-actual {
    margin: 0 auto;
    margin-bottom: 25px; } }

.node-type-right .region-content .row .col-md-11.title-right {
  margin-bottom: 50px; }
  .node-type-right .region-content .row .col-md-11.title-right .pane-content h1 {
    margin-top: 0;
    border-bottom: none;
    margin-bottom: 10px;
    padding-bottom: 0; }
    @media (max-width: 767px) {
      .node-type-right .region-content .row .col-md-11.title-right .pane-content h1 {
        text-align: center;
        margin-bottom: 25px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .node-type-right .region-content .row .col-md-11.title-right .pane-content h1 {
        text-align: center;
        margin-bottom: 25px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .node-type-right .region-content .row .col-md-11.title-right .pane-content h1 {
        text-align: left; } }
    @media (min-width: 1200px) {
      .node-type-right .region-content .row .col-md-11.title-right .pane-content h1 {
        text-align: left; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .node-type-right .region-content .row .field-name-field-short-description {
    text-align: left; } }

@media (min-width: 1200px) {
  .node-type-right .region-content .row .field-name-field-short-description {
    text-align: left; } }

.node-type-right .region-content .row .field-name-title-field.field-label-hidden h2 {
  display: none; }

.node-type-right .region-content .row .pane-node-body .accordion .acc-group {
  position: relative;
  visibility: inherit;
  background-color: #B9E0E3;
  background-color: #F2F8F8;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 0;
  width: 100%; }
  .node-type-right .region-content .row .pane-node-body .accordion .acc-group summary.wb-toggle-inited {
    background-color: #ebf8fa;
    font-size: 22px;
    list-style: none;
    color: black;
    font-weight: 600;
    border: none;
    padding: 0;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0;
    border-bottom: 10px solid #802F2D; }
    .node-type-right .region-content .row .pane-node-body .accordion .acc-group summary.wb-toggle-inited:after {
      position: absolute;
      top: 25px;
      right: 15px;
      content: ' ';
      margin-left: 15px;
      width: 20px;
      height: 20px;
      background-image: url("../images/icons/up_arrow.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block; }
      @media (min-width: 768px) and (max-width: 991px) {
        .node-type-right .region-content .row .pane-node-body .accordion .acc-group summary.wb-toggle-inited:after {
          top: 22px;
          font-size: 19px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .node-type-right .region-content .row .pane-node-body .accordion .acc-group summary.wb-toggle-inited:after {
          top: 20px;
          font-size: 19px; } }
      @media (min-width: 1200px) {
        .node-type-right .region-content .row .pane-node-body .accordion .acc-group summary.wb-toggle-inited:after {
          top: 20px;
          font-size: 19px; } }
    .node-type-right .region-content .row .pane-node-body .accordion .acc-group summary.wb-toggle-inited::-webkit-details-marker {
      display: none; }
  .node-type-right .region-content .row .pane-node-body .accordion .acc-group .tgl-panel {
    padding: 20px;
    background-color: white; }
  .node-type-right .region-content .row .pane-node-body .accordion .acc-group.on {
    background-color: #B9E0E3;
    border: none; }
    .node-type-right .region-content .row .pane-node-body .accordion .acc-group.on summary.wb-toggle-inited {
      border: none;
      color: black;
      background-color: #B9E0E3;
      border-bottom: 10px solid #802F2D; }
      .node-type-right .region-content .row .pane-node-body .accordion .acc-group.on summary.wb-toggle-inited:after {
        position: absolute;
        top: 25px;
        right: 15px;
        content: ' ';
        margin-left: 15px;
        width: 20px;
        height: 20px;
        background-image: url("../images/icons/down_arrow.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block; }
        @media (min-width: 768px) and (max-width: 991px) {
          .node-type-right .region-content .row .pane-node-body .accordion .acc-group.on summary.wb-toggle-inited:after {
            top: 22px;
            font-size: 19px; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          .node-type-right .region-content .row .pane-node-body .accordion .acc-group.on summary.wb-toggle-inited:after {
            top: 20px;
            font-size: 19px; } }
        @media (min-width: 1200px) {
          .node-type-right .region-content .row .pane-node-body .accordion .acc-group.on summary.wb-toggle-inited:after {
            top: 20px;
            font-size: 19px; } }

q2,
h2 {
  margin-top: 26px; }

main.container .pane-entity-field.pane-node-body a {
  text-decoration: none;
  border-bottom: 1px dotted #295376;
  padding-bottom: 1px; }
  main.container .pane-entity-field.pane-node-body a:hover {
    text-decoration: none;
    border-bottom: 1px solid #295376; }

header .brand a {
  border-bottom: none;
  font-size: 26px;
  font-weight: 700;
  color: #480302; }

.page-node-add .hierarchical-select-wrapper .hierarchical-select .selects,
.page-node-edit .hierarchical-select-wrapper .hierarchical-select .selects {
  max-width: 100%; }

.page-node-add #edit-title-field-und-0-value,
.page-node-edit #edit-title-field-und-0-value {
  margin-bottom: 23px; }

.page-node-add .panel-default,
.page-node-edit .panel-default {
  margin-bottom: 23px; }

.page-node-add .field-widget-options-select,
.page-node-edit .field-widget-options-select {
  margin-bottom: 23px; }

.page-node-add .burr-flipped2 .burr-flipped2-content,
.page-node-edit .burr-flipped2 .burr-flipped2-content {
  width: 100%; }

.page-node-add .burr-flipped2 .burr-flipped2-sidebar,
.page-node-edit .burr-flipped2 .burr-flipped2-sidebar {
  width: 0;
  overflow: hidden;
  display: none; }

.page-node-add .burr-flipped2 .burr-flipped2-content,
.page-node-edit .burr-flipped2 .burr-flipped2-content {
  position: relative; }
  .page-node-add .burr-flipped2 .burr-flipped2-content #sticky-wrapper,
  .page-node-edit .burr-flipped2 .burr-flipped2-content #sticky-wrapper {
    float: right;
    margin-right: 30px; }
  .page-node-add .burr-flipped2 .burr-flipped2-content a.slidetoggle,
  .page-node-edit .burr-flipped2 .burr-flipped2-content a.slidetoggle {
    width: 30px !important;
    z-index: 9999 !important;
    height: 30px;
    background: #335075;
    border-radius: 5px;
    text-align: right;
    position: absolute;
    right: auto; }
    .page-node-add .burr-flipped2 .burr-flipped2-content a.slidetoggle:after,
    .page-node-edit .burr-flipped2 .burr-flipped2-content a.slidetoggle:after {
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f0c7";
      color: #fff;
      padding: 8px 9px;
      display: inline-block; }

.page-node-add.sidebar-open .burr-flipped2 .burr-flipped2-content,
.page-node-edit.sidebar-open .burr-flipped2 .burr-flipped2-content {
  width: 68.085%; }
  .page-node-add.sidebar-open .burr-flipped2 .burr-flipped2-content a.slidetoggle:after,
  .page-node-edit.sidebar-open .burr-flipped2 .burr-flipped2-content a.slidetoggle:after {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f013";
    color: #fff;
    padding: 8px 9px;
    display: inline-block; }

.page-node-add.sidebar-open .burr-flipped2 .burr-flipped2-sidebar,
.page-node-edit.sidebar-open .burr-flipped2 .burr-flipped2-sidebar {
  width: 31.915%;
  display: block; }

.enforcement-actions .form-inline .form-group {
  margin-bottom: 10px; }
  .enforcement-actions .form-inline .form-group.form-actions {
    display: block; }

.content-listings .view-header {
  padding: 0.5em;
  background-color: #e3eff3;
  font-weight: 700;
  margin: 1em 0; }

.content-listings .view-content .item-list ol {
  margin-left: -18px !important; }
  .content-listings .view-content .item-list ol li {
    margin-left: 0 !important;
    text-indent: 0 !important; }

.content-listings .views-row {
  border-bottom: 1px solid #eee !important;
  padding-bottom: 1em !important;
  margin-bottom: 0.6em !important;
  overflow: hidden; }
  .content-listings .views-row .views-field-counter .field-content a,
  .content-listings .views-row .views-field-field-listing-title-long .field-content a,
  .content-listings .views-row .views-field-title-field .field-content a {
    font-size: em(13) !important;
    line-height: em(20) !important;
    color: #295376 !important; }

.content-listings .views-field-teaser {
  margin-left: 0 !important; }
  .content-listings .views-field-teaser .field-content {
    padding-top: 0.2em;
    font-size: em(13) !important;
    line-height: em(22) !important;
    color: black !important; }

.content-listings .views-field-ds-changed .field-content {
  font-style: italic; }

.content-listings .views-field-bundle-name,
.content-listings .views-field-ds-changed,
.content-listings .views-field-sm-vid-Publication-Type,
.content-listings .views-field-ts-publication-type {
  margin-left: 0 !important; }
  .content-listings .views-field-bundle-name .field-content,
  .content-listings .views-field-ds-changed .field-content,
  .content-listings .views-field-sm-vid-Publication-Type .field-content,
  .content-listings .views-field-ts-publication-type .field-content {
    font-size: em(13);
    line-height: em(18);
    color: #464646 !important; }

.content-listings .pager {
  margin-left: 0.8em !important;
  margin-right: 0.5em !important; }

footer#wb-info {
  border-top: 1px solid #7a0806; }
  footer#wb-info ul.menu.nav li a {
    padding: 0;
    background: transparent !important; }

footer#wb-info > nav > ul {
  column-count: initial !important;
  -moz-column-count: initial !important; }

h1 {
  margin-top: 20px; }

ul.facetapi-facetapi-links {
  padding-left: 0;
  margin-left: 0; }
  ul.facetapi-facetapi-links li {
    margin-bottom: 7px; }
    ul.facetapi-facetapi-links li a {
      display: block;
      position: relative;
      padding-right: 60px; }
      ul.facetapi-facetapi-links li a .facetapi-facet-count {
        position: absolute;
        right: 0; }
      ul.facetapi-facetapi-links li a.facetapi-active:after {
        position: absolute;
        font-size: 12px;
        top: 50%;
        margin-top: -10px;
        padding: 4px 7px;
        right: 10px;
        width: 24px;
        height: 24px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background: #335075;
        color: #fff;
        border-radius: 50%;
        content: "";
        font-family: 'FontAwesome'; }

.page-search .current-search-item {
  margin: 1em 0; }

.well-title {
  margin-top: 0; }

.node-type-publication .alt-format-panel {
  margin-top: 20px;
  float: right;
  margin: 0 0 15px 15px;
  min-width: 200px; }

.node-type-publication .publication-banner img {
  max-width: 100%; }

.node-type-publication .alt-format {
  background-color: #2572b4;
  color: #fff;
  font-size: 80%;
  margin-bottom: 10px; }
  .node-type-publication .alt-format .field-label {
    font-weight: normal; }
  .node-type-publication .alt-format ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: left; }
    .node-type-publication .alt-format ul li {
      float: left;
      display: inline;
      margin: 8px 5px;
      padding: 3px 2px; }
      .node-type-publication .alt-format ul li a {
        text-decoration: none !important;
        color: #fff !important; }
    .node-type-publication .alt-format ul:before {
      font: normal normal normal 25px/1 FontAwesome;
      content: "\f054";
      color: #fff;
      padding: 10px;
      vertical-align: middle;
      float: left;
      display: inline-block; }

.legal-list > ol,
.legal-list ol.list-decimal,
.legal-list ol.list-numeric {
  counter-reset: legal-list-1; }
  .legal-list > ol li:before,
  .legal-list ol.list-decimal li:before,
  .legal-list ol.list-numeric li:before {
    content: "(" counter(legal-list-1, decimal) ") ";
    counter-increment: legal-list-1; }

.legal-list > ol > li > ol,
.legal-list ol.list-lower-alpha {
  counter-reset: legal-list-2; }
  .legal-list > ol > li > ol li:before,
  .legal-list ol.list-lower-alpha li:before {
    content: "(" counter(legal-list-2, lower-alpha) ") ";
    counter-increment: legal-list-2; }

.legal-list > ol > li > ol li > ol,
.legal-list ol.list-lower-roman {
  counter-reset: legal-list-3; }
  .legal-list > ol > li > ol li > ol li:before,
  .legal-list ol.list-lower-roman li:before {
    content: "(" counter(legal-list-3, lower-roman) ") ";
    counter-increment: legal-list-3; }

.legal-list li {
  position: relative;
  display: list-item;
  list-style-type: none; }
  .legal-list li:before {
    display: block;
    position: absolute;
    line-height: 120%;
    left: -1.75em;
    top: 0.5em; }

.legal-list ol li {
  text-indent: 0 !important;
  margin-left: 1.5em !important;
  display: list-item;
  list-style-type: none;
  padding-top: 6px; }

dl.table-display {
  clear: both; }
  dl.table-display dt {
    border-top: 1px solid #d4d4d4;
    clear: both;
    float: left;
    font-size: em(12);
    line-height: em(22);
    font-weight: 700;
    width: 20%;
    padding: 5px 1%; }
  dl.table-display dd {
    border-top: 1px solid #d4d4d4;
    clear: right;
    font-size: em(12);
    line-height: em(22);
    padding: 5px 2%;
    margin-left: 0;
    float: left;
    width: 74%; }

.definition-1 dt {
  color: #007a91;
  font-size: em(16);
  font-weight: 700; }

.definition-1 dd {
  font-size: em(14);
  margin-left: 20px;
  margin-top: 0.5em;
  margin-bottom: 1em;
  max-width: 80%; }

.definition-2 dt {
  color: black;
  font-size: em(13);
  font-weight: 700; }

.definition-2 dd {
  font-size: em(12);
  margin-left: 20px;
  margin-top: 0.5em;
  margin-bottom: 1em; }

.expand {
  line-height: 150%;
  display: block;
  color: #007fb5;
  font-size: em(18);
  font-weight: bold;
  border-bottom: 1px dashed #a5a5a5;
  background-repeat: no-repeat;
  background-position: 20% center; }

.collapse {
  line-height: 150%;
  display: block;
  padding-right: 30px;
  color: #007fb5;
  font-size: em(18);
  font-weight: bold;
  border-bottom: 1px dashed #a5a5a5;
  background-repeat: no-repeat;
  background-position: 20% center; }

.logged-in.admin-menu .collapse {
  display: none;
  background: transparent;
  border: none;
  color: #000;
  padding-right: 0; }
  .logged-in.admin-menu .collapse.in {
    display: block; }

.collapse-note {
  font-size: em(13);
  background-color: #f5f5f5;
  padding: 1em; }

.highlight-box-2 {
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  background-color: #ebf1eb; }
  .highlight-box-2 h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em; }
  .highlight-box-2 .divider {
    background-color: #ccc;
    height: 1px;
    display: block;
    text-align: center; }

.map-1 {
  margin-left: 10px;
  width: 300px;
  border: 1px solid #cbcbcb; }
  .map-1 p {
    text-align: center;
    color: #555 !important;
    font-weight: 600; }
  .map-1 img {
    width: 300px;
    height: 190px;
    padding: 0;
    margin: 0; }

.map-2 {
  margin-left: 10px;
  width: 300px;
  border: 1px solid #cbcbcb;
  border-radius: 0;
  background-clip: padding-box;
  background-color: #f9f9f9; }
  .map-2 p:first-child {
    padding: 0 !important;
    margin: 0 !important; }
  .map-2 img {
    width: 300px;
    height: 190px;
    padding: 0;
    margin: 0; }
  .map-2 p {
    text-align: center;
    color: #555 !important;
    font-weight: 700; }

.wet-boew-note {
  background-color: #FFFAF8;
  border-top: 1px solid #bb3700;
  border-bottom: 1px solid #bb3700; }
  .wet-boew-note h2 {
    margin-top: 0;
    padding-top: 10px !important;
    padding-bottom: 0.1em !important;
    border-bottom: 1px solid #c5c0c4; }
  .wet-boew-note dl {
    margin-left: 10px; }
    .wet-boew-note dl dt {
      color: #007a91;
      font-weight: 700; }
    .wet-boew-note dl dd {
      margin-left: 0;
      margin-top: 0.5em;
      margin-bottom: 1em;
      max-width: 80%; }
      .wet-boew-note dl dd p {
        margin-left: 0;
        margin-top: 0;
        display: inline; }
      .wet-boew-note dl dd p.note-return {
        padding: 0 !important;
        display: inline; }

details.black h2,
details.black h3,
details.black h4,
details.black h5,
details.black h6 {
  background-color: #F5F5F5 !important;
  padding-left: 10px;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1em; }

details.black dl dd p {
  background-color: #ffffff !important; }

details.black summary {
  background-color: transparent;
  color: #555555;
  font-weight: 700;
  font-size: em(14);
  line-height: em(25);
  border-bottom: 1px dashed #a5a5a5; }

details.black p {
  background-color: #F5F5F5 !important;
  padding: 10px;
  margin: 0;
  text-indent: 0; }

details.black ol,
details.black ul {
  padding: 10px;
  background-color: #F5F5F5 !important;
  margin: 0; }

#wb-dtmd {
  float: right; }

.back-to-link {
  margin-top: 20px;
  margin-bottom: 20px; }

.alert-title {
  margin-top: 0; }

.ruling-date {
  margin-bottom: 15px; }

.ruling-suspension .alert-title {
  display: none; }

.ruling-suspension .alert-body {
  padding-left: 30px; }

.ruling-title {
  font-weight: bold; }

.ruling-case-number,
.ruling-file-number {
  margin-bottom: 15px; }

.homepage-news.panel-primary {
  border-color: #bb3700; }
  .homepage-news.panel-primary .panel-heading {
    background: #bb3700;
    border-color: #bb3700; }

.homepage-chair.panel-primary {
  border-color: #275132; }
  .homepage-chair.panel-primary .panel-heading {
    background: #275132;
    border-color: #275132; }
  .homepage-chair.panel-primary img {
    width: 100%; }
  .homepage-chair.panel-primary ul {
    padding: 5px 0 0 !important;
    border-bottom: 0 !important;
    list-style: none; }
    .homepage-chair.panel-primary ul li {
      border-bottom: 1px solid #295378;
      margin-left: 0 !important; }
      .homepage-chair.panel-primary ul li a {
        padding-right: 20px;
        background-image: url("/sites/all/themes/cta_bootstrap/images/blue-arrow.png");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 14px 14px;
        display: block;
        padding: 5px 20px 5px 0; }
      .homepage-chair.panel-primary ul li:last-child {
        border: none; }

.front .berton-content-banners.row {
  padding-top: 30px; }

.front .content-listings .views-row .views-field-field-published-date {
  float: none; }

.mark-redacted {
  background-color: #000000;
  color: #ffffff; }

.mark,
mark {
  background-color: #fcf8e3;
  padding: 0.2em; }

.btn-danger {
  color: #fff !important; }

.media .file-image img {
  max-width: 100%; }

.flysmart-submenu {
  padding-bottom: 2em; }
  .flysmart-submenu ul {
    background-color: #e7e7e7;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    margin: 0;
    padding: 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      .flysmart-submenu ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .flysmart-submenu ul {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3; } }
    .flysmart-submenu ul li {
      background-color: #e7e7e7;
      padding: 10px; }
      .flysmart-submenu ul li a {
        font-weight: bold;
        padding: 0; }
        .flysmart-submenu ul li a.active-trail.active:before {
          font-family: FontAwesome;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          text-decoration: inherit;
          content: " \f04b" !important; }
        .flysmart-submenu ul li a:hover {
          background: transparent;
          text-decoration: underline; }

.flysmart-topmenu ul {
  margin: 0;
  padding: 0;
  margin-bottom: 11px; }
  .flysmart-topmenu ul li {
    width: calc(20% - 16px);
    float: left;
    text-align: center;
    margin: 0 10px;
    cursor: pointer; }
    .flysmart-topmenu ul li a {
      padding: 5px;
      font-weight: bold;
      font-size: 110%;
      border-bottom: 2px solid #fff; }
    .flysmart-topmenu ul li.active:before {
      height: 11px;
      content: '';
      background-image: url("../images/fly-smart/fs-nav-arrow-sprite.png");
      background-position: 0 0;
      background-size: 105%;
      display: block;
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%; }
    .flysmart-topmenu ul li:after {
      background-image: url("../images/fly-smart/fs-nav-sprite.png");
      background-position: 0 0;
      background-size: 202%;
      display: block;
      height: 160px;
      width: 100%;
      position: relative;
      content: ''; }
      @media (min-width: 768px) and (max-width: 991px) {
        .flysmart-topmenu ul li:after {
          height: 97px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .flysmart-topmenu ul li:after {
          height: 130px; } }
    .flysmart-topmenu ul li:first-child a, .flysmart-topmenu ul li:last-child a {
      color: #fff;
      background-color: #bb3800; }
    .flysmart-topmenu ul li:first-child {
      margin-left: 0; }
      .flysmart-topmenu ul li:first-child.active:after, .flysmart-topmenu ul li:first-child:hover:after {
        background-position: 100% 0; }
    .flysmart-topmenu ul li:nth-child(2) a {
      background: #265277;
      color: #fff; }
    .flysmart-topmenu ul li:nth-child(2):after {
      background-position: 0 25%; }
    .flysmart-topmenu ul li:nth-child(2).active:after, .flysmart-topmenu ul li:nth-child(2):hover:after {
      background-position: 100% 25%; }
    .flysmart-topmenu ul li:nth-child(2).active:before, .flysmart-topmenu ul li:nth-child(2):hover:before {
      background-position: 0 25%; }
    .flysmart-topmenu ul li:nth-child(3) a {
      background: #0d661b;
      color: #fff; }
    .flysmart-topmenu ul li:nth-child(3):after {
      background-position: 0 50%; }
    .flysmart-topmenu ul li:nth-child(3).active:after, .flysmart-topmenu ul li:nth-child(3):hover:after {
      background-position: 100% 50%; }
    .flysmart-topmenu ul li:nth-child(3).active:before, .flysmart-topmenu ul li:nth-child(3):hover:before {
      background-position: 0 50%; }
    .flysmart-topmenu ul li:nth-child(4) a {
      background: #7a4376;
      color: #fff; }
    .flysmart-topmenu ul li:nth-child(4):after {
      background-position: 0 75%; }
    .flysmart-topmenu ul li:nth-child(4).active:after, .flysmart-topmenu ul li:nth-child(4):hover:after {
      background-position: 100% 75%; }
    .flysmart-topmenu ul li:nth-child(4).active:before, .flysmart-topmenu ul li:nth-child(4):hover:before {
      background-position: 0 75%; }
    .flysmart-topmenu ul li:last-child {
      margin-right: 0; }
      .flysmart-topmenu ul li:last-child:after {
        background-position: 0 100%; }
      .flysmart-topmenu ul li:last-child.active:after, .flysmart-topmenu ul li:last-child:hover:after {
        background-position: 0 100%; }
      .flysmart-topmenu ul li:last-child.active:before, .flysmart-topmenu ul li:last-child:hover:before {
        background-position: 0 100%; }

.take-charge-side-menu ul {
  margin: 0;
  padding: 0; }
  .take-charge-side-menu ul li {
    border-bottom: 1px solid #173247;
    padding: 0;
    margin: 0; }
    .take-charge-side-menu ul li a {
      padding: 10px;
      margin: 0;
      text-decoration: none;
      color: #173247;
      font-weight: bold; }
      .take-charge-side-menu ul li a:hover {
        background: transparent;
        text-decoration: underline; }
      .take-charge-side-menu ul li a.active {
        color: #818181; }

.node-type-web-page .pane-node-body .col-md-8 h2:first-child {
  margin-top: 0; }

.clearfix {
  clear: both; }

.pane-node-body img {
  max-width: 100%;
  height: auto; }

.submitted-comments .view-content {
  margin-top: 10px; }

.submitted-comments ul.download-file {
  padding-left: 0;
  list-style: none;
  display: inline-block; }

.pane-node-toc {
  clear: both; }

.front .region-berton-content-listings img {
  width: 100%; }

.brand {
  border-top-color: #f8f8f8 !important;
  padding-top: 0px !important;
  margin-top: -10px; }

.theme-gcweb .panel-primary > .panel-heading {
  background-color: #7a0806 !important;
  border-color: #7a0806 !important; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #b0151e !important;
  border-color: #b0151e !important; }

.panel-primary {
  border-color: #7a0806 !important; }

#wb-glb-mn ul.chvrn,
#mb-pnl .info-pnl,
#mb-pnl .srch-pnl form button:active,
#mb-pnl .srch-pnl form button:focus,
#mb-pnl .srch-pnl form button:hover {
  background-color: #7a0806 !important;
  border: none !important; }

#wb-glb-mn ul.chvrn::before {
  border-left: .6875em solid #ceeaf6; }

#mb-pnl .sm-pnl,
#mb-pnl .modal-body,
#mb-pnl .modal-header {
  background-color: #b0151e !important;
  border: none !important; }

#mb-pnl .modal-header:before {
  border: none !important; }

#mb-pnl .srch-pnl form button {
  background-color: #7a0806 !important;
  border-color: none !important; }

#mb-pnl {
  background-image: none !important;
  /* background-color: #7a0806 !important; */ }

h2.pane-title {
  color: #480302; }

.pane-date-mod {
  margin-top: -20px;
  width: 50%;
  float: right; }

.wb-share-inited,
.pane-bean-share-this-page {
  float: left;
  width: auto !important; }

ol.paragraph_list > li:before {
  content: "[      ] ";
  counter-increment: list;
  left: -2.0em;
  position: relative;
  white-space: pre; }

.cta-home-top .right .panel-separator {
  padding-bottom: 10px; }

cta-home-top {
  margin-top: 10px; }

.cta-home-mid img {
  width: 92%;
  margin-left: 4%; }

.cta-home-mid .pane-news-releases-panel-pane-2,
.cta-home-mid .pane-bean-cta-gc-twitter-home-block {
  /*
  border: 1px solid #dca740;
  border-radius: 4px;
  */ }

.cta-home-mid .pane-bean-consumer-protection-for-air-trav {
  /*
  border-top: 1px solid #dca740;
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-radius: 4px;
  */
  margin-bottom: 0px;
  padding-bottom: 0px; }

.cta-home-mid .pane-bean-fly-smart-home-block {
  /*
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-bottom: 1px solid #dca740;
  border-radius: 4px;
  */
  margin-top: -5px;
  padding-top: 10px;
  padding-bottom: 10px; }

.cta-home-mid h2.pane-title {
  margin-top: 0px;
  background: #dca740;
  border-color: #dca740;
  padding: 10px 15px;
  font-size: 20px !important; }

@media (min-width: 992px) {
  .cta-home-mid .container .row {
    display: flex; } }

.cta-home-mid .container .row > div {
  flex: 1;
  border: 1px solid black;
  margin: 15px;
  padding: 0px;
  border: 1px solid #dca740;
  border-radius: 4px; }

.cta-home-mid .container {
  border-bottom: 1px solid #7a0806;
  padding-bottom: 20px; }

.cta-home-bot {
  background: none;
  padding-top: 0px; }

.cta-home-bot .row {
  border-bottom: 1px solid #7a0806;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: #7a0806;
  color: #ffffff; }

.cta-home-bot h2.pane-title {
  color: #ffffff !important;
  margin-top: 0px;
  font-size: 1.5em; }

.pane-news-releases-panel-pane-2 .pane-content,
.pane-bean-consumer-protection-for-air-trav .pane-content,
.pane-bean-cta-gc-twitter-home-block .pane-content {
  padding: 15px; }

/*
.view-content-rotator-bean-view {
  color: #480302;
}
*/
.view-content-rotator-bean-view .views-field-title {
  font-size: 1.3em;
  font-weight: bold;
  padding-bottom: 10px; }

.pane-bean-homepage-quote blockquote {
  color: #480302 !important;
  font-size: 2.5rem !important;
  border-left: 5px solid #e2a731; }

.pane-content-rotator-view-panel-pane-1 {
  border: 1px solid red;
  border-radius: 4px;
  padding: 10px;
  background: #480302;
  color: #ffffff;
  font-size: 1.1em; }

#addressfield-wrapper {
  padding-top: 25px; }

@-webkit-keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%,
  100%,
  80% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.listings-view ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .listings-view ul li {
    display: block;
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid #ddd; }
    .listings-view ul li a {
      padding: 10px 20px 10px 0;
      display: block; }
    .listings-view ul li:first-child {
      padding-top: 0; }
    .listings-view ul li:last-child {
      padding-bottom: 0; }

body {
  background: #ffffff; }

iframe {
  max-width: 100%; }

.btn.btn-secondary {
  background: #E2A731;
  color: black;
  border-radius: 0;
  font-size: 1.8rem;
  padding: 10px 20px;
  margin-top: 10px; }

.front .homepage-chair {
  background: #480302;
  padding: 15px; }
  .front .homepage-chair img {
    width: 100%;
    height: auto; }
  .front .homepage-chair ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center; }
    .front .homepage-chair ul li {
      margin: 0;
      padding: 0;
      display: inline-block; }
      .front .homepage-chair ul li a {
        background: #E2A731;
        color: black;
        border-radius: 0;
        font-size: 1.8rem;
        padding: 10px 20px;
        display: block;
        margin: 10px 10px 0 10px;
        text-decoration: none; }

.front .cta-home-top .pane-bean-home-page-slider {
  display: block;
  padding: 5px;
  background: #ffffff; }

.front .cta-home-top .right a {
  display: block;
  padding: 5px;
  background: #ffffff;
  margin-bottom: 10px; }
  .front .cta-home-top .right a img {
    width: 100%; }

.front #wb-info {
  margin-top: 0; }

.front .bg-white {
  padding: 20px 0;
  background: #ffffff; }
  .front .bg-white h2 {
    /* font-size: 2rem; */ }
  .front .bg-white .btn.btn-primary {
    background: #E2A731;
    color: black;
    border-radius: 0;
    font-size: 1.8rem;
    border: none;
    padding: 10px 20px; }

.front .bg-gray {
  padding: 10px 0;
  background: #eee; }

.front .bg-red {
  padding: 50px 0;
  background: #7a0806; }
  .front .bg-red .btn.btn-primary {
    background: #E2A731;
    color: black;
    border-radius: 0;
    font-size: 1.8rem;
    border: none;
    padding: 10px 20px; }
  .front .bg-red blockquote {
    color: #ffffff;
    font-size: 3.2rem;
    line-height: 3.4rem;
    font-weight: bold;
    border-color: #EEDE6F; }

#wb-srch button,
.srchbox button {
  background: #7a0806; }

header .brand img,
header .brand object {
  max-height: 80px; }
  @media (max-width: 767px) {
    header .brand img,
    header .brand object {
      max-width: 100%; } }

header {
  background-color: rgba(194, 229, 244, 0.8); }
  @media (max-width: 767px) {
    header {
      padding-top: 25px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    header .brand {
      margin-top: 0px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    header .brand {
      margin-top: -20px; } }
  @media (min-width: 1200px) {
    header .brand {
      margin-top: -35px;
      margin-bottom: 25px; } }

.gc-nttvs {
  margin-top: 30px;
  margin-bottom: 30px; }

footer#wb-info {
  margin-top: 30px;
  background-color: #ebf8fa;
  border-top: none; }
  body footer#wb-info {
    border-top: inherit;
    color: black; }
  footer#wb-info .pane-bean-about-us,
  footer#wb-info .pane-bean-contact-us,
  footer#wb-info .pane-menu-wet-mid-footer {
    margin-bottom: 25px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .pane-bean-about-us,
      footer#wb-info .pane-bean-contact-us,
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    @media (min-width: 1200px) {
      footer#wb-info .pane-bean-about-us,
      footer#wb-info .pane-bean-contact-us,
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    footer#wb-info .pane-bean-about-us .pane-title,
    footer#wb-info .pane-bean-contact-us .pane-title,
    footer#wb-info .pane-menu-wet-mid-footer .pane-title {
      margin-bottom: 15px;
      font-size: 22px;
      font-weight: 700;
      overflow: unset;
      position: relative;
      height: inherit;
      width: inherit;
      color: black; }
    footer#wb-info .pane-bean-about-us .pane-content .field-name-field-bean-wetkit-body,
    footer#wb-info .pane-bean-contact-us .pane-content .field-name-field-bean-wetkit-body,
    footer#wb-info .pane-menu-wet-mid-footer .pane-content .field-name-field-bean-wetkit-body {
      font-size: 15px; }
  footer#wb-info .pane-menu-wet-mid-footer {
    margin-bottom: 25px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    @media (min-width: 1200px) {
      footer#wb-info .pane-menu-wet-mid-footer {
        margin-bottom: inherit; } }
    footer#wb-info .pane-menu-wet-mid-footer .pane-title {
      margin-top: 0; }
    footer#wb-info .pane-menu-wet-mid-footer .menu-block-ctools-menu-wet-mid-footer-1 .nav li a {
      color: black;
      text-decoration: underline; }
  footer#wb-info .brand {
    border-top: none;
    background-color: white; }
    footer#wb-info .brand nav.ftr-urlt-lnk .links {
      list-style-type: none; }
      footer#wb-info .brand nav.ftr-urlt-lnk .links li {
        margin-left: 0; }
        footer#wb-info .brand nav.ftr-urlt-lnk .links li:before {
          content: none; }
        footer#wb-info .brand nav.ftr-urlt-lnk .links li a {
          list-style: none; }
    footer#wb-info .brand .col-xs-6.col-md-2.text-right {
      padding-top: 0px; }
      @media (min-width: 768px) and (max-width: 991px) {
        footer#wb-info .brand .col-xs-6.col-md-2.text-right {
          padding-top: 0px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        footer#wb-info .brand .col-xs-6.col-md-2.text-right {
          padding-top: 0px; } }
  footer#wb-info .openplus-50_25_25 {
    padding-bottom: 25px; }
    @media (max-width: 767px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        padding-right: 15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        padding-right: 55px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        padding-right: 55px;
        border-right: 1px solid #B9E0E3; } }
    @media (min-width: 1200px) {
      footer#wb-info .openplus-50_25_25 .row .left {
        border-right: 1px solid #B9E0E3;
        padding-right: 55px; } }
    @media (max-width: 767px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        padding-left: 15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        padding-left: 15px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        padding-left: 55px;
        border-right: 1px solid #B9E0E3; } }
    @media (min-width: 1200px) {
      footer#wb-info .openplus-50_25_25 .row .center {
        border-right: 1px solid #B9E0E3;
        padding-left: 55px; } }
    @media (max-width: 767px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 15px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 55px; } }
    @media (min-width: 1200px) {
      footer#wb-info .openplus-50_25_25 .row .right {
        padding-left: 55px; } }
  footer#wb-info .field-item .coat-of-arms-logo {
    display: block;
    margin-bottom: 25px; }

.front main {
  padding-top: 70px; }

@media (max-width: 767px) {
  main .region-content {
    padding-left: 15px;
    padding-right: 15px; } }

.h2,
h2 {
  margin-top: 26px; }

main.container .pane-entity-field.pane-node-body a {
  text-decoration: none;
  border-bottom: 1px dotted #295376;
  padding-bottom: 1px; }
  main.container .pane-entity-field.pane-node-body a:hover {
    text-decoration: none;
    border-bottom: 1px solid #295376; }

header .brand a {
  border-bottom: none;
  font-size: 26px;
  font-weight: 700;
  color: #480302; }

.page-node-add .hierarchical-select-wrapper .hierarchical-select .selects,
.page-node-edit .hierarchical-select-wrapper .hierarchical-select .selects {
  max-width: 100%; }

.page-node-add #edit-title-field-und-0-value,
.page-node-edit #edit-title-field-und-0-value {
  margin-bottom: 23px; }

.page-node-add .panel-default,
.page-node-edit .panel-default {
  margin-bottom: 23px; }

.page-node-add .field-widget-options-select,
.page-node-edit .field-widget-options-select {
  margin-bottom: 23px; }

.page-node-add .burr-flipped2 .burr-flipped2-content,
.page-node-edit .burr-flipped2 .burr-flipped2-content {
  width: 100%; }

.page-node-add .burr-flipped2 .burr-flipped2-sidebar,
.page-node-edit .burr-flipped2 .burr-flipped2-sidebar {
  width: 0;
  overflow: hidden;
  display: none; }

.page-node-add .burr-flipped2 .burr-flipped2-content,
.page-node-edit .burr-flipped2 .burr-flipped2-content {
  position: relative; }
  .page-node-add .burr-flipped2 .burr-flipped2-content #sticky-wrapper,
  .page-node-edit .burr-flipped2 .burr-flipped2-content #sticky-wrapper {
    float: right;
    margin-right: 30px; }
  .page-node-add .burr-flipped2 .burr-flipped2-content a.slidetoggle,
  .page-node-edit .burr-flipped2 .burr-flipped2-content a.slidetoggle {
    width: 30px !important;
    z-index: 9999 !important;
    height: 30px;
    background: #335075;
    border-radius: 5px;
    text-align: right;
    position: absolute;
    right: auto; }
    .page-node-add .burr-flipped2 .burr-flipped2-content a.slidetoggle:after,
    .page-node-edit .burr-flipped2 .burr-flipped2-content a.slidetoggle:after {
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f0c7";
      color: #fff;
      padding: 8px 9px;
      display: inline-block; }

.page-node-add.sidebar-open .burr-flipped2 .burr-flipped2-content,
.page-node-edit.sidebar-open .burr-flipped2 .burr-flipped2-content {
  width: 68.085%; }
  .page-node-add.sidebar-open .burr-flipped2 .burr-flipped2-content a.slidetoggle:after,
  .page-node-edit.sidebar-open .burr-flipped2 .burr-flipped2-content a.slidetoggle:after {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f013";
    color: #fff;
    padding: 8px 9px;
    display: inline-block; }

.page-node-add.sidebar-open .burr-flipped2 .burr-flipped2-sidebar,
.page-node-edit.sidebar-open .burr-flipped2 .burr-flipped2-sidebar {
  width: 31.915%;
  display: block; }

.enforcement-actions .form-inline .form-group {
  margin-bottom: 10px; }
  .enforcement-actions .form-inline .form-group.form-actions {
    display: block; }

.content-listings .view-header {
  padding: 0.5em;
  background-color: #e3eff3;
  font-weight: 700;
  margin: 1em 0; }

.content-listings .view-content .item-list ol {
  margin-left: -18px !important; }
  .content-listings .view-content .item-list ol li {
    margin-left: 0 !important;
    text-indent: 0 !important; }

.content-listings .views-row {
  border-bottom: 1px solid #eee !important;
  padding-bottom: 1em !important;
  margin-bottom: 0.6em !important;
  overflow: hidden; }
  .content-listings .views-row .views-field-counter .field-content a,
  .content-listings .views-row .views-field-field-listing-title-long .field-content a,
  .content-listings .views-row .views-field-title-field .field-content a {
    font-size: em(13) !important;
    line-height: em(20) !important;
    color: #295376 !important; }

.content-listings .views-field-teaser {
  margin-left: 0 !important; }
  .content-listings .views-field-teaser .field-content {
    padding-top: 0.2em;
    font-size: em(13) !important;
    line-height: em(22) !important;
    color: black !important; }

.content-listings .views-field-ds-changed .field-content {
  font-style: italic; }

.content-listings .views-field-bundle-name,
.content-listings .views-field-ds-changed,
.content-listings .views-field-sm-vid-Publication-Type,
.content-listings .views-field-ts-publication-type {
  margin-left: 0 !important; }
  .content-listings .views-field-bundle-name .field-content,
  .content-listings .views-field-ds-changed .field-content,
  .content-listings .views-field-sm-vid-Publication-Type .field-content,
  .content-listings .views-field-ts-publication-type .field-content {
    font-size: em(13);
    line-height: em(18);
    color: #464646 !important; }

.content-listings .pager {
  margin-left: 0.8em !important;
  margin-right: 0.5em !important; }

footer#wb-info {
  border-top: 1px solid #7a0806; }
  footer#wb-info ul.menu.nav li a {
    padding: 0;
    background: transparent !important; }

footer#wb-info > nav > ul {
  column-count: initial !important;
  -moz-column-count: initial !important; }

h1 {
  margin-top: 20px; }

ul.facetapi-facetapi-links {
  padding-left: 0;
  margin-left: 0; }
  ul.facetapi-facetapi-links li {
    margin-bottom: 7px; }
    ul.facetapi-facetapi-links li a {
      display: block;
      position: relative;
      padding-right: 60px; }
      ul.facetapi-facetapi-links li a .facetapi-facet-count {
        position: absolute;
        right: 0; }
      ul.facetapi-facetapi-links li a.facetapi-active:after {
        position: absolute;
        font-size: 12px;
        top: 50%;
        margin-top: -10px;
        padding: 4px 7px;
        right: 10px;
        width: 24px;
        height: 24px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background: #335075;
        color: #fff;
        border-radius: 50%;
        content: "";
        font-family: 'FontAwesome'; }

.page-search .current-search-item {
  margin: 1em 0; }

.well-title {
  margin-top: 0; }

.node-type-publication .alt-format-panel {
  margin-top: 20px;
  float: right;
  margin: 0 0 15px 15px;
  min-width: 200px; }

.node-type-publication .publication-banner img {
  max-width: 100%; }

.node-type-publication .alt-format {
  background-color: #2572b4;
  color: #fff;
  font-size: 80%;
  margin-bottom: 10px; }
  .node-type-publication .alt-format .field-label {
    font-weight: normal; }
  .node-type-publication .alt-format ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: left; }
    .node-type-publication .alt-format ul li {
      float: left;
      display: inline;
      margin: 8px 5px;
      padding: 3px 2px; }
      .node-type-publication .alt-format ul li a {
        text-decoration: none !important;
        color: #fff !important; }
    .node-type-publication .alt-format ul:before {
      font: normal normal normal 25px/1 FontAwesome;
      content: "\f054";
      color: #fff;
      padding: 10px;
      vertical-align: middle;
      float: left;
      display: inline-block; }

.legal-list > ol,
.legal-list ol.list-decimal,
.legal-list ol.list-numeric {
  counter-reset: legal-list-1; }
  .legal-list > ol li:before,
  .legal-list ol.list-decimal li:before,
  .legal-list ol.list-numeric li:before {
    content: "(" counter(legal-list-1, decimal) ") ";
    counter-increment: legal-list-1; }

.legal-list > ol > li > ol,
.legal-list ol.list-lower-alpha {
  counter-reset: legal-list-2; }
  .legal-list > ol > li > ol li:before,
  .legal-list ol.list-lower-alpha li:before {
    content: "(" counter(legal-list-2, lower-alpha) ") ";
    counter-increment: legal-list-2; }

.legal-list > ol > li > ol li > ol,
.legal-list ol.list-lower-roman {
  counter-reset: legal-list-3; }
  .legal-list > ol > li > ol li > ol li:before,
  .legal-list ol.list-lower-roman li:before {
    content: "(" counter(legal-list-3, lower-roman) ") ";
    counter-increment: legal-list-3; }

.legal-list li {
  position: relative;
  display: list-item;
  list-style-type: none; }
  .legal-list li:before {
    display: block;
    position: absolute;
    line-height: 120%;
    left: -1.75em;
    top: 0.5em; }

.legal-list ol li {
  text-indent: 0 !important;
  margin-left: 1.5em !important;
  display: list-item;
  list-style-type: none;
  padding-top: 6px; }

dl.table-display {
  clear: both; }
  dl.table-display dt {
    border-top: 1px solid #d4d4d4;
    clear: both;
    float: left;
    font-size: em(12);
    line-height: em(22);
    font-weight: 700;
    width: 20%;
    padding: 5px 1%; }
  dl.table-display dd {
    border-top: 1px solid #d4d4d4;
    clear: right;
    font-size: em(12);
    line-height: em(22);
    padding: 5px 2%;
    margin-left: 0;
    float: left;
    width: 74%; }

.definition-1 dt {
  color: #007a91;
  font-size: em(16);
  font-weight: 700; }

.definition-1 dd {
  font-size: em(14);
  margin-left: 20px;
  margin-top: 0.5em;
  margin-bottom: 1em;
  max-width: 80%; }

.definition-2 dt {
  color: black;
  font-size: em(13);
  font-weight: 700; }

.definition-2 dd {
  font-size: em(12);
  margin-left: 20px;
  margin-top: 0.5em;
  margin-bottom: 1em; }

.expand {
  line-height: 150%;
  display: block;
  color: #007fb5;
  font-size: em(18);
  font-weight: bold;
  border-bottom: 1px dashed #a5a5a5;
  background-image: url("../images/blue-plus-sign.png");
  background-repeat: no-repeat;
  background-position: 20% center; }

.collapse {
  line-height: 150%;
  display: block;
  padding-right: 30px;
  color: #007fb5;
  font-size: em(18);
  font-weight: bold;
  border-bottom: 1px dashed #a5a5a5;
  background-image: url("../images/blue-minus-sign.png");
  background-repeat: no-repeat;
  background-position: 20% center; }

.logged-in.admin-menu .collapse {
  display: none;
  background: transparent;
  border: none;
  color: #000;
  padding-right: 0; }
  .logged-in.admin-menu .collapse.in {
    display: block; }

.collapse-note {
  font-size: em(13);
  background-color: #f5f5f5;
  padding: 1em; }

.highlight-box-2 {
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  background-color: #ebf1eb; }
  .highlight-box-2 h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em; }
  .highlight-box-2 .divider {
    background-color: #ccc;
    height: 1px;
    display: block;
    text-align: center; }

.map-1 {
  margin-left: 10px;
  width: 300px;
  border: 1px solid #cbcbcb; }
  .map-1 p {
    text-align: center;
    color: #555 !important;
    font-weight: 600; }
  .map-1 img {
    width: 300px;
    height: 190px;
    padding: 0;
    margin: 0; }

.map-2 {
  margin-left: 10px;
  width: 300px;
  border: 1px solid #cbcbcb;
  border-radius: 0;
  background-clip: padding-box;
  background-color: #f9f9f9; }
  .map-2 p:first-child {
    padding: 0 !important;
    margin: 0 !important; }
  .map-2 img {
    width: 300px;
    height: 190px;
    padding: 0;
    margin: 0; }
  .map-2 p {
    text-align: center;
    color: #555 !important;
    font-weight: 700; }

.wet-boew-note {
  background-color: #FFFAF8;
  border-top: 1px solid #bb3700;
  border-bottom: 1px solid #bb3700; }
  .wet-boew-note h2 {
    margin-top: 0;
    padding-top: 10px !important;
    padding-bottom: 0.1em !important;
    border-bottom: 1px solid #c5c0c4; }
  .wet-boew-note dl {
    margin-left: 10px; }
    .wet-boew-note dl dt {
      color: #007a91;
      font-weight: 700; }
    .wet-boew-note dl dd {
      margin-left: 0;
      margin-top: 0.5em;
      margin-bottom: 1em;
      max-width: 80%; }
      .wet-boew-note dl dd p {
        margin-left: 0;
        margin-top: 0;
        display: inline; }
      .wet-boew-note dl dd p.note-return {
        padding: 0 !important;
        display: inline; }

details.black h2,
details.black h3,
details.black h4,
details.black h5,
details.black h6 {
  background-color: #F5F5F5 !important;
  padding-left: 10px;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1em; }

details.black dl dd p {
  background-color: #ffffff !important; }

details.black summary {
  background-color: transparent;
  color: #555555;
  font-weight: 700;
  font-size: em(14);
  line-height: em(25);
  border-bottom: 1px dashed #a5a5a5; }

details.black p {
  background-color: #F5F5F5 !important;
  padding: 10px;
  margin: 0;
  text-indent: 0; }

details.black ol,
details.black ul {
  padding: 10px;
  background-color: #F5F5F5 !important;
  margin: 0; }

#wb-dtmd {
  float: right; }

.back-to-link {
  margin-top: 20px;
  margin-bottom: 20px; }

.alert-title {
  margin-top: 0; }

.ruling-date {
  margin-bottom: 15px; }

.ruling-suspension .alert-title {
  display: none; }

.ruling-suspension .alert-body {
  padding-left: 30px; }

.ruling-title {
  font-weight: bold; }

.ruling-case-number,
.ruling-file-number {
  margin-bottom: 15px; }

.homepage-news.panel-primary {
  border-color: #bb3700; }
  .homepage-news.panel-primary .panel-heading {
    background: #bb3700;
    border-color: #bb3700; }

.homepage-chair.panel-primary {
  border-color: #275132; }
  .homepage-chair.panel-primary .panel-heading {
    background: #275132;
    border-color: #275132; }
  .homepage-chair.panel-primary img {
    width: 100%; }
  .homepage-chair.panel-primary ul {
    padding: 5px 0 0 !important;
    border-bottom: 0 !important;
    list-style: none; }
    .homepage-chair.panel-primary ul li {
      border-bottom: 1px solid #295378;
      margin-left: 0 !important; }
      .homepage-chair.panel-primary ul li a {
        padding-right: 20px;
        background-image: url("/sites/all/themes/cta_bootstrap/images/blue-arrow.png");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 14px 14px;
        display: block;
        padding: 5px 20px 5px 0; }
      .homepage-chair.panel-primary ul li:last-child {
        border: none; }

.front .berton-content-banners.row {
  padding-top: 30px; }

.front .content-listings .views-row .views-field-field-published-date {
  float: none; }

.mark-redacted {
  background-color: #000000;
  color: #ffffff; }

.mark,
mark {
  background-color: #fcf8e3;
  padding: 0.2em; }

.btn-danger {
  color: #fff !important; }

.media .file-image img {
  max-width: 100%; }

.flysmart-submenu {
  padding-bottom: 2em; }
  .flysmart-submenu ul {
    background-color: #e7e7e7;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    margin: 0;
    padding: 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      .flysmart-submenu ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .flysmart-submenu ul {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3; } }
    .flysmart-submenu ul li {
      background-color: #e7e7e7;
      padding: 10px; }
      .flysmart-submenu ul li a {
        font-weight: bold;
        padding: 0; }
        .flysmart-submenu ul li a.active-trail.active:before {
          font-family: FontAwesome;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          text-decoration: inherit;
          content: " \f04b" !important; }
        .flysmart-submenu ul li a:hover {
          background: transparent;
          text-decoration: underline; }

.flysmart-topmenu ul {
  margin: 0;
  padding: 0;
  margin-bottom: 11px; }
  .flysmart-topmenu ul li {
    width: calc(20% - 16px);
    float: left;
    text-align: center;
    margin: 0 10px;
    cursor: pointer; }
    .flysmart-topmenu ul li a {
      padding: 5px;
      font-weight: bold;
      font-size: 110%;
      border-bottom: 2px solid #fff; }
    .flysmart-topmenu ul li.active:before {
      height: 11px;
      content: '';
      background-image: url("../images/fly-smart/fs-nav-arrow-sprite.png");
      background-position: 0 0;
      background-size: 105%;
      display: block;
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%; }
    .flysmart-topmenu ul li:after {
      background-image: url("../images/fly-smart/fs-nav-sprite.png");
      background-position: 0 0;
      background-size: 202%;
      display: block;
      height: 160px;
      width: 100%;
      position: relative;
      content: ''; }
      @media (min-width: 768px) and (max-width: 991px) {
        .flysmart-topmenu ul li:after {
          height: 97px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .flysmart-topmenu ul li:after {
          height: 130px; } }
    .flysmart-topmenu ul li:first-child a, .flysmart-topmenu ul li:last-child a {
      color: #fff;
      background-color: #bb3800; }
    .flysmart-topmenu ul li:first-child {
      margin-left: 0; }
      .flysmart-topmenu ul li:first-child.active:after, .flysmart-topmenu ul li:first-child:hover:after {
        background-position: 100% 0; }
    .flysmart-topmenu ul li:nth-child(2) a {
      background: #265277;
      color: #fff; }
    .flysmart-topmenu ul li:nth-child(2):after {
      background-position: 0 25%; }
    .flysmart-topmenu ul li:nth-child(2).active:after, .flysmart-topmenu ul li:nth-child(2):hover:after {
      background-position: 100% 25%; }
    .flysmart-topmenu ul li:nth-child(2).active:before, .flysmart-topmenu ul li:nth-child(2):hover:before {
      background-position: 0 25%; }
    .flysmart-topmenu ul li:nth-child(3) a {
      background: #0d661b;
      color: #fff; }
    .flysmart-topmenu ul li:nth-child(3):after {
      background-position: 0 50%; }
    .flysmart-topmenu ul li:nth-child(3).active:after, .flysmart-topmenu ul li:nth-child(3):hover:after {
      background-position: 100% 50%; }
    .flysmart-topmenu ul li:nth-child(3).active:before, .flysmart-topmenu ul li:nth-child(3):hover:before {
      background-position: 0 50%; }
    .flysmart-topmenu ul li:nth-child(4) a {
      background: #7a4376;
      color: #fff; }
    .flysmart-topmenu ul li:nth-child(4):after {
      background-position: 0 75%; }
    .flysmart-topmenu ul li:nth-child(4).active:after, .flysmart-topmenu ul li:nth-child(4):hover:after {
      background-position: 100% 75%; }
    .flysmart-topmenu ul li:nth-child(4).active:before, .flysmart-topmenu ul li:nth-child(4):hover:before {
      background-position: 0 75%; }
    .flysmart-topmenu ul li:last-child {
      margin-right: 0; }
      .flysmart-topmenu ul li:last-child:after {
        background-position: 0 100%; }
      .flysmart-topmenu ul li:last-child.active:after, .flysmart-topmenu ul li:last-child:hover:after {
        background-position: 0 100%; }
      .flysmart-topmenu ul li:last-child.active:before, .flysmart-topmenu ul li:last-child:hover:before {
        background-position: 0 100%; }

.take-charge-side-menu ul {
  margin: 0;
  padding: 0; }
  .take-charge-side-menu ul li {
    border-bottom: 1px solid #173247;
    padding: 0;
    margin: 0; }
    .take-charge-side-menu ul li a {
      padding: 10px;
      margin: 0;
      text-decoration: none;
      color: #173247;
      font-weight: bold; }
      .take-charge-side-menu ul li a:hover {
        background: transparent;
        text-decoration: underline; }
      .take-charge-side-menu ul li a.active {
        color: #818181; }

.node-type-web-page .pane-node-body .col-md-8 h2:first-child {
  margin-top: 0; }

.clearfix {
  clear: both; }

.pane-node-body img {
  max-width: 100%;
  height: auto; }

.submitted-comments .view-content {
  margin-top: 10px; }

.submitted-comments ul.download-file {
  padding-left: 0;
  list-style: none;
  display: inline-block; }

.pane-node-toc {
  clear: both; }

.front .region-berton-content-listings img {
  width: 100%; }

.brand {
  border-top-color: #f8f8f8 !important;
  padding-top: 0px !important;
  margin-top: -10px; }

.theme-gcweb .panel-primary > .panel-heading {
  background-color: #7a0806 !important;
  border-color: #7a0806 !important; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #b0151e !important;
  border-color: #b0151e !important; }

.panel-primary {
  border-color: #7a0806 !important; }

#wb-glb-mn ul.chvrn,
#mb-pnl .info-pnl,
#mb-pnl .srch-pnl form button:active,
#mb-pnl .srch-pnl form button:focus,
#mb-pnl .srch-pnl form button:hover {
  background-color: #7a0806 !important;
  border: none !important; }

#mb-pnl .sm-pnl,
#mb-pnl .modal-body,
#mb-pnl .modal-header {
  background-color: #b0151e !important;
  border: none !important; }

#mb-pnl .modal-header:before {
  border: none !important; }

#mb-pnl .srch-pnl form button {
  background-color: #7a0806 !important;
  border-color: none !important; }

#mb-pnl {
  background-image: none !important;
  /* background-color: #7a0806 !important; */ }

h2.pane-title {
  color: #480302; }

.pane-date-mod {
  margin-top: -20px;
  width: 50%;
  float: right; }

.wb-share-inited,
.pane-bean-share-this-page {
  float: left;
  width: auto !important; }

ol.paragraph_list > li:before {
  content: "[      ] ";
  counter-increment: list;
  left: -2.0em;
  position: relative;
  white-space: pre; }

.cta-home-top .right .panel-separator {
  padding-bottom: 10px; }

cta-home-top {
  margin-top: 10px; }

.cta-home-mid img {
  width: 92%;
  margin-left: 4%; }

.cta-home-mid .pane-news-releases-panel-pane-2,
.cta-home-mid .pane-bean-cta-gc-twitter-home-block {
  /*
  border: 1px solid #dca740;
  border-radius: 4px;
  */ }

.cta-home-mid .pane-bean-consumer-protection-for-air-trav {
  /*
  border-top: 1px solid #dca740;
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-radius: 4px;
  */
  margin-bottom: 0px;
  padding-bottom: 0px; }

.cta-home-mid .pane-bean-fly-smart-home-block {
  /*
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-bottom: 1px solid #dca740;
  border-radius: 4px;
  */
  margin-top: -5px;
  padding-top: 10px;
  padding-bottom: 10px; }

.cta-home-mid h2.pane-title {
  margin-top: 0px;
  background: #dca740;
  border-color: #dca740;
  padding: 10px 15px;
  font-size: 20px !important; }

@media (min-width: 992px) {
  .cta-home-mid .container .row {
    display: flex; } }

.cta-home-mid .container .row > div {
  flex: 1;
  border: 1px solid black;
  margin: 15px;
  padding: 0px;
  border: 1px solid #dca740;
  border-radius: 4px; }

.cta-home-mid .container {
  border-bottom: 1px solid #7a0806;
  padding-bottom: 20px; }

.cta-home-bot {
  background: none;
  padding-top: 0px; }

.cta-home-bot .row {
  border-bottom: 1px solid #7a0806;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: #7a0806;
  color: #ffffff; }

.cta-home-bot h2.pane-title {
  color: #ffffff !important;
  margin-top: 0px;
  font-size: 1.5em; }

.pane-news-releases-panel-pane-2 .pane-content,
.pane-bean-consumer-protection-for-air-trav .pane-content,
.pane-bean-cta-gc-twitter-home-block .pane-content {
  padding: 15px; }

/*
.view-content-rotator-bean-view {
  color: #480302;
}
*/
.view-content-rotator-bean-view .views-field-title {
  font-size: 1.3em;
  font-weight: bold;
  padding-bottom: 10px; }

.pane-bean-homepage-quote blockquote {
  color: #480302 !important;
  font-size: 2.5rem !important;
  border-left: 5px solid #e2a731; }

.pane-content-rotator-view-panel-pane-1 {
  border: 1px solid red;
  border-radius: 4px;
  padding: 10px;
  background: #480302;
  color: #ffffff;
  font-size: 1.1em; }

.webform-component-addressfield {
  margin: 25px auto; }

.webform-component-radios {
  margin: 15px auto; }

#edit-submitted-additional-applicants-add-row-additional-applicants {
  margin-bottom: 25px; }

.webform-client-form .table {
  margin-top: 20px; }

.webform-client-form .form-type-textfield {
  margin: 15px auto; }

/* Joseph fixes for regressions caused by wetkit 7.x-4.50 upgrade.*/
#edit-xml-body fieldset.collapsed, #edit-xml fieldset.collapsed {
  height: unset !important; }

/* Joseph fixes for regressions caused by wetkit 7.x-4.50 upgrade , affects permissions page possibly other tables#*/
.page-admin table td, .page-admin table th {
  display: table-cell; }

.form-actions, fieldset .panel-body {
  clear: both; }
