.pane-node-webform {
    .webform-client-form {
        .form-type-checkbox {
            margin-top: 15px;
            margin-bottom: 15px;
            //label
            .control-label {
                position: relative;
                font-size: 20px;
                // font-size: 15px;
                //input checkbox
                .form-checkbox {}
            }
        }
    }
}