nav#wb-sm {
    background-color: $secondary-blue;
    .row {
        .list-inline.menu {
            border-right: none;
            .leaf {
                position: relative;
                border-left: none;
                border-right: none;
                font-size: 20px;
                @include bp(m) {
                    position: relative;
                    border-left: none;
                    border-right: none;
                    font-size: 20px;
                }
                @include bp(l) {
                    font-size: 24px;
                }
                // &.active-trail {
                //     background-color: inherit;
                //     // border-bottom: 10px solid $primary-red;
                //     a {
                //         font-weight: 600;
                //         color: $primary-red!important;
                //     }
                //     &:before {
                //         position: absolute;
                //         width: 100%;
                //         bottom: 0;
                //         content: ' ';
                //         border-bottom: 10px solid $primary-red;
                //     }
                //     .active-trail {}
                // }
                a {
                    position: relative;
                    padding-top: 1em;
                    padding-bottom: 1em;
                    font-weight: 300;
                    text-shadow: none;
                    // color: black!important;
                    color: black!important;
                    background-color: transparent!important;
                    @include bp(s) {
                        padding-top: 0.9em;
                        padding-bottom: 0.9em;
                    }
                    @include bp(m) {
                        padding-top: 0.9em;
                        padding-bottom: 0.9em;
                    }
                    @include bp(l) {
                        padding-top: 0.75em;
                        padding-bottom: 0.75em;
                    }
                    &.wb-navcurr {
                        font-weight: 600;
                        color: $primary-red!important;
                        &:before {
                            position: absolute;
                            left: 0;
                            width: 100%;
                            bottom: 0;
                            content: ' ';
                            border-bottom: 10px solid $primary-red;
                        }
                    }
                    &:hover {
                        // background-color: cornflowblue!important;
                        // background: cornflowblue!important;
                        // font-weight: 600;
                        color: $primary-red!important;
                        &:before {
                            position: absolute;
                            left: 0;
                            width: 100%;
                            bottom: 0;
                            content: ' ';
                            border-bottom: 10px solid $primary-red;
                        }
                    }
                    &:after {
                        content: ' ';
                        margin-left: 15px;
                        width: 20px;
                        height: 20px;
                        background-image: url("../images/icons/down_arrow.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        display: inline-block;
                    }
                }
            }
            .active {
                background: transparent!important;
                // &:before {
                //     position: absolute;
                //     left: 0;
                //     width: 100%;
                //     bottom: 0;
                //     content: ' ';
                //     border-bottom: 10px solid $primary-red;
                // }
            }
        }
    }
}