.block-system {
    .pane-bean-know-your-rights-header {
        // display: none;
        .field-name-field-bean-wetkit-body {
            .field-item {
                padding-top: 10px;
                p {
                    font-size: 18px;
                }
            }
        }
    }
    .pane-bean-know-your-rights-obligations {
        margin-bottom: 25px;
        .pane-title {
            display: none;
        }
        .pane-content {
            .field-name-field-bean-wetkit-body {
                .field-item h3 {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .view-rights-list {
        .view-content {
            .accordion {
                .acc-group {
                    summary.wb-toggle-inited {
                        position: relative;
                        overflow: hidden;
                        box-sizing: border-box;
                        background-color: $secondary-blue;
                        padding: 30px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-right: 50px;
                        font-size: 19px;
                        color: black;
                        list-style: none;
                        border: none;
                        @include bp(xs) {
                            padding-left: 15px;
                            font-size: 16px;
                        }
                        @include bp(m) {
                            padding-right: 120px;
                        }
                        @include bp(l) {
                            padding-right: 120px;
                        }
                        &:before {
                            content: " ";
                        }
                        &:after {
                            position: absolute;
                            top: 23%;
                            right: 15px;
                            content: ' ';
                            margin-left: 15px;
                            width: 20px;
                            height: 20px;
                            background-image: url("../images/icons/up_arrow.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            display: inline-block;
                            @include bp(s) {
                                top: 40%;
                                font-size: 19px;
                            }
                            @include bp(m) {
                                top: 43%;
                                font-size: 19px;
                            }
                            @include bp(l) {
                                top: 40%;
                                font-size: 19px;
                            }
                        }
                        &:hover {
                            color: $primary-red;
                        }
                        // padding-right: 50px;
                        .image-actual {
                            float: left;
                            margin-right: 28px;
                            width: 80px;
                            height: auto;
                            max-width: 40px;
                            @include bp(s) {
                                max-width: 40px;
                            }
                            @include bp(m) {
                                max-width: 60px;
                            }
                            @include bp(l) {
                                max-width: 80px;
                            }
                        }
                        &::-webkit-details-marker {
                            display: none;
                            // float: right;
                            // list-style-image: url("../images/icons/down_arrow.png");
                        }
                    }
                    .tgl-panel {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        p {
                            // font-weight: 500;
                            font-size: 16px;
                        }
                        a.btn-primary {
                            color: black;
                            font-weight: 700;
                            width: 100%;
                            background-color: $tertiary-blue;
                            &:hover {
                                background-color: #9BD3DD;
                            }
                        }
                    }
                    &.on {
                        border: none;
                        summary.wb-toggle-inited {
                            color: black;
                            background-color: $tertiary-blue;
                            border-bottom: 10px solid $primary-red;
                            &:after {
                                position: absolute;
                                top: 22px;
                                right: 15px;
                                content: ' ';
                                margin-left: 15px;
                                width: 20px;
                                height: 20px;
                                background-image: url("../images/icons/down_arrow.png");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                display: inline-block;
                                @include bp(s) {
                                    top: 30px;
                                    font-size: 19px;
                                }
                                @include bp(m) {
                                    top: 40px;
                                    font-size: 19px;
                                }
                                @include bp(l) {
                                    top: 43px;
                                    font-size: 19px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}