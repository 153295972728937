@import 'global/global';
@import 'layout/layout';
@import 'modules/modules';
@import 'pages/pages';
@import 'elements/index';
q2,
h2 {
    margin-top: 26px;
}

main.container .pane-entity-field.pane-node-body a {
    text-decoration: none;
    border-bottom: 1px dotted #295376;
    padding-bottom: 1px;
    &:hover {
        text-decoration: none;
        border-bottom: 1px solid #295376;
    }
}

header .brand a {
    border-bottom: none;
    font-size: 26px;
    font-weight: 700;
    color: #480302;
}

.page-node-add,
.page-node-edit {
    .hierarchical-select-wrapper .hierarchical-select .selects {
        max-width: 100%;
    }
    #edit-title-field-und-0-value {
        margin-bottom: 23px;
    }
    .panel-default {
        margin-bottom: 23px;
    }
    .field-widget-options-select {
        margin-bottom: 23px;
    }
    .burr-flipped2 {
        .burr-flipped2-content {
            width: 100%;
        }
        .burr-flipped2-sidebar {
            width: 0;
            overflow: hidden;
            display: none;
        }
    }
    .burr-flipped2 {
        .burr-flipped2-content {
            position: relative;
            #sticky-wrapper {
                float: right;
                margin-right: 30px;
            }
            a.slidetoggle {
                width: 30px !important;
                z-index: 9999 !important;
                height: 30px;
                background: #335075;
                border-radius: 5px;
                text-align: right;
                position: absolute;
                right: auto;
                &:after {
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f0c7";
                    color: #fff;
                    padding: 8px 9px;
                    display: inline-block;
                }
            }
        }
    }
    &.sidebar-open {
        .burr-flipped2 {
            .burr-flipped2-content {
                width: 68.085%;
                a.slidetoggle {
                    &:after {
                        font: normal normal normal 14px/1 FontAwesome;
                        content: "\f013";
                        color: #fff;
                        padding: 8px 9px;
                        display: inline-block;
                    }
                }
            }
            .burr-flipped2-sidebar {
                width: 31.915%;
                display: block;
            }
        }
    }
}

.enforcement-actions {
    .form-inline .form-group {
        margin-bottom: 10px;
        &.form-actions {
            display: block;
        }
    }
}

// .pane-node-body {
//   .panel.panel-primary {
//     ul {
//       list-style: none;
//       padding:15px;
//       margin:0;
//       li {
//         margin-bottom: 5px;
//       }
//       ul {
//         padding-bottom: 0px;
//         padding-top: 5px;
//       }
//     }
//   }
// }
// @import "elements/landing-page";
// @import "elements/new-styles";
// @import "elements/general";
// @import "elements/search-results";
// @import "elements/slider";
// @import "elements/side-nav";
// @import "elements/ruling";
// @import "elements/publications";
// @import "elements/decisions";
// @import "elements/icons";
// Landing list items
.content-listings {
    .view-header {
        // // @include inline-block();
        padding: 0.5em;
        background-color: #e3eff3;
        font-weight: 700;
        margin: 1em 0;
    }
    .view-content {
        .item-list ol {
            margin-left: -18px !important;
            li {
                margin-left: 0 !important;
                text-indent: 0 !important;
            }
        }
    }
    //border after each row
    .views-row {
        border-bottom: 1px solid #eee !important;
        // background-color: white !important;
        padding-bottom: 1em !important;
        margin-bottom: 0.6em !important;
        overflow: hidden;
        // .views-field-field-published-date, .content-date {
        //   float: right;
        //   margin-top:10px;
        // }
        .views-field-counter,
        .views-field-field-listing-title-long,
        .views-field-title-field {
            .field-content {
                a {
                    font-size: em(13) !important;
                    line-height: em(20) !important;
                    color: #295376 !important;
                    // font-weight: bold;
                }
            }
        }
    }
    .views-field-teaser {
        margin-left: 0 !important;
        .field-content {
            padding-top: 0.2em;
            font-size: em(13) !important;
            line-height: em(22) !important;
            color: black !important;
        }
    }
    .views-field-ds-changed {
        .field-content {
            font-style: italic;
        }
    }
    .views-field-bundle-name,
    .views-field-ds-changed,
    .views-field-sm-vid-Publication-Type,
    .views-field-ts-publication-type {
        margin-left: 0 !important;
        .field-content {
            font-size: em(13);
            line-height: em(18);
            color: #464646 !important;
        }
    }
    .pager {
        margin-left: 0.8em !important;
        margin-right: 0.5em !important;
    }
}

// .front {
//   #block-system-main,
//   #content {
//     section {
//       margin-bottom: 1em;
//       padding-bottom: 1em;
//     }
//   }
// }
footer#wb-info {
    border-top: 1px solid #7a0806;
    ul.menu.nav {
        li {
            a {
                padding: 0;
                background: transparent !important;
            }
        }
    }
}

footer#wb-info>nav>ul {
    column-count: initial !important;
    -moz-column-count: initial !important;
}

h1 {
    margin-top: 20px;
}

ul.facetapi-facetapi-links {
    padding-left: 0;
    margin-left: 0;
    li {
        margin-bottom: 7px;
        a {
            display: block;
            position: relative;
            padding-right: 60px;
            .facetapi-facet-count {
                position: absolute;
                right: 0;
            }
            &.facetapi-active {
                &:after {
                    position: absolute;
                    font-size: 12px;
                    top: 50%;
                    margin-top: -10px;
                    padding: 4px 7px;
                    right: 10px;
                    width: 24px;
                    height: 24px;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    background: #335075;
                    color: #fff;
                    border-radius: 50%;
                    content: "";
                    font-family: 'FontAwesome';
                }
            }
        }
    }
}

.page-search {
    .current-search-item {
        margin: 1em 0;
    }
}

.well-title {
    margin-top: 0;
}

.node-type-publication {
    .alt-format-panel {
        margin-top: 20px;
        float: right;
        margin: 0 0 15px 15px;
        min-width: 200px;
    }
    .publication-banner {
        img {
            max-width: 100%;
        }
    }
    .alt-format {
        background-color: #2572b4;
        color: #fff;
        font-size: 80%;
        margin-bottom: 10px;
        .field-label {
            font-weight: normal;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            float: left;
            li {
                float: left;
                display: inline;
                margin: 8px 5px;
                padding: 3px 2px;
                a {
                    text-decoration: none !important;
                    color: #fff !important;
                }
            }
            &:before {
                font: normal normal normal 25px/1 FontAwesome;
                content: "\f054";
                color: #fff;
                padding: 10px;
                vertical-align: middle;
                float: left;
                display: inline-block;
            }
        }
    }
}

.legal-list>ol,
.legal-list ol.list-decimal,
.legal-list ol.list-numeric {
    counter-reset: legal-list-1;
    li {
        &:before {
            content: "(" counter(legal-list-1, decimal) ") ";
            counter-increment: legal-list-1;
        }
    }
}

.legal-list>ol>li>ol,
.legal-list ol.list-lower-alpha {
    counter-reset: legal-list-2;
    li {
        &:before {
            content: "(" counter(legal-list-2, lower-alpha) ") ";
            counter-increment: legal-list-2;
        }
    }
}

.legal-list>ol>li>ol li>ol,
.legal-list ol.list-lower-roman {
    counter-reset: legal-list-3;
    li {
        &:before {
            content: "(" counter(legal-list-3, lower-roman) ") ";
            counter-increment: legal-list-3;
        }
    }
}

.legal-list {
    li {
        position: relative;
        display: list-item;
        list-style-type: none;
        &:before {
            display: block;
            position: absolute;
            line-height: 120%;
            left: -1.75em;
            top: 0.5em;
        }
    }
    ol {
        li {
            text-indent: 0 !important;
            margin-left: 1.5em !important;
            display: list-item;
            list-style-type: none;
            padding-top: 6px;
        }
    }
}

dl.table-display {
    dt {
        border-top: 1px solid #d4d4d4;
        clear: both;
        float: left;
        font-size: em(12);
        line-height: em(22);
        font-weight: 700;
        width: 20%;
        padding: 5px 1%;
    }
    dd {
        border-top: 1px solid #d4d4d4;
        clear: right;
        font-size: em(12);
        line-height: em(22);
        padding: 5px 2%;
        margin-left: 0;
        float: left;
        width: 74%;
    }
    clear:both;
}

.definition-1 {
    dt {
        color: #007a91;
        font-size: em(16);
        font-weight: 700;
    }
    dd {
        font-size: em(14);
        margin-left: 20px;
        margin-top: 0.5em;
        margin-bottom: 1em;
        max-width: 80%;
    }
}

.definition-2 {
    dt {
        color: black;
        font-size: em(13);
        font-weight: 700;
    }
    dd {
        font-size: em(12);
        margin-left: 20px;
        margin-top: 0.5em;
        margin-bottom: 1em;
    }
}

.expand {
    line-height: 150%;
    display: block;
    color: #007fb5;
    font-size: em(18);
    font-weight: bold;
    border-bottom: 1px dashed #a5a5a5;
    background-repeat: no-repeat;
    background-position: 20% center;
}

.collapse {
    line-height: 150%;
    display: block;
    padding-right: 30px;
    color: #007fb5;
    font-size: em(18);
    font-weight: bold;
    border-bottom: 1px dashed #a5a5a5;
    background-repeat: no-repeat;
    background-position: 20% center;
}

.logged-in.admin-menu {
    .collapse {
        display: none;
        background: transparent;
        border: none;
        color: #000;
        padding-right: 0;
        &.in {
            display: block;
        }
    }
}

.collapse-note {
    font-size: em(13);
    background-color: #f5f5f5;
    padding: 1em;
}

.highlight-box-2 {
    h2 {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    padding: 0.5em 1em;
    //    max-width: 45%;
    border: 1px solid #ccc;
    background-color: #ebf1eb;
    .divider {
        background-color: #ccc;
        height: 1px;
        display: block;
        //      width: 100%;
        text-align: center;
    }
}

.map-1 {
    margin-left: 10px;
    width: 300px;
    border: 1px solid #cbcbcb; // stroke
    p {
        text-align: center;
        color: #555 !important;
        font-weight: 600;
    }
    img {
        width: 300px;
        height: 190px;
        padding: 0;
        margin: 0;
    }
}

.map-2 {
    margin-left: 10px;
    width: 300px;
    border: 1px solid #cbcbcb; // stroke
    border-radius: 0; // border radius
    background-clip: padding-box; // prevents bg color from leaking outside the border
    background-color: #f9f9f9; // layer fill content
    p:first-child {
        padding: 0 !important;
        margin: 0 !important;
    }
    img {
        width: 300px;
        height: 190px;
        padding: 0;
        margin: 0;
    }
    p {
        text-align: center;
        color: #555 !important;
        font-weight: 700;
    }
}

.wet-boew-note {
    background-color: #FFFAF8;
    border-top: 1px solid #bb3700;
    border-bottom: 1px solid #bb3700;
    h2 {
        margin-top: 0;
        padding-top: 10px !important;
        padding-bottom: 0.1em !important;
        border-bottom: 1px solid #c5c0c4;
    }
    dl {
        margin-left: 10px;
        dt {
            color: #007a91;
            //font-size: em(16);
            font-weight: 700;
        }
        dd {
            //font-size: em(14);
            margin-left: 0;
            margin-top: 0.5em;
            margin-bottom: 1em;
            max-width: 80%;
            p {
                margin-left: 0;
                margin-top: 0;
                display: inline;
            }
            p.note-return {
                padding: 0 !important;
                display: inline;
            }
        }
    }
}

details.black {
    h2,
    h3,
    h4,
    h5,
    h6 {
        background-color: #F5F5F5 !important;
        padding-left: 10px;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 1em;
    }
    dl dd p {
        background-color: #ffffff !important;
    }
    summary {
        background-color: transparent;
        color: #555555;
        font-weight: 700;
        font-size: em(14);
        line-height: em(25);
        border-bottom: 1px dashed #a5a5a5;
    }
    p {
        background-color: #F5F5F5 !important;
        padding: 10px;
        margin: 0;
        text-indent: 0;
    }
    ol,
    ul {
        padding: 10px;
        background-color: #F5F5F5 !important;
        margin: 0;
    }
}

#wb-dtmd {
    float: right;
}

.back-to-link {
    margin-top: 20px;
    margin-bottom: 20px;
}

.alert-title {
    margin-top: 0;
}

.ruling-date {
    margin-bottom: 15px;
}

.ruling-suspension {
    .alert-title {
        display: none;
    }
    .alert-body {
        padding-left: 30px;
    }
}

.ruling-title {
    font-weight: bold;
}

.ruling-case-number,
.ruling-file-number {
    margin-bottom: 15px;
}

.homepage-news.panel-primary {
    border-color: #bb3700;
    .panel-heading {
        background: #bb3700;
        border-color: #bb3700;
    }
}

.homepage-chair.panel-primary {
    border-color: #275132;
    .panel-heading {
        background: #275132;
        border-color: #275132;
    }
    img {
        width: 100%;
    }
    ul {
        padding: 5px 0 0 !important;
        border-bottom: 0 !important;
        list-style: none;
        li {
            border-bottom: 1px solid #295378;
            margin-left: 0 !important;
            a {
                padding-right: 20px;
                background-image: url("/sites/all/themes/cta_bootstrap/images/blue-arrow.png");
                background-repeat: no-repeat;
                background-position: center right;
                background-size: 14px 14px;
                display: block;
                padding: 5px 20px 5px 0;
            }
            &:last-child {
                border: none;
            }
        }
    }
}

.front {
    .berton-content-banners.row {
        padding-top: 30px;
    }
    .content-listings .views-row .views-field-field-published-date {
        float: none;
    }
}

.mark-redacted {
    background-color: #000000;
    color: #ffffff;
}

.mark,
mark {
    background-color: #fcf8e3;
    padding: 0.2em;
}

// Button
.btn-danger {
    color: #fff !important;
}

.media {
    .file-image {
        img {
            max-width: 100%;
        }
    }
}

.flysmart-submenu {
    padding-bottom: 2em;
    ul {
        background-color: #e7e7e7;
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        margin: 0;
        padding: 0;
        @include bp(s) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
        @include bp(m) {
            -webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
        }
        li {
            background-color: #e7e7e7;
            padding: 10px;
            a {
                font-weight: bold;
                padding: 0;
                &.active-trail.active {
                    &:before {
                        font-family: FontAwesome;
                        font-weight: normal;
                        font-style: normal;
                        display: inline-block;
                        text-decoration: inherit;
                        content: " \f04b" !important;
                    }
                }
                &:hover {
                    background: transparent;
                    text-decoration: underline;
                }
            }
        }
    }
}

.flysmart-topmenu {
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 11px;
        li {
            width: calc(20% - 16px);
            float: left;
            text-align: center;
            margin: 0 10px;
            cursor: pointer;
            a {
                padding: 5px;
                font-weight: bold;
                font-size: 110%;
                border-bottom: 2px solid #fff;
            }
            &.active {
                &:before {
                    height: 11px;
                    content: '';
                    background-image: url("../images/fly-smart/fs-nav-arrow-sprite.png");
                    background-position: 0 0;
                    background-size: 105%;
                    display: block;
                    position: absolute;
                    bottom: -11px;
                    left: 0;
                    width: 100%;
                }
            }
            &:after {
                background-image: url("../images/fly-smart/fs-nav-sprite.png");
                background-position: 0 0;
                background-size: 202%;
                display: block;
                height: 160px;
                width: 100%;
                position: relative;
                content: '';
                @include bp(s) {
                    height: 97px;
                }
                @include bp(m) {
                    height: 130px;
                }
            }
            &:first-child,
            &:last-child {
                a {
                    color: #fff;
                    background-color: #bb3800;
                }
            }
            &:first-child {
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 0;
                    }
                }
                margin-left: 0;
            }
            &:nth-child(2) {
                a {
                    background: #265277;
                    color: #fff;
                }
                &:after {
                    background-position: 0 25%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 25%;
                    }
                    &:before {
                        background-position: 0 25%;
                    }
                }
            }
            &:nth-child(3) {
                a {
                    background: #0d661b;
                    color: #fff;
                }
                &:after {
                    background-position: 0 50%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 50%;
                    }
                    &:before {
                        background-position: 0 50%;
                    }
                }
            }
            &:nth-child(4) {
                a {
                    background: #7a4376;
                    color: #fff;
                }
                &:after {
                    background-position: 0 75%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 75%;
                    }
                    &:before {
                        background-position: 0 75%;
                    }
                }
            }
            &:last-child {
                &:after {
                    background-position: 0 100%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 0 100%;
                    }
                    &:before {
                        background-position: 0 100%;
                    }
                }
                margin-right: 0;
            }
        }
    }
}

.take-charge-side-menu {
    ul {
        margin: 0;
        padding: 0;
        li {
            border-bottom: 1px solid #173247;
            padding: 0;
            margin: 0;
            a {
                padding: 10px;
                margin: 0;
                text-decoration: none;
                color: #173247;
                font-weight: bold;
                &:hover {
                    background: transparent;
                    text-decoration: underline;
                }
                &.active {
                    color: #818181;
                }
            }
        }
    }
}

.node-type-web-page {
    .pane-node-body {
        .col-md-8 {
            h2:first-child {
                margin-top: 0;
            }
        }
    }
}

.clearfix {
    clear: both;
}

.pane-node-body {
    img {
        max-width: 100%;
        height: auto;
    }
}

.submitted-comments {
    .view-content {
        margin-top: 10px;
    }
    ul.download-file {
        padding-left: 0;
        list-style: none;
        display: inline-block;
    }
}

.pane-node-toc {
    clear: both;
}

.front {
    .region-berton-content-listings {
        img {
            width: 100%;
        }
    }
}

.brand {
    border-top-color: #f8f8f8 !important;
    padding-top: 0px !important;
    margin-top: -10px;
}

.theme-gcweb .panel-primary>.panel-heading {
    background-color: #7a0806 !important;
    border-color: #7a0806 !important;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #b0151e !important;
    border-color: #b0151e !important;
}

.panel-primary {
    border-color: #7a0806 !important;
}

#wb-glb-mn ul.chvrn,
#mb-pnl .info-pnl,
#mb-pnl .srch-pnl form button:active,
#mb-pnl .srch-pnl form button:focus,
#mb-pnl .srch-pnl form button:hover {
    background-color: #7a0806 !important;
    border: none !important;
}

#wb-glb-mn ul.chvrn::before{
    border-left: .6875em solid #ceeaf6;
}

#mb-pnl .sm-pnl,
#mb-pnl .modal-body,
#mb-pnl .modal-header {
    background-color: #b0151e !important;
    border: none !important;
}

#mb-pnl .modal-header:before {
    border: none !important;
}

#mb-pnl .srch-pnl form button {
    background-color: rgb(122, 8, 6) !important;
    border-color: none !important;
}

#mb-pnl {
    background-image: none !important;
    /* background-color: #7a0806 !important; */
}

h2.pane-title {
    color: #480302;
}

.pane-date-mod {
    margin-top: -20px;
    width: 50%;
    float: right;
}

.wb-share-inited,
.pane-bean-share-this-page {
    float: left;
    width: auto !important;
}

ol.paragraph_list>li:before {
    content: "[      ] ";
    counter-increment: list;
    left: -2.0em;
    position: relative;
    white-space: pre;
}

.cta-home-top .right .panel-separator {
    padding-bottom: 10px;
}

cta-home-top {
    margin-top: 10px;
}

.cta-home-mid img {
    width: 92%;
    margin-left: 4%;
}

.cta-home-mid .pane-news-releases-panel-pane-2,
.cta-home-mid .pane-bean-cta-gc-twitter-home-block {
    /*
  border: 1px solid #dca740;
  border-radius: 4px;
  */
}

.cta-home-mid .pane-bean-consumer-protection-for-air-trav {
    /*
  border-top: 1px solid #dca740;
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-radius: 4px;
  */
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.cta-home-mid .pane-bean-fly-smart-home-block {
    /*
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-bottom: 1px solid #dca740;
  border-radius: 4px;
  */
    margin-top: -5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cta-home-mid h2.pane-title {
    margin-top: 0px;
    background: #dca740;
    border-color: #dca740;
    padding: 10px 15px;
    font-size: 20px !important;
}

@media (min-width: 992px) {
    .cta-home-mid .container .row {
        display: flex;
    }
}

.cta-home-mid .container .row>div {
    flex: 1;
    border: 1px solid black;
    margin: 15px;
    padding: 0px;
    border: 1px solid #dca740;
    border-radius: 4px;
}

.cta-home-mid .container {
    border-bottom: 1px solid #7a0806;
    padding-bottom: 20px;
}

.cta-home-bot {
    background: none;
    padding-top: 0px;
}

.cta-home-bot .row {
    border-bottom: 1px solid #7a0806;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background-color: #7a0806;
    color: #ffffff;
}

.cta-home-bot h2.pane-title {
    color: #ffffff !important;
    margin-top: 0px;
    font-size: 1.5em;
}

.pane-news-releases-panel-pane-2 .pane-content,
.pane-bean-consumer-protection-for-air-trav .pane-content,
.pane-bean-cta-gc-twitter-home-block .pane-content {
    padding: 15px;
}


/*
.view-content-rotator-bean-view {
  color: #480302;
}
*/

.view-content-rotator-bean-view .views-field-title {
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 10px;
}

.pane-bean-homepage-quote blockquote {
    color: #480302 !important;
    font-size: 2.5rem !important;
    border-left: 5px solid #e2a731;
}

.pane-content-rotator-view-panel-pane-1 {
    border: 1px solid red;
    border-radius: 4px;
    padding: 10px;
    background: #480302;
    color: #ffffff;
    font-size: 1.1em;
}

//Forms
#addressfield-wrapper {
    padding-top: 25px;
}

@import 'global/global';
@import 'layout/layout';
@import 'modules/modules';
@import 'pages/pages';
.h2,
h2 {
    margin-top: 26px;
}

main.container .pane-entity-field.pane-node-body a {
    text-decoration: none;
    border-bottom: 1px dotted #295376;
    padding-bottom: 1px;
    &:hover {
        text-decoration: none;
        border-bottom: 1px solid #295376;
    }
}

header .brand a {
    border-bottom: none;
    font-size: 26px;
    font-weight: 700;
    color: #480302;
}

.page-node-add,
.page-node-edit {
    .hierarchical-select-wrapper .hierarchical-select .selects {
        max-width: 100%;
    }
    #edit-title-field-und-0-value {
        margin-bottom: 23px;
    }
    .panel-default {
        margin-bottom: 23px;
    }
    .field-widget-options-select {
        margin-bottom: 23px;
    }
    .burr-flipped2 {
        .burr-flipped2-content {
            width: 100%;
        }
        .burr-flipped2-sidebar {
            width: 0;
            overflow: hidden;
            display: none;
        }
    }
    .burr-flipped2 {
        .burr-flipped2-content {
            position: relative;
            #sticky-wrapper {
                float: right;
                margin-right: 30px;
            }
            a.slidetoggle {
                width: 30px !important;
                z-index: 9999 !important;
                height: 30px;
                background: #335075;
                border-radius: 5px;
                text-align: right;
                position: absolute;
                right: auto;
                &:after {
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f0c7";
                    color: #fff;
                    padding: 8px 9px;
                    display: inline-block;
                }
            }
        }
    }
    &.sidebar-open {
        .burr-flipped2 {
            .burr-flipped2-content {
                width: 68.085%;
                a.slidetoggle {
                    &:after {
                        font: normal normal normal 14px/1 FontAwesome;
                        content: "\f013";
                        color: #fff;
                        padding: 8px 9px;
                        display: inline-block;
                    }
                }
            }
            .burr-flipped2-sidebar {
                width: 31.915%;
                display: block;
            }
        }
    }
}

.enforcement-actions {
    .form-inline .form-group {
        margin-bottom: 10px;
        &.form-actions {
            display: block;
        }
    }
}

// .pane-node-body {
//   .panel.panel-primary {
//     ul {
//       list-style: none;
//       padding:15px;
//       margin:0;
//       li {
//         margin-bottom: 5px;
//       }
//       ul {
//         padding-bottom: 0px;
//         padding-top: 5px;
//       }
//     }
//   }
// }
// @import "elements/landing-page";
// @import "elements/new-styles";
// @import "elements/general";
// @import "elements/search-results";
// @import "elements/slider";
// @import "elements/side-nav";
// @import "elements/ruling";
// @import "elements/publications";
// @import "elements/decisions";
// @import "elements/icons";
// Landing list items
.content-listings {
    .view-header {
        // // @include inline-block();
        padding: 0.5em;
        background-color: #e3eff3;
        font-weight: 700;
        margin: 1em 0;
    }
    .view-content {
        .item-list ol {
            margin-left: -18px !important;
            li {
                margin-left: 0 !important;
                text-indent: 0 !important;
            }
        }
    }
    //border after each row
    .views-row {
        border-bottom: 1px solid #eee !important;
        // background-color: white !important;
        padding-bottom: 1em !important;
        margin-bottom: 0.6em !important;
        overflow: hidden;
        // .views-field-field-published-date, .content-date {
        //   float: right;
        //   margin-top:10px;
        // }
        .views-field-counter,
        .views-field-field-listing-title-long,
        .views-field-title-field {
            .field-content {
                a {
                    font-size: em(13) !important;
                    line-height: em(20) !important;
                    color: #295376 !important;
                    // font-weight: bold;
                }
            }
        }
    }
    .views-field-teaser {
        margin-left: 0 !important;
        .field-content {
            padding-top: 0.2em;
            font-size: em(13) !important;
            line-height: em(22) !important;
            color: black !important;
        }
    }
    .views-field-ds-changed {
        .field-content {
            font-style: italic;
        }
    }
    .views-field-bundle-name,
    .views-field-ds-changed,
    .views-field-sm-vid-Publication-Type,
    .views-field-ts-publication-type {
        margin-left: 0 !important;
        .field-content {
            font-size: em(13);
            line-height: em(18);
            color: #464646 !important;
        }
    }
    .pager {
        margin-left: 0.8em !important;
        margin-right: 0.5em !important;
    }
}

// .front {
//   #block-system-main,
//   #content {
//     section {
//       margin-bottom: 1em;
//       padding-bottom: 1em;
//     }
//   }
// }
footer#wb-info {
    border-top: 1px solid #7a0806;
    ul.menu.nav {
        li {
            a {
                padding: 0;
                background: transparent !important;
            }
        }
    }
}

footer#wb-info>nav>ul {
    column-count: initial !important;
    -moz-column-count: initial !important;
}

h1 {
    margin-top: 20px;
}

ul.facetapi-facetapi-links {
    padding-left: 0;
    margin-left: 0;
    li {
        margin-bottom: 7px;
        a {
            display: block;
            position: relative;
            padding-right: 60px;
            .facetapi-facet-count {
                position: absolute;
                right: 0;
            }
            &.facetapi-active {
                &:after {
                    position: absolute;
                    font-size: 12px;
                    top: 50%;
                    margin-top: -10px;
                    padding: 4px 7px;
                    right: 10px;
                    width: 24px;
                    height: 24px;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    background: #335075;
                    color: #fff;
                    border-radius: 50%;
                    content: "";
                    font-family: 'FontAwesome';
                }
            }
        }
    }
}

.page-search {
    .current-search-item {
        margin: 1em 0;
    }
}

.well-title {
    margin-top: 0;
}

.node-type-publication {
    .alt-format-panel {
        margin-top: 20px;
        float: right;
        margin: 0 0 15px 15px;
        min-width: 200px;
    }
    .publication-banner {
        img {
            max-width: 100%;
        }
    }
    .alt-format {
        background-color: #2572b4;
        color: #fff;
        font-size: 80%;
        margin-bottom: 10px;
        .field-label {
            font-weight: normal;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            float: left;
            li {
                float: left;
                display: inline;
                margin: 8px 5px;
                padding: 3px 2px;
                a {
                    text-decoration: none !important;
                    color: #fff !important;
                }
            }
            &:before {
                font: normal normal normal 25px/1 FontAwesome;
                content: "\f054";
                color: #fff;
                padding: 10px;
                vertical-align: middle;
                float: left;
                display: inline-block;
            }
        }
    }
}

.legal-list>ol,
.legal-list ol.list-decimal,
.legal-list ol.list-numeric {
    counter-reset: legal-list-1;
    li {
        &:before {
            content: "(" counter(legal-list-1, decimal) ") ";
            counter-increment: legal-list-1;
        }
    }
}

.legal-list>ol>li>ol,
.legal-list ol.list-lower-alpha {
    counter-reset: legal-list-2;
    li {
        &:before {
            content: "(" counter(legal-list-2, lower-alpha) ") ";
            counter-increment: legal-list-2;
        }
    }
}

.legal-list>ol>li>ol li>ol,
.legal-list ol.list-lower-roman {
    counter-reset: legal-list-3;
    li {
        &:before {
            content: "(" counter(legal-list-3, lower-roman) ") ";
            counter-increment: legal-list-3;
        }
    }
}

.legal-list {
    li {
        position: relative;
        display: list-item;
        list-style-type: none;
        &:before {
            display: block;
            position: absolute;
            line-height: 120%;
            left: -1.75em;
            top: 0.5em;
        }
    }
    ol {
        li {
            text-indent: 0 !important;
            margin-left: 1.5em !important;
            display: list-item;
            list-style-type: none;
            padding-top: 6px;
        }
    }
}

dl.table-display {
    dt {
        border-top: 1px solid #d4d4d4;
        clear: both;
        float: left;
        font-size: em(12);
        line-height: em(22);
        font-weight: 700;
        width: 20%;
        padding: 5px 1%;
    }
    dd {
        border-top: 1px solid #d4d4d4;
        clear: right;
        font-size: em(12);
        line-height: em(22);
        padding: 5px 2%;
        margin-left: 0;
        float: left;
        width: 74%;
    }
    clear:both;
}

.definition-1 {
    dt {
        color: #007a91;
        font-size: em(16);
        font-weight: 700;
    }
    dd {
        font-size: em(14);
        margin-left: 20px;
        margin-top: 0.5em;
        margin-bottom: 1em;
        max-width: 80%;
    }
}

.definition-2 {
    dt {
        color: black;
        font-size: em(13);
        font-weight: 700;
    }
    dd {
        font-size: em(12);
        margin-left: 20px;
        margin-top: 0.5em;
        margin-bottom: 1em;
    }
}

.expand {
    line-height: 150%;
    display: block;
    color: #007fb5;
    font-size: em(18);
    font-weight: bold;
    border-bottom: 1px dashed #a5a5a5;
    background-image: url('../images/blue-plus-sign.png');
    background-repeat: no-repeat;
    background-position: 20% center;
}

.collapse {
    line-height: 150%;
    display: block;
    padding-right: 30px;
    color: #007fb5;
    font-size: em(18);
    font-weight: bold;
    border-bottom: 1px dashed #a5a5a5;
    background-image: url('../images/blue-minus-sign.png');
    background-repeat: no-repeat;
    background-position: 20% center;
}

.logged-in.admin-menu {
    .collapse {
        display: none;
        background: transparent;
        border: none;
        color: #000;
        padding-right: 0;
        &.in {
            display: block;
        }
    }
}

.collapse-note {
    font-size: em(13);
    background-color: #f5f5f5;
    padding: 1em;
}

.highlight-box-2 {
    h2 {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    padding: 0.5em 1em;
    //    max-width: 45%;
    border: 1px solid #ccc;
    background-color: #ebf1eb;
    .divider {
        background-color: #ccc;
        height: 1px;
        display: block;
        //      width: 100%;
        text-align: center;
    }
}

.map-1 {
    margin-left: 10px;
    width: 300px;
    border: 1px solid #cbcbcb; // stroke
    p {
        text-align: center;
        color: #555 !important;
        font-weight: 600;
    }
    img {
        width: 300px;
        height: 190px;
        padding: 0;
        margin: 0;
    }
}

.map-2 {
    margin-left: 10px;
    width: 300px;
    border: 1px solid #cbcbcb; // stroke
    border-radius: 0; // border radius
    background-clip: padding-box; // prevents bg color from leaking outside the border
    background-color: #f9f9f9; // layer fill content
    p:first-child {
        padding: 0 !important;
        margin: 0 !important;
    }
    img {
        width: 300px;
        height: 190px;
        padding: 0;
        margin: 0;
    }
    p {
        text-align: center;
        color: #555 !important;
        font-weight: 700;
    }
}

.wet-boew-note {
    background-color: #FFFAF8;
    border-top: 1px solid #bb3700;
    border-bottom: 1px solid #bb3700;
    h2 {
        margin-top: 0;
        padding-top: 10px !important;
        padding-bottom: 0.1em !important;
        border-bottom: 1px solid #c5c0c4;
    }
    dl {
        margin-left: 10px;
        dt {
            color: #007a91;
            //font-size: em(16);
            font-weight: 700;
        }
        dd {
            //font-size: em(14);
            margin-left: 0;
            margin-top: 0.5em;
            margin-bottom: 1em;
            max-width: 80%;
            p {
                margin-left: 0;
                margin-top: 0;
                display: inline;
            }
            p.note-return {
                padding: 0 !important;
                display: inline;
            }
        }
    }
}

details.black {
    h2,
    h3,
    h4,
    h5,
    h6 {
        background-color: #F5F5F5 !important;
        padding-left: 10px;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 1em;
    }
    dl dd p {
        background-color: #ffffff !important;
    }
    summary {
        background-color: transparent;
        color: #555555;
        font-weight: 700;
        font-size: em(14);
        line-height: em(25);
        border-bottom: 1px dashed #a5a5a5;
    }
    p {
        background-color: #F5F5F5 !important;
        padding: 10px;
        margin: 0;
        text-indent: 0;
    }
    ol,
    ul {
        padding: 10px;
        background-color: #F5F5F5 !important;
        margin: 0;
    }
}

#wb-dtmd {
    float: right;
}

.back-to-link {
    margin-top: 20px;
    margin-bottom: 20px;
}

.alert-title {
    margin-top: 0;
}

.ruling-date {
    margin-bottom: 15px;
}

.ruling-suspension {
    .alert-title {
        display: none;
    }
    .alert-body {
        padding-left: 30px;
    }
}

.ruling-title {
    font-weight: bold;
}

.ruling-case-number,
.ruling-file-number {
    margin-bottom: 15px;
}

.homepage-news.panel-primary {
    border-color: #bb3700;
    .panel-heading {
        background: #bb3700;
        border-color: #bb3700;
    }
}

.homepage-chair.panel-primary {
    border-color: #275132;
    .panel-heading {
        background: #275132;
        border-color: #275132;
    }
    img {
        width: 100%;
    }
    ul {
        padding: 5px 0 0 !important;
        border-bottom: 0 !important;
        list-style: none;
        li {
            border-bottom: 1px solid #295378;
            margin-left: 0 !important;
            a {
                padding-right: 20px;
                background-image: url("/sites/all/themes/cta_bootstrap/images/blue-arrow.png");
                background-repeat: no-repeat;
                background-position: center right;
                background-size: 14px 14px;
                display: block;
                padding: 5px 20px 5px 0;
            }
            &:last-child {
                border: none;
            }
        }
    }
}

.front {
    .berton-content-banners.row {
        padding-top: 30px;
    }
    .content-listings .views-row .views-field-field-published-date {
        float: none;
    }
}

.mark-redacted {
    background-color: #000000;
    color: #ffffff;
}

.mark,
mark {
    background-color: #fcf8e3;
    padding: 0.2em;
}

// Button
.btn-danger {
    color: #fff !important;
}

.media {
    .file-image {
        img {
            max-width: 100%;
        }
    }
}

.flysmart-submenu {
    padding-bottom: 2em;
    ul {
        background-color: #e7e7e7;
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        margin: 0;
        padding: 0;
        @include bp(s) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
        @include bp(m) {
            -webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
        }
        li {
            background-color: #e7e7e7;
            padding: 10px;
            a {
                font-weight: bold;
                padding: 0;
                &.active-trail.active {
                    &:before {
                        font-family: FontAwesome;
                        font-weight: normal;
                        font-style: normal;
                        display: inline-block;
                        text-decoration: inherit;
                        content: " \f04b" !important;
                    }
                }
                &:hover {
                    background: transparent;
                    text-decoration: underline;
                }
            }
        }
    }
}

.flysmart-topmenu {
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 11px;
        li {
            width: calc(20% - 16px);
            float: left;
            text-align: center;
            margin: 0 10px;
            cursor: pointer;
            a {
                padding: 5px;
                font-weight: bold;
                font-size: 110%;
                border-bottom: 2px solid #fff;
            }
            &.active {
                &:before {
                    height: 11px;
                    content: '';
                    background-image: url("../images/fly-smart/fs-nav-arrow-sprite.png");
                    background-position: 0 0;
                    background-size: 105%;
                    display: block;
                    position: absolute;
                    bottom: -11px;
                    left: 0;
                    width: 100%;
                }
            }
            &:after {
                background-image: url("../images/fly-smart/fs-nav-sprite.png");
                background-position: 0 0;
                background-size: 202%;
                display: block;
                height: 160px;
                width: 100%;
                position: relative;
                content: '';
                @include bp(s) {
                    height: 97px;
                }
                @include bp(m) {
                    height: 130px;
                }
            }
            &:first-child,
            &:last-child {
                a {
                    color: #fff;
                    background-color: #bb3800;
                }
            }
            &:first-child {
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 0;
                    }
                }
                margin-left: 0;
            }
            &:nth-child(2) {
                a {
                    background: #265277;
                    color: #fff;
                }
                &:after {
                    background-position: 0 25%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 25%;
                    }
                    &:before {
                        background-position: 0 25%;
                    }
                }
            }
            &:nth-child(3) {
                a {
                    background: #0d661b;
                    color: #fff;
                }
                &:after {
                    background-position: 0 50%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 50%;
                    }
                    &:before {
                        background-position: 0 50%;
                    }
                }
            }
            &:nth-child(4) {
                a {
                    background: #7a4376;
                    color: #fff;
                }
                &:after {
                    background-position: 0 75%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 100% 75%;
                    }
                    &:before {
                        background-position: 0 75%;
                    }
                }
            }
            &:last-child {
                &:after {
                    background-position: 0 100%;
                }
                &.active,
                &:hover {
                    &:after {
                        background-position: 0 100%;
                    }
                    &:before {
                        background-position: 0 100%;
                    }
                }
                margin-right: 0;
            }
        }
    }
}

.take-charge-side-menu {
    ul {
        margin: 0;
        padding: 0;
        li {
            border-bottom: 1px solid #173247;
            padding: 0;
            margin: 0;
            a {
                padding: 10px;
                margin: 0;
                text-decoration: none;
                color: #173247;
                font-weight: bold;
                &:hover {
                    background: transparent;
                    text-decoration: underline;
                }
                &.active {
                    color: #818181;
                }
            }
        }
    }
}

.node-type-web-page {
    .pane-node-body {
        .col-md-8 {
            h2:first-child {
                margin-top: 0;
            }
        }
    }
}

.clearfix {
    clear: both;
}

.pane-node-body {
    img {
        max-width: 100%;
        height: auto;
    }
}

.submitted-comments {
    .view-content {
        margin-top: 10px;
    }
    ul.download-file {
        padding-left: 0;
        list-style: none;
        display: inline-block;
    }
}

.pane-node-toc {
    clear: both;
}

.front {
    .region-berton-content-listings {
        img {
            width: 100%;
        }
    }
}

.brand {
    border-top-color: #f8f8f8 !important;
    padding-top: 0px !important;
    margin-top: -10px;
}

.theme-gcweb .panel-primary>.panel-heading {
    background-color: #7a0806 !important;
    border-color: #7a0806 !important;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #b0151e !important;
    border-color: #b0151e !important;
}

.panel-primary {
    border-color: #7a0806 !important;
}

#wb-glb-mn ul.chvrn,
#mb-pnl .info-pnl,
#mb-pnl .srch-pnl form button:active,
#mb-pnl .srch-pnl form button:focus,
#mb-pnl .srch-pnl form button:hover {
    background-color: #7a0806 !important;
    border: none !important;
}

#mb-pnl .sm-pnl,
#mb-pnl .modal-body,
#mb-pnl .modal-header {
    background-color: #b0151e !important;
    border: none !important;
}

#mb-pnl .modal-header:before {
    border: none !important;
}

#mb-pnl .srch-pnl form button {
    background-color: rgb(122, 8, 6) !important;
    border-color: none !important;
}

#mb-pnl {
    background-image: none !important;
    /* background-color: #7a0806 !important; */
}

h2.pane-title {
    color: #480302;
}

.pane-date-mod {
    margin-top: -20px;
    width: 50%;
    float: right;
}

.wb-share-inited,
.pane-bean-share-this-page {
    float: left;
    width: auto !important;
}

ol.paragraph_list>li:before {
    content: "[      ] ";
    counter-increment: list;
    left: -2.0em;
    position: relative;
    white-space: pre;
}

.cta-home-top .right .panel-separator {
    padding-bottom: 10px;
}

cta-home-top {
    margin-top: 10px;
}

.cta-home-mid img {
    width: 92%;
    margin-left: 4%;
}

.cta-home-mid .pane-news-releases-panel-pane-2,
.cta-home-mid .pane-bean-cta-gc-twitter-home-block {
    /*
  border: 1px solid #dca740;
  border-radius: 4px;
  */
}

.cta-home-mid .pane-bean-consumer-protection-for-air-trav {
    /*
  border-top: 1px solid #dca740;
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-radius: 4px;
  */
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.cta-home-mid .pane-bean-fly-smart-home-block {
    /*
  border-left: 1px solid #dca740;
  border-right: 1px solid #dca740;
  border-bottom: 1px solid #dca740;
  border-radius: 4px;
  */
    margin-top: -5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cta-home-mid h2.pane-title {
    margin-top: 0px;
    background: #dca740;
    border-color: #dca740;
    padding: 10px 15px;
    font-size: 20px !important;
}

@media (min-width: 992px) {
    .cta-home-mid .container .row {
        display: flex;
    }
}

.cta-home-mid .container .row>div {
    flex: 1;
    border: 1px solid black;
    margin: 15px;
    padding: 0px;
    border: 1px solid #dca740;
    border-radius: 4px;
}

.cta-home-mid .container {
    border-bottom: 1px solid #7a0806;
    padding-bottom: 20px;
}

.cta-home-bot {
    background: none;
    padding-top: 0px;
}

.cta-home-bot .row {
    border-bottom: 1px solid #7a0806;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background-color: #7a0806;
    color: #ffffff;
}

.cta-home-bot h2.pane-title {
    color: #ffffff !important;
    margin-top: 0px;
    font-size: 1.5em;
}

.pane-news-releases-panel-pane-2 .pane-content,
.pane-bean-consumer-protection-for-air-trav .pane-content,
.pane-bean-cta-gc-twitter-home-block .pane-content {
    padding: 15px;
}


/*
.view-content-rotator-bean-view {
  color: #480302;
}
*/

.view-content-rotator-bean-view .views-field-title {
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 10px;
}

.pane-bean-homepage-quote blockquote {
    color: #480302 !important;
    font-size: 2.5rem !important;
    border-left: 5px solid #e2a731;
}

.pane-content-rotator-view-panel-pane-1 {
    border: 1px solid red;
    border-radius: 4px;
    padding: 10px;
    background: #480302;
    color: #ffffff;
    font-size: 1.1em;
}

//Forms
.webform-component-addressfield {
    margin: 25px auto;
}

.webform-component-radios {
    margin: 15px auto;
}

#edit-submitted-additional-applicants-add-row-additional-applicants {
    margin-bottom: 25px;
}

.webform-client-form {
    .table {
        margin-top: 20px;
    }
    .form-type-textfield {
        margin: 15px auto;
    }
}

/* Joseph fixes for regressions caused by wetkit 7.x-4.50 upgrade.*/
#edit-xml-body fieldset.collapsed, #edit-xml fieldset.collapsed {
  height: unset !important;
}

/* Joseph fixes for regressions caused by wetkit 7.x-4.50 upgrade , affects permissions page possibly other tables#*/
.page-admin table td, .page-admin table th {
  display: table-cell;
}

// Fix for multi file upload widget on forms
.form-actions, fieldset .panel-body {
  clear: both;
}