.row {
    figure.video {
        .pane-content & {
            .btn-default {
                width: inherit;
                height: inherit;
                padding: 0;
                margin: 0;
                background-color: inherit;
                &:after {
                    content: none;
                }
            }
            .tline {
                padding: 0;
            }
        }
    }
    figcaption {
        margin-top: 10px;
    }
}