.listings-view {
  ul {
    list-style: none;
    margin:0;
    padding:0;
    li {
      display: block;
      padding:15px 0;
      margin:0;
      border-bottom:1px solid #ddd;
      a {
        padding: 10px 20px 10px 0;
        display: block;
      }
      &:first-child {
        padding-top:0;
      }
      &:last-child {
        padding-bottom:0;
      }
    }
  }
}
body {
  background:$white;
}
iframe {
  max-width:100%;
}
.btn {
  &.btn-secondary {
    background:#E2A731;
    color:black;
    border-radius: 0;
    font-size: 1.8rem;
    padding: 10px 20px;
    margin-top: 10px;
  }
}
.front {
  .homepage-chair {
    background:#480302;
    padding:15px;
    img {
      width:100%;
      height: auto;
    }
    ul {
      list-style: none;
      margin:0;
      padding:0;
      text-align: center;
      li {
        margin:0;
        padding:0;
        display: inline-block;
        a {
          background:#E2A731;
          color:black;
          border-radius: 0;
          font-size: 1.8rem;
          padding: 10px 20px;
          display: block;
          margin: 10px 10px 0 10px;
          text-decoration: none;
        }
      }
    }
  }
  .cta-home-top {
    .pane-bean-home-page-slider {
      display: block;
      padding:5px;
      background:$white;
    }
    .right {
      a {
        display: block;
        padding:5px;
        background:$white;
        margin-bottom: 10px;
        img {
          width:100%;
        }
      }
    }
  }
  #wb-info {
    margin-top:0;
  }
  .bg-white {
    padding:20px 0;
    background:$white;
    h2 {
      /* font-size: 2rem; */
    }
    .btn {
      &.btn-primary {
        background:#E2A731;
        color:black;
        border-radius: 0;
        font-size: 1.8rem;
        border:none;
        padding: 10px 20px;
      }
    }
  }
  .bg-gray {
    padding:10px 0;
    background:#eee;
  }
  .bg-red {
    .btn {
      &.btn-primary {
        background:#E2A731;
        color:black;
        border-radius: 0;
        font-size: 1.8rem;
        border:none;
        padding: 10px 20px;
      }
    }
    padding:50px 0;
    background:$secondary;
    blockquote {
      color:$white;
      font-size: 3.2rem;
      line-height: 3.4rem;
      font-weight: bold;
      border-color:$yellow;
    }
  }
}
