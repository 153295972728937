.pane-bean-contact-us {
    .media-element-container {
        margin-right: 10px;
        margin-top: 0;
        .file-image {
            .wetkit-image-quarter {
                max-width: 25px;
            }
        }
    }
}