#gc-bar {
    margin-top: 25px;
    li {
        font-size: 16px;
        // color: black;
        a {
            color: black;
            margin-left: 20px;
            font-weight: 600;
            text-decoration: none;
            &:visited {
                color: black;
            }
            &:hover {
                color: #284162;
            }
        }
    }
}

//mobile menu
#mb-pnl {
    .srch-pnl {
        display: none;
    }
    .lng-ofr {
        padding: 10px 22px 8px;
        padding-left: 23px;
        padding-right: 24px;
        padding-top: 0;
        .list-inline {
            text-align: left;
            margin-bottom: -17px;
            li {
                margin-top: 9px;
                display: inline-block;
                // padding: 0 6px;
                width: 100%;
                a {
                    // border: 1px solid white;
                    display: inline-block;
                    padding: 0 6px;
                    width: 100%;
                }
            }
        }
    }
    .modal-footer {
        .btn-primary {
            &:before {
                content: none;
            }
        }
    }
}

.glyphicon-search {
    &:before {
        content: none;
    }
}