.gc-nttvs {
    margin-top: 30px;
    margin-bottom: 30px;
}

footer#wb-info {
    margin-top: 30px;
    background-color: $secondary-blue;
    border-top: none;
    body & {
        border-top: inherit;
        // font-size: 19px;
        color: black;
    }
    .pane-bean-about-us,
    .pane-bean-contact-us,
    .pane-menu-wet-mid-footer {
        margin-bottom: 25px;
        @include bp(m) {
            margin-bottom: inherit;
        }
        @include bp(l) {
            margin-bottom: inherit;
        }
        .pane-title {
            margin-bottom: 15px;
            font-size: 22px;
            font-weight: 700;
            overflow: unset;
            position: relative;
            height: inherit;
            width: inherit;
            color: black;
        }
        .pane-content {
            .field-name-field-bean-wetkit-body {
                font-size: 15px;
            }
        }
    }
    //contact block
    .pane-bean-contact-us {
        .pane-content {
            .field-name-field-bean-wetkit-body {
                // font-size: inherit;
            }
        }
    }
    //links block
    .pane-menu-wet-mid-footer {
        margin-bottom: 25px;
        @include bp(m) {
            margin-bottom: inherit;
        }
        @include bp(l) {
            margin-bottom: inherit;
        }
        .pane-title {
            margin-top: 0;
        }
        .menu-block-ctools-menu-wet-mid-footer-1 {
            .nav {
                li {
                    a {
                        color: black;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .brand {
        border-top: none;
        background-color: white;
        nav.ftr-urlt-lnk {
            .links {
                list-style-type: none;
                li {
                    margin-left: 0;
                    &:before {
                        content: none;
                    }
                    a {
                        list-style: none;
                    }
                }
            }
        }
        .col-xs-6.col-md-2.text-right {
            padding-top: 0px;
            @include bp(s) {
                padding-top: 0px;
            }
            @include bp(m) {
                padding-top: 0px;
            }
            @include bp(l) {}
            object {
                // padding-top: 25px;
            }
        }
    }
    .openplus-50_25_25 {
        padding-bottom: 25px;
        .row {
            .left {
                // padding-right: 55px;
                @include bp(xs) {
                    padding-right: 15px;
                }
                @include bp(s) {
                    padding-right: 55px;
                }
                @include bp(m) {
                    padding-right: 55px;
                    border-right: 1px solid $tertiary-blue;
                }
                @include bp(l) {
                    border-right: 1px solid $tertiary-blue;
                    padding-right: 55px;
                }
            }
            .center {
                @include bp(xs) {
                    padding-left: 15px;
                }
                @include bp(s) {
                    padding-left: 15px;
                }
                @include bp(m) {
                    padding-left: 55px;
                    border-right: 1px solid $tertiary-blue;
                }
                @include bp(l) {
                    border-right: 1px solid $tertiary-blue;
                    padding-left: 55px;
                }
            }
            .right {
                @include bp(xs) {
                    padding-left: 15px;
                }
                @include bp(s) {
                    padding-left: 15px;
                }
                @include bp(m) {
                    padding-left: 55px;
                }
                @include bp(l) {
                    padding-left: 55px;
                }
            }
        }
    }
    .field-item {
        .coat-of-arms-logo {
            display: block;
            margin-bottom: 25px;
        }
    }
}