    .page-air-travel-complaints,
    .node-type-form {
        .pane-page-title {
            h1 {
                border-bottom: none;
            }
        }
        .webform-component-markup {
            // background-color: white;
            .well {
                background-color: white;
                border: none;
                padding: 0;
                box-shadow: none;
            }
            .alert,
            .alert.alert-info {
                background-color: $secondary-blue;
                border: none;
                padding: 20px;
                // display: none;
                h2 {
                    line-height: 1.47;
                    margin-left: 0;
                    &:before {
                        content: '';
                    }
                }
            }
            //button 
            &.form-item.webform-component--wxt-group--complaint-subject-acc--links-1,
            &.webform-component--wxt-group--communications-acc--links-2 {
                // display: none;
                margin-top: 15px;
                display: inline-block;
                float: right;
                margin-bottom: 50px;
                width: 100%;
                @include bp(s) {
                    width: 100%;
                }
                @include bp(m) {
                    width: 50%;
                    margin-right: -17px;
                }
                @include bp(l) {
                    width: 50%;
                    margin-right: -17px;
                }
                .btn-default {
                    position: relative;
                    background-color: $primary-red;
                    color: white;
                    width: 100%;
                    font-size: 19px;
                    border: 2px solid transparent;
                    &:hover,
                    &:focus {
                        border: 2px solid $primary-red;
                        background-color: white;
                        color: $primary-red;
                        &:after {
                            background-image: url("../images/icons/right_arrow_red.png");
                        }
                    }
                    &:after {
                        position: absolute;
                        top: 11.5px;
                        right: inherit;
                        content: ' ';
                        margin-left: 5px;
                        width: 17px;
                        height: 17px;
                        background-image: url("../images/icons/right_arrow_white.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        display: inline-block;
                        font-size: 19px;
                        @include bp(s) {
                            top: 12.5px;
                            right: inherit;
                        }
                        @include bp(m) {
                            top: 12.5px;
                            right: inherit;
                        }
                        @include bp(l) {
                            top: 12.5px;
                            right: inherit;
                        }
                    }
                }
                .btn-white {
                    display: none;
                }
            }
            &.webform-component--wxt-group--communications-acc--links-2,
            &.webform-component--wxt-group--complaint-details-acc--links-3 {
                width: 100%;
                @include bp(s) {
                    width: 100%;
                }
                @include bp(m) {
                    width: 100%;
                    margin-right: 0px;
                }
                @include bp(l) {
                    width: 100%;
                    margin-right: 0px;
                }
                .btn-default {
                    margin-right: inherit;
                    position: relative;
                    background-color: $primary-red;
                    color: white;
                    width: 48%;
                    margin-right: 4%;
                    &:first-child {
                        background-color: white;
                        color: $primary-red;
                        font-size: 19px;
                        border: 2px solid $primary-red;
                        padding-right: 15px;
                        &:after {
                            content: none;
                        }
                        &:before {
                            position: relative;
                            top: 2.5px;
                            content: ' ';
                            margin-left: -20px;
                            margin-right: 5px;
                            width: 17px;
                            height: 17px;
                            background-image: url("../images/icons/left_arrow_red.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            display: inline-block;
                            font-size: 19px;
                        }
                        &:hover,
                        &:focus {
                            border: 2px solid $primary-red;
                            background-color: white;
                            color: $primary-red;
                            &:before {
                                background-image: url("../images/icons/left_arrow_red.png");
                            }
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &.webform-component--wxt-group--complaint-details-acc--links-3 {
                margin-bottom: -62px;
            }
            button.btn-white {
                margin-top: 10px;
                font-size: 18px;
                background: white;
                font-weight: 600;
                padding: 10px;
                width: 100%;
                -webkit-appearance: none;
                border: 1px solid black;
                border-radius: 3px
            }
        }
        .accordion-open {
            margin-top: -47px;
            margin-top: -78px;
        }
        .pane-node-webform .form-actions {
            // display: none;
            // margin-top: -47px;
            display: inline-block;
            float: right;
            margin-top: 15px;
            margin-bottom: 50px;
            width: 50%;
            @include bp(s) {
                // width: 100%;
            }
            @include bp(m) {
                width: 50%;
                // margin-right: -17px;
                // margin-top: -47px;
            }
            @include bp(l) {
                width: 50%;
                margin-right: -17px;
            }
            .btn-default,
            .btn-primary {
                max-width: 570px;
                position: relative;
                background-color: $primary-red;
                color: white;
                width: 100%;
                font-size: 19px;
                border: 2px solid transparent;
                &:hover,
                &:focus {
                    border: 2px solid $primary-red;
                    background-color: white;
                    color: $primary-red;
                    &:after {
                        background-image: url("../images/icons/right_arrow_red.png");
                    }
                }
                &:after {
                    position: absolute;
                    top: 11.5px;
                    right: inherit;
                    content: ' ';
                    margin-left: 5px;
                    width: 17px;
                    height: 17px;
                    background-image: url("../images/icons/right_arrow_white.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    display: inline-block;
                    font-size: 19px;
                    @include bp(s) {
                        top: 12.5px;
                        right: inherit;
                    }
                    @include bp(m) {
                        top: 12.5px;
                        right: inherit;
                    }
                    @include bp(l) {
                        top: 12.5px;
                        right: inherit;
                    }
                }
            }
        }
        .pane-node-webform .webform-client-form.preview {
            .form-actions {
                width: 100%;
                margin-right: inherit;
                .webform-previous,
                .webform-submit {
                    width: 48%;
                    margin-right: 0;
                    @include bp(s) {}
                    @include bp(m) {}
                    @include bp(l) {
                        width: 49%;
                    }
                }
                .webform-previous {
                    margin-right: 4%;
                    float: left;
                    background-color: white;
                    color: $primary-red;
                    font-size: 19px;
                    border: 2px solid $primary-red;
                    @include bp(l) {
                        margin-right: 2.5%;
                    }
                    &:after {
                        content: none;
                    }
                }
                .webform-submit {}
            }
            //flight from city field
            .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
            .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
                margin-top: 15px;
            }
            .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 {
                margin-top: 10px;
            }
            .col-md-6 {
                width: 100%;
            }
            a.printbtn {
                margin-top: 10px;
                margin-bottom: 10px;
                float: right;
            }
        }
        .accordion {
            .acc-group {
                clear: both;
                position: relative;
                visibility: inherit;
                background-color: $tertiary-blue;
                background-color: #D8D8D8;
                border-bottom: 10px solid #919191;
                padding-top: 15px;
                padding-bottom: 15px;
                padding: 0;
                width: 100%;
                summary.wb-toggle-inited {
                    font-size: 19px;
                    list-style: none;
                    color: black;
                    font-weight: 600;
                    border: none;
                    padding: 0;
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    padding: 20px;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    margin-bottom: 0;
                    padding-right: 40px;
                    @include bp(m) {
                        padding-right: 20px;
                    }
                    @include bp(l) {
                        padding-right: 20px;
                    }
                    @include bp(m) {
                        font-size: 22px;
                    }
                    @include bp(l) {
                        font-size: 22px;
                    }
                    &:before {
                        content: " ";
                    }
                    &:after {
                        position: absolute;
                        top: 17px;
                        right: 15px;
                        content: ' ';
                        margin-left: 15px;
                        width: 20px;
                        height: 20px;
                        background-image: url("../images/icons/up_arrow.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        display: inline-block;
                        font-size: 19px;
                        @include bp(s) {
                            top: 17px;
                        }
                        @include bp(m) {
                            top: 16.5px;
                        }
                        @include bp(l) {
                            top: 15px;
                        }
                    }
                    &::-webkit-details-marker {
                        display: none;
                        // float: right;
                        // list-style-image: url("../images/icons/down_arrow.png");
                    }
                    &::-ms-expand {
                        display: none;
                    }
                }
                .tgl-panel {
                    padding: 20px;
                    background-color: white;
                }
                &.on {
                    background-color: $tertiary-blue;
                    border: none;
                    summary.wb-toggle-inited {
                        border: none;
                        color: black;
                        background-color: $tertiary-blue;
                        border-bottom: 10px solid $primary-red;
                        &:after {
                            position: absolute;
                            top: 17px;
                            right: 15px;
                            content: ' ';
                            margin-left: 15px;
                            width: 20px;
                            height: 20px;
                            background-image: url("../images/icons/down_arrow.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            display: inline-block;
                            font-size: 19px;
                            @include bp(s) {
                                top: 17px;
                            }
                            @include bp(m) {
                                top: 16.5px;
                            }
                            @include bp(l) {
                                top: 15px;
                            }
                        }
                    }
                }
                //*******************************
                //FORM ELEMENTS FOR THIRD SECTION
                //*******************************
                .tgl-panel {
                    // padding: 0;
                    .webform-component-fieldset {
                        box-shadow: none;
                        padding-top: 30px;
                        border: none;
                        border-bottom: 1px solid black;
                        border-radius: 0;
                        .panel-heading {
                            background-color: inherit;
                            border: none;
                            // padding-top: 30px;
                            // padding-bottom: 30px;
                            .panel-title {
                                font-weight: 700;
                            }
                        }
                        .panel-body {
                            padding-top: 0;
                            padding-bottom: 30px;
                        }
                    }
                    .webform-component--wxt-group--complaint-details-acc--pics-confirmation,
                    .webform-component--wxt-group--complaint-details-acc--pics--pics-confirmation {
                        // display: none;
                        .webform-component-checkboxes {
                            .form-item {
                                .control-label {
                                    font-size: 14px;
                                }
                            }
                        }
                        .form-item {
                            .control-label {
                                padding-top: 5px;
                                font-size: 14px;
                            }
                        }
                        .control-label.required {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                    .webform-component--wxt-group--complaint-details-acc--complaint-details-fieldset {
                        .control-label.required {
                            font-weight: 400;
                            font-size: 14px;
                            margin-bottom: 15px;
                            margin-left: 12.5px;
                        }
                    }
                    .webform-component--wxt-group--complaint-details-acc--what-happened-title {
                        padding-top: 0;
                        .form-textarea {
                            background-color: $secondary-blue;
                        }
                    }
                    //last and third components of forms
                    .webform-component--wxt-group--complaint-details-acc--flight-details-1,
                    .webform-component--wxt-group--complaint-details-acc--flight-details-2,
                    .webform-component--wxt-group--complaint-details-acc--flight-details-3,
                    .webform-component--wxt-group--complaint-details-acc--flight-details-4,
                    .webform-component--wxt-group--complaint-details-acc--flight-details-5 {
                        .panel-body {
                            // display: none;
                            width: 100%;
                            // display: none;
                            // .webform-component--wxt-group--complaint-details-acc--t3-row1--t1-row1-left--flight-details {
                            padding-top: 0;
                            .control-label {
                                font-size: 14px;
                                font-weight: inherit;
                                margin-bottom: 15px;
                                // display: block;
                            }
                            .form-control {
                                background-color: $secondary-blue;
                            }
                            .webform-component--wxt-group--complaint-details-acc--flight-details-1--airline-1 {
                                margin-bottom: 39px;
                                @include bp(s) {
                                    margin-bottom: 39px;
                                }
                                @include bp(m) {
                                    margin-bottom: 29px;
                                }
                                @include bp(l) {
                                    margin-bottom: 39px;
                                }
                            }
                            //input element needs to be visible to show proper html5 validation feedback
                            #edit-submitted-wxt-group-complaint-details-acc-flight-details-1-airline-1 {
                                position: absolute;
                                display: inherit!important;
                                @media screen and (min-width: 300px) and (max-width: 767px) {
                                    width: 91.5%;
                                    max-width: inherit;
                                }
                                @include bp(s) {
                                    width: 96%;
                                    max-width: inherit;
                                }
                                @include bp(m) {
                                    width: 97%;
                                    max-width: inherit;
                                }
                                @include bp(l) {
                                    width: 97.5%;
                                    max-width: inherit;
                                }
                            }
                            //select list for airline dropdown
                            #edit-submitted-wxt-group-complaint-details-acc-t3-row1-t1-row1-left-flight-details-airline {
                                width: 100%;
                                // background-color: $secondary-blue;
                                @include bp(s) {
                                    width: 100%;
                                }
                                @include bp(m) {
                                    width: 40%;
                                }
                                @include bp(l) {
                                    width: 40%;
                                }
                            }
                            //flight number text field
                            .webform-component--wxt-group--complaint-details-acc--flight-details--flight-number,
                            .webform-component-textfield,
                            .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-number {
                                display: inline-block;
                                max-width: 100%;
                                width: 100%;
                                margin-left: 0;
                                @include bp(s) {
                                    max-width: 40%;
                                }
                                @include bp(m) {
                                    max-width: 30%;
                                }
                                @include bp(l) {
                                    max-width: 20%;
                                }
                                .control-label {
                                    display: block;
                                }
                                .form-text {
                                    width: 100%;
                                }
                            }
                            //date picker for flight date
                            .webform-component--wxt-group--complaint-details-acc--flight-details--flight-date,
                            .webform-component-date,
                            .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-date {
                                display: inline-block;
                                max-width: 100%;
                                // width: 100%;
                                margin-left: 0px;
                                .webform-datepicker {
                                    .form-item .form-select {
                                        max-width: 29%;
                                        @include bp(m) {
                                            max-width: 100%;
                                        }
                                        @include bp(l) {
                                            max-width: 100%;
                                        }
                                    }
                                    .hasDatepicker {
                                        margin-left: 0px;
                                        max-width: 35px;
                                        @include bp(m) {
                                            margin-left: 10px;
                                            max-width: 35px;
                                        }
                                        @include bp(l) {
                                            margin-left: 10px;
                                            max-width: 35px;
                                        }
                                    }
                                }
                                @include bp(s) {
                                    display: inline-block;
                                    max-width: 46%;
                                    margin-left: 40px;
                                }
                                @include bp(m) {
                                    display: inline-block;
                                    max-width: 60%;
                                    width: 60%;
                                    margin-left: 40px;
                                }
                                @include bp(l) {
                                    display: inline-block;
                                    max-width: 60%;
                                    width: 60%;
                                    margin-left: 40px;
                                }
                                .control-label {
                                    display: block;
                                }
                            }
                            //flight from city field
                            .webform-component--wxt-group--complaint-details-acc--flight-details--flight-from,
                            .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-from {
                                width: 100%;
                                display: block;
                                margin-left: 0;
                                @include bp(s) {
                                    width: 50%;
                                    display: inline-block;
                                    padding-right: 20px;
                                }
                                @include bp(m) {
                                    width: 50%;
                                    display: inline-block;
                                    padding-right: 20px;
                                }
                                @include bp(l) {
                                    display: inline-block;
                                    width: 50%;
                                    padding-right: 20px;
                                    max-width: 35%;
                                }
                                .form-text {
                                    width: 100%;
                                }
                            }
                            //flight to city field
                            .webform-component--wxt-group--complaint-details-acc--flight-details-1--flight-to-1 {
                                @include bp(s) {
                                    margin-left: 40px;
                                }
                                @include bp(m) {
                                    margin-left: 40px;
                                }
                                @include bp(l) {
                                    margin-left: 40px;
                                }
                            }
                            .webform-component--wxt-group--complaint-details-acc--flight-details--flight-to,
                            .webform-component--wxt-group--complaint-details-acc--flight-details-2--flight-to {
                                width: 100%;
                                display: block;
                                margin-left: 0;
                                @include bp(s) {
                                    width: 50%;
                                    display: inline-block;
                                }
                                @include bp(m) {
                                    width: 50%;
                                    display: inline-block;
                                }
                                @include bp(l) {
                                    display: inline-block;
                                    width: 50%;
                                    max-width: 35%;
                                }
                                .form-text {
                                    width: 100%;
                                }
                            }
                            .webform-component--wxt-group--complaint-details-acc--flight-details--add-a-flight-1 {
                                .control-label {
                                    margin-top: 15px;
                                    font-size: 14px;
                                    font-weight: inherit;
                                    margin-bottom: 0px;
                                    // display: block;
                                }
                                .form-type-checkbox {
                                    margin-top: 0;
                                    .control-label {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .form-item-submitted-wxt-group-complaint-details-acc-flight-details-add-a-flight-1-1 {
                                .control-label {
                                    font-size: 14px;
                                    &:before {
                                        content: none;
                                    }
                                }
                            }
                            .webform-component--wxt-group--complaint-details-acc--flight-details--airline,
                            .webform-component--wxt-group--complaint-details-acc--flight-details-2--airline {
                                max-width: 100%;
                                @include bp(s) {
                                    max-width: 70%;
                                }
                                @include bp(m) {
                                    max-width: 70%;
                                }
                                @include bp(l) {
                                    max-width: 70%;
                                }
                            }
                            .chosen-container-single {
                                width: 100%!important;
                                // max-width: 70%;
                                padding: 0;
                                border: none;
                                box-shadow: none;
                                // display: none!important;
                                .chosen-single {
                                    background: none;
                                    padding-top: 6px;
                                    height: 37px;
                                    &>div {
                                        top: 5px;
                                    }
                                }
                                .chosen-drop {
                                    background-color: #ebf8fa;
                                }
                                .chosen-search {
                                    input {
                                        background-color: white;
                                    }
                                }
                                .no-results {
                                    background-color: white;
                                }
                            }
                            // }
                        }
                    }
                    //Contact information applicant
                    .webform-component--wxt-group--complaint-details-acc--applicant-title {
                        // display: none;
                        padding-top: 0;
                        .panel-body {
                            #addressfield-wrapper {
                                padding-top: 0;
                            }
                            // .webform-component-addressfield {
                            // margin-top: 0;
                            .control-label {
                           
                                font-size: 14px;
                                font-weight: inherit;
                            }
                            .form-item {
                                max-width: 100%;
                                width: 100%;
                                margin-left: 0;
                                display: block;
                                float: none;
                                @include bp(s) {
                                    max-width: 70%;
                                    margin-left: 0;
                                    display: block;
                                    float: none;
                                }
                                @include bp(m) {
                                    max-width: 70%;
                                    margin-left: 0;
                                    display: block;
                                    float: none;
                                }
                                @include bp(l) {
                                    max-width: 70%;
                                    margin-left: 0;
                                    display: block;
                                    float: none;
                                }
                                .form-control {
                                    width: 100%;
                                    background-color: $secondary-blue;
                                }
                                &.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-administrative-area {
                                    // display: none;
                                    @include bp(m) {
                                        max-width: 34%;
                                        margin-left: 0;
                                        display: inline-block;
                                        float: none;
                                    }
                                    @include bp(l) {
                                        max-width: 34%;
                                        margin-left: 0;
                                        display: inline-block;
                                        float: none;
                                    }
                                    .form-control {
                                        @include bp(m) {
                                            width: 100%;
                                        }
                                        @include bp(l) {
                                            width: 100%;
                                        }
                                    }
                                }
                                &.form-item-submitted-wxt-group-complaint-details-acc-applicant-title-applicant-postal-code {
                                    @include bp(m) {
                                        max-width: 34%;
                                        margin-left: 0;
                                        display: inline-block;
                                        // float: left;
                                    }
                                    @include bp(l) {
                                        max-width: 34%;
                                        margin-left: 0;
                                        display: inline-block;
                                        // float: left;
                                    }
                                    .form-control {
                                        @include bp(m) {
                                            width: 100%;
                                        }
                                        @include bp(l) {
                                            width: 100%;
                                        }
                                    }
                                    // display: none;
                                }
                            }
                            // }
                        }
                        .op-required .control-label {
                            margin-left: 12.5px;
                            &:before {
                                content: "* ";
                                margin-left: -.665em;
                                color: #d3080c;
                                font-weight: 700;
                            }
                        }
                    }
                    //baggage information check
                    .webform-component--wxt-group--complaint-details-acc--baggage-information {
                        padding-top: 0;
                        .webform-component-textfield {
                            // max-width: 70%;
                            margin: 15px 0;
                            max-width: 100%;
                            @include bp(s) {
                                max-width: 70%;
                            }
                            @include bp(m) {
                                max-width: 70%;
                            }
                            @include bp(l) {
                                max-width: 70%;
                            }
                        }
                        .control-label {
                            font-size: 14px;
                            font-weight: inherit;
                        }
                        .form-control {
                            width: 100%;
                            background-color: $secondary-blue;
                        }
                    }
                    .webform-component--wxt-group--complaint-details-acc--details-documents {
                        border-bottom: none;
                        .webform-component-markup {
                            max-width: 550px;
                        }
                        .help-block {
                            margin-top: 15px;
                            color: black;
                            strong {
                                font-weight: 400;
                            }
                        }
                        .webform-component.webform-component-file {
                            // display: none;
                            .form-managed-file {
                                // display: none;
                                .form-file {
                                    background-color: $secondary-blue;
                                    height: auto;
                                    padding: 40px;
                                    border: none;
                                    // padding: 30px;
                                    // display: none;
                                }
                                .btn-primary.form-submit {
                                    border: 1px solid $primary-red;
                                    color: black;
                                    background-color: #9BD3DD;
                                    padding: 8px 20px;
                                    border-radius: 3px;
                                    font-size: 20px;
                                    margin-top: 15px;
                                    &:hover,
                                    &:focus {
                                        background-color: $tertiary-blue;
                                    }
                                    .glyphicon-upload {
                                        &:before {
                                            content: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .webform-component--wxt-group--communications-acc--t2-row1 {
                        // .control-label {
                        //     position: relative;
                        //     &:before {
                        //         position: absolute;
                        //         left: 0;
                        //         content: ' ';
                        //         width: 20px;
                        //         height: 20px;
                        //         border-radius: 45px;
                        //         background-color: $secondary-blue;
                        //     }
                        // }
                        // .composite-form-label {
                        //     &:before {
                        //         content: none;
                        //     }
                        // }
                        .form-radio {
                            // visibility: hidden;
                            padding: 20px;
                            background-color: $secondary-blue;
                        }
                    }
                }
                &:nth-child(3) {
                    // display: none;
                    .tgl-panel {
                        padding: 0;
                    }
                }
            }
            //what is your complaint about
            .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-left {
                .control-label {
                    font-size: 20px;
                }
            }
             //alert info block
            .webform-component--wxt-group--complaint-subject-acc--t1-row1--t1-row1-right {
                padding-right: 15px;
                @include bp(s) {
                    padding-right: 15px;
                }
                @include bp(m) {
                    padding-right: 0;
                }
                @include bp(l) {
                    padding-right: 0;
                }
            }
        }

        //CHECKBOXES FORM ELEMENT STYLING
        .form-checkboxes {
            box-sizing: border-box;
            .control-label {
                position: relative;
                // text-indent: -25px;
                margin-left: 20px;
                padding-left: 28px;
                padding-top: 5px;
                // font-size: 14px!important;
            }
            //new checkboxes
            label input[type="checkbox"] {
                // visibility: hidden;
                // opacity: 1;
                // position: absolute;
                // top: 0;
                // left: -50px;
                // display: inline-block;
                // display: inline-block;
                // margin-top: 0px;
                // width: 30px;
                // height: 30px;
                // background: #fcfcfc;
                // background: $secondary-blue;
                // border: 10px solid black;
                // border-radius: 3px;
                // display: none;
                margin-left: -39px;
            }
            label span {
                position: absolute;
                top: 0;
                left: -19px;
                display: inline-block;
                display: inline-block;
                margin-top: 0px;
                width: 30px;
                height: 30px;
                background: #fcfcfc;
                background: $secondary-blue;
                border: 1px solid black;
                border-radius: 3px;
            }
            label :checked+span {
                position: absolute;
                left: -19px;
            }
            label :checked+span:after {
                content: ' ';
                font-size: 20px;
                position: absolute;
                top: 12px;
                left: 3px;
                color: #99a1a7;
                width: 4px;
                height: 4px;
                box-shadow: 4px 0 0 rgb(0, 0, 0), 8px 0 0 rgb(0, 0, 0), 8px -4px 0 rgb(0, 0, 0), 8px -8px 0 rgb(0, 0, 0), 8px -12px 0 rgb(0, 0, 0), 8px -16px 0 rgb(0, 0, 0);
                transform: rotate(45deg);
            }
        }
        //INPUT BACKGROUND
        textarea,
        select,
        input {
            background-color: $secondary-blue;
        }
        input.form-file {
            height: 58px;
            padding-top: 17px;
        }
        .radio label {
            padding-left: 53px;
        }
        //RADIOS FORM ELEMENT STYLING
        .webform-component-multiple-file {
            //.form-managed-file {
            .help-block {
                color: #585858;
            }
            //}
        }
        .form-group {
            .composite-form-label {
                font-size: 20px;
            }
        }
        // .webform-component-radios {
        // .form-type-radio {
        .form-radios {
            // visibility: hidden;
            box-sizing: border-box;
            margin-bottom: 25px;
            .control-label {
                margin-top: 10px;
                position: relative;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.4;
                // text-indent: -25px;
                // margin-left: 20px;
                // padding-left: 48px;
            }
            .form-radio {
                    visibility: hidden;
            }
            //new radios
            .form-type-radio {

                label {
                    padding-left: 48px;
                }
            }
            label input[type="radio"] {
                // display: none;
                // margin-left: -38px;
                // margin-top: 5px;
                margin-left: -45px;
                margin-top: 5px;
            }
            label span {
                position: absolute;
                top: 0;
                left: 0px;
                display: inline-block;
                margin-top: 0px;
                width: 31.5px;
                height: 31.5px;
                background: #fcfcfc;
                background: $secondary-blue;
                border: 1px solid black;
                border-radius: 25px;
            }
            label :checked+span {
                position: absolute;
                left: 0px;
            }
            label :checked+span:after {
                content: ' ';
                font-size: 20px;
                position: absolute;
                top: 12px;
                left: 3px;
                color: #99a1a7;
                width: 4px;
                height: 4px;
                box-shadow: 4px 0 0 rgb(0, 0, 0), 8px 0 0 rgb(0, 0, 0), 8px -4px 0 rgb(0, 0, 0), 8px -8px 0 rgb(0, 0, 0), 8px -12px 0 rgb(0, 0, 0), 8px -16px 0 rgb(0, 0, 0);
                transform: rotate(45deg);
            }
        }
        .messages.warning {
            margin-bottom: 15px;
        }
        strong.form-required {
            color: #820101 !important;
        }
        .control-label.op-required {
            margin-left: 12.5px;
            &:before {
                content: "* ";
                margin-left: -.665em;
                color: #820101;
                font-weight: 700;
            }
        }
        .control-label {
            font-size: 14px;
            font-weight: inherit;
        }
    }
    
    // .no-details details[open] {
    //     display: block!important;
    // }
    // .no-details details summary {
    //     display: block!important
    // }
    // .no-details[dir=rtl] details>summary:before {
    //     content: " "!important;
    // }
