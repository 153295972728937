.node-type-right {
    .region-content {
        .row {
            .col-md-1.title-left {
                .pane-node-field-icon {
                    .image-actual {
                        // display: none;
                        @include bp(xs) {
                            margin: 0 auto;
                            margin-bottom: 25px;
                        }
                        @include bp(s) {
                            margin: 0 auto;
                            margin-bottom: 25px;
                        }
                        @include bp(m) {}
                        @include bp(l) {}
                    }
                }
            }
            .col-md-11.title-right {
                margin-bottom: 50px;
                .pane-content {
                    h1 {
                        margin-top: 0;
                        border-bottom: none;
                        margin-bottom: 10px;
                        padding-bottom: 0;
                        @include bp(xs) {
                            text-align: center;
                            margin-bottom: 25px;
                        }
                        @include bp(s) {
                            text-align: center;
                            margin-bottom: 25px;
                        }
                        @include bp(m) {
                            text-align: left;
                        }
                        @include bp(l) {
                            text-align: left;
                        }
                    }
                }
            }
            .field-name-field-short-description {
                @include bp(xs) {
                    // text-align: center;
                }
                @include bp(s) {
                    // text-align: center;
                }
                @include bp(m) {
                    text-align: left;
                }
                @include bp(l) {
                    text-align: left;
                }
            }
            .field-name-title-field.field-label-hidden {
                h2 {
                    display: none;
                }
            }
            .pane-node-body {
                .accordion {
                    .acc-group {
                        position: relative;
                        visibility: inherit;
                        background-color: $tertiary-blue;
                        background-color: $light-blue;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding: 0;
                        width: 100%;
                        summary.wb-toggle-inited {
                            background-color: $secondary-blue;
                            font-size: 22px;
                            list-style: none;
                            color: black;
                            font-weight: 600;
                            border: none;
                            padding: 0;
                            width: 100%;
                            margin-right: 0;
                            margin-left: 0;
                            padding: 20px;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            margin-bottom: 0;
                            border-bottom: 10px solid $primary-red;
                            &:after {
                                position: absolute;
                                top: 25px;
                                right: 15px;
                                content: ' ';
                                margin-left: 15px;
                                width: 20px;
                                height: 20px;
                                background-image: url("../images/icons/up_arrow.png");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                display: inline-block;
                                @include bp(s) {
                                    top: 22px;
                                    font-size: 19px;
                                }
                                @include bp(m) {
                                    top: 20px;
                                    font-size: 19px;
                                }
                                @include bp(l) {
                                    top: 20px;
                                    font-size: 19px;
                                }
                            }
                            &::-webkit-details-marker {
                                display: none;
                                // float: right;
                                // list-style-image: url("../images/icons/down_arrow.png");
                            }
                        }
                        .tgl-panel {
                            padding: 20px;
                            background-color: white;
                        }
                        &.on {
                            background-color: $tertiary-blue;
                            border: none;
                            summary.wb-toggle-inited {
                                border: none;
                                color: black;
                                background-color: $tertiary-blue;
                                border-bottom: 10px solid $primary-red;
                                &:after {
                                    position: absolute;
                                    top: 25px;
                                    right: 15px;
                                    content: ' ';
                                    margin-left: 15px;
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("../images/icons/down_arrow.png");
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    display: inline-block;
                                    @include bp(s) {
                                        top: 22px;
                                        font-size: 19px;
                                    }
                                    @include bp(m) {
                                        top: 20px;
                                        font-size: 19px;
                                    }
                                    @include bp(l) {
                                        top: 20px;
                                        font-size: 19px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}