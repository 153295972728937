main {
    .front & {
        padding-top: 70px;
    }
    .region-content {
        @include bp(xs) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}