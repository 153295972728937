#wb-sm {
    // background: $primary;
    // color: $white;
}

#wb-sm .menu>li a:focus,
#wb-sm .menu>li a:hover {
    // background: $secondary;
}

#wb-sm .menu .active,
#wb-sm .menu .selected,
#wb-sm .menu .wb-navcurr {
    // background: $secondary;
}

#wb-srch button,
.srchbox button {
    background: $secondary;
}

header .brand img,
header .brand object {
    max-height: 80px;
    @include bp(xs) {
        max-width: 100%;
    }
}

header {
    background-color: $primary-blue;
    @include bp(xs) {
        padding-top: 25px;
    }
    .brand {
        @include bp(s) {
            margin-top: 0px;
        }
        @include bp(m) {
            margin-top: -20px;
        }
        @include bp(l) {
            margin-top: -35px;
            margin-bottom: 25px;
        }
        a {}
    }
}