.media-element-container {
    .file-video {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        .media-youtube-player {
            width: 100%;
            height: auto;
            // min-height: inherit;
            @include bp(s) {
                min-height: 397px;
            }
            @include bp(m) {
                min-height: 320px;
            }
            @include bp(l) {
                min-height: 420px;
                margin-bottom: 20px;
            }
        }
    }
}

.panel-pane.pane-node-body {
    .field-name-body {
        button.btn-primary {
            width: 100%;
            background-color: transparent;
            position: relative;
            background-color: $primary-red;
            color: white;
            width: 100%;
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 19px;
            border: 2px solid transparent;
            background-color: white;
            color: $primary-red;
            font-size: 19px;
            border: 2px solid $primary-red;
            border-radius: 3px;
            margin-top: 10px;
            margin-bottom: 5px;
            @include bp(s) {
                margin-top: 10px;
                margin-bottom: 5px;
            }
            @include bp(m) {
                margin-top: 10px;
                margin-bottom: 5px;
            }
            @include bp(l) {
                margin-top: 5px;
                margin-bottom: 5px;
            }
            &:hover,
            &:focus {
                border: 2px solid $primary-red;
                background-color: white;
                color: $primary-red;
            }
            &:before {
                position: absolute;
                top: 11.5px;
                right: inherit;
                content: ' ';
                margin-left: -20px;
                width: 17px;
                height: 17px;
                background-image: url("../images/icons/left_arrow_red.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                display: inline-block;
                font-size: 19px;
                @include bp(s) {
                    top: 12.5px;
                    right: inherit;
                }
                @include bp(m) {
                    top: 12.5px;
                    right: inherit;
                }
                @include bp(l) {
                    top: 12.5px;
                    right: inherit;
                }
            }
        }
        button.btn-default {
            width: 100%;
            background-color: $primary-red;
            position: relative;
            background-color: $primary-red;
            color: white;
            width: 100%;
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 19px;
            border: 2px solid transparent;
            border-radius: 3px;
            margin-top: 10px;
            margin-bottom: 5px;
            @include bp(s) {
                margin-top: 10px;
                margin-bottom: 5px;
            }
            @include bp(m) {
                margin-top: 10px;
                margin-bottom: 5px;
            }
            @include bp(l) {
                margin-top: 5px;
                margin-bottom: 5px;
            }
            &:hover,
            &:focus {
                border: 2px solid $primary-red;
                background-color: white;
                color: $primary-red;
                &:after {
                    background-image: url("../images/icons/right_arrow_red.png");
                }
            }
            &:after {
                position: absolute;
                top: 11.5px;
                right: inherit;
                content: ' ';
                margin-left: 5px;
                width: 17px;
                height: 17px;
                background-image: url("../images/icons/right_arrow_white.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                display: inline-block;
                font-size: 19px;
                @include bp(s) {
                    top: 12.5px;
                    right: inherit;
                }
                @include bp(m) {
                    top: 12.5px;
                    right: inherit;
                }
                @include bp(l) {
                    top: 12.5px;
                    right: inherit;
                }
            }
        }
    }
}