header {
    .field-name-field-bean-wetkit-body {
        padding-left: 20px;
        padding-right: 20px;
        min-height: 355px;
        @include bp(m) {
            min-height: 420px;
        }
        @include bp(l) {
            min-height: 300px;
        }
        .row {
            .hero-header,
            .hero-heading {
                margin-top: 25px;
                color: black;
                font-size: 46px;
                font-weight: bold;
                line-height: initial;
            }
            p {
                margin-top: 25px;
                font-size: 18px;
            }
            .col-md-6,
            .col-md-3 {
                .media-element-container {
                    position: relative;
                    .file-image {
                        img.img-responsive {
                            margin-top: 40px;
                            margin-bottom: 40px;
                            @include bp(m) {
                                position: absolute;
                                top: -50px;
                                margin-top: 40px;
                                margin-bottom: inherit;
                            }
                            @include bp(l) {
                                position: absolute;
                                top: -135px;
                                right: -80px;
                                margin-top: 40px;
                                margin-bottom: inherit;
                                max-width: 110%;
                                width: 110%;
                                // top: -150px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//NEW HERO HEADER
header {
    // .field-name-field-bean-wetkit-body {
    //     padding-left: 20px;
    //     padding-right: 20px;
    //     min-height: 355px;
    //     @include bp(m) {
    //         min-height: 420px;
    //     }
    //     @include bp(l) {
    //         min-height: 300px;
    //     }
    .row.hero-block {
        padding-left: 20px;
        padding-right: 20px;
        min-height: 220px;
        @include bp(m) {
            min-height: 220px;
        }
        @include bp(l) {
            min-height: 220px;
        }
        .hero-left {
            padding-left: 0;
        }
        .hero-header,
        .hero-heading {
            //margin-top: 55px;
            color: black;
            font-size: 37px;
            font-weight: bold;
            line-height: initial;
            margin-top: 0;
            @include bp(s) {
                font-size: 46px;
            }
            @include bp(m) {
                font-size: 46px;
            }
            @include bp(l) {
                font-size: 46px;
            }
            .front & {
                @include bp(l) {
                    //max-width: 277px;
                }
            }
        }
        p {
            // margin-top: 25px;
            font-size: 18px;
        }
        .col-md-6 {
            .hero-wordmark{
              width: 100px;
              position: absolute;
              top: 120px;
              right: -40px;
              margin-top: 40px;
              margin-bottom: inherit;
              @include bp(s) {
                float: right;
                position: static;
                margin-top: 0;
                margin-bottom: 20px;
              }
              @include bp(xs) {
                float: right;
                position: static;
                margin-top: 0;
                margin-bottom: 20px;
              }
            }
            .media-element-container,
            .field-name-field-hero-image {
                position: relative;
                .file-image,
                .field-item {
                    img.img-responsive {
                        margin-top: 40px;
                        margin-bottom: 40px;

                        @include bp(m) {
                            position: absolute;
                            top: -50px;
                            margin-top: 40px;
                            margin-bottom: inherit;
                        }
                        @include bp(l) {
                            position: absolute;
                            top: -110px;
                            right: -60px;
                            margin-top: 40px;
                            margin-bottom: inherit;
                            max-width: 100%;
                            width: 100%;
                            // top: -150px;
                        }
                    }
                }
            }
        }
    }
    // }
}
