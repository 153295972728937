.view-notices-list {
    margin-bottom: 50px;
    .view-header {
        a {
            text-transform: uppercase;
            color: black;
            position: absolute;
            right: 0;
            top: 0;
            &:hover {
                color: $primary-red;
            }
        }
    }
    .view-content {
        .views-row {
            margin-bottom: 25px;
            background-color: $light-blue;
            padding-top: 15px;
            padding-bottom: 15px;
            padding: 15px;
            .views-field-title-field {
                font-weight: 600;
                margin-bottom: 15px;
                margin-top: 15px;
                @include bp(xs) {
                    margin-top: inherit;
                    margin-top: 15px;
                }
                @include bp(m) {
                    margin-top: inherit;
                }
                @include bp(l) {
                    margin-top: inherit;
                }
                a {
                    color: black;
                }
            }
            .views-field-field-featured-image {
                .image-actual {
                    width: 100%;
                    display: none;
                    @include bp(s) {
                        display: none;
                    }
                    @include bp(m) {
                        display: inherit
                    }
                    @include bp(l) {
                        display: inherit
                    }
                }
            }
            .views-field-body {
                span {
                    line-height: 1.59;
                }
            }
            .views-field-field-published-date {
                font-weight: 700;
            }
            .views-field-nid {
                .btn-default {
                    // font-size: 14px;
                    color: black;
                    text-transform: uppercase;
                    border: none;
                    background-color: inherit;
                    padding: 0;
                    margin-top: 15px;
                    text-decoration: underline;
                    &:hover {
                        color: $primary-red;
                        &:after {
                            content: ' ';
                            margin-left: 10px;
                            width: 13px;
                            height: 13px;
                            background-image: url("../images/icons/right_arrow_red.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            display: inline-block;
                        }
                    }
                    &:after {
                        content: ' ';
                        margin-left: 10px;
                        width: 13px;
                        height: 13px;
                        position: initial;
                        background-image: url("../images/icons/right_arrow_black.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.pane-notices-list-panel-pane-1 {
    margin-top: 50px;
    position: relative;
    .pane-title {
        font-size: 22px;
        text-transform: uppercase;
        color: black;
    }
}