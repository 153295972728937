////////////////////////
// Breakpoints Mixins //
////////////////////////
@mixin bp($p) {
   @if $p == l{
    @media (min-width: 1200px) {
       @content;
    }
  }
  @else if $p == m {
     @media (min-width: 992px) and (max-width: 1199px){
      @content;
    }
  }
  @else if $p == s {
     @media (min-width: 768px) and (max-width: 991px){
      @content;
    }
  }
  @else if $p == xs {
     @media (max-width: 767px){
      @content;
    }
  }
}
// This is the proper usage of breakpoints plugin
// .container { width: 800px;
//     @include bp(l) { width: 700px;}
//     @include bp(m) { width: 500px;}
//     @include bp(s) { width: 300px;}
//     @include bp(xs) { width: 100px;}
// }
//////////////////////////
// Border Radius Mixins //
//////////////////////////
@mixin br($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}
@mixin br-top($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin br-right($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}
@mixin br-bottom($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin br-left($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
///////////////////
// Bouncing Mixin //
///////////////////
@-webkit-keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0.0);
  }

  40% {
    -webkit-transform: scale(1.0);
  }
}
@keyframes bouncedelay {
  0%,
  100%,
  80% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
///////////////////
// Opacity Mixin //
///////////////////
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
// Usage for opacity
// @include opacity(0.8);
///////////////////////////////////
// Clearfix Function / extention //
///////////////////////////////////

%clearfix {
  &:after,
  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}
// Sidebar Right Mixins
@mixin sidebar-right-dark {
  background: $charcol;
  padding: 45px 40px;
  color: $white;
  position: relative;
  overflow: hidden;
  @include bp(xs) {
    margin-left: -30px;
    margin-right: -30px;
    padding: 45px 30px;
  }

  a {
    color: $white;
  }

  h2 {
    line-height: 36px;
    font-size: 30px;
  }
  // &:after {
  //   position: absolute;
  //   left:100%;
  //   top:0;
  //   width:calc((100vw - 1110px)/2);
  //   content:'';
  //   background:$charcol;
  //   height:100%;
  //   @include bp(m){
  //     width:calc((100vw - 910px)/2);
  //   }
  // }
}
@mixin sidebar-right-cta {
  background: $neutral-blue;
  padding: 40px;
  color: $white;
  position: relative;
  @include bp(xs) {
    margin-left: -30px;
    margin-right: -30px;
  }

  a {
    color: $white;

    &.list-link {
      display: block;

      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -5px;
        color: $white;
        font: normal normal normal 16px/1 FontAwesome;
        content: "\f105";
        @include bp(xs) {
          right: 30px;
        }
      }
    }
  }

  h2 {
    line-height: 36px;
    font-size: 30px;
  }
  // &:after {
  //   position: absolute;
  //   left:100%;
  //   top:0;
  //   width:calc((100vw - 1110px)/2);
  //   content:'';
  //   background:$neutral-blue;
  //   height:100%;
  //   @include bp(m){
  //     width:calc((100vw - 910px)/2);
  //   }
  // }
}
@mixin sidebar-right-light {
  background: $light;
  padding: 40px;
  color: $black;
  position: relative;

  a {
    color: $black;
  }

  h2 {
    margin-bottom: 0;
  }
  @include bp(xs) {
    margin-left: -30px;
    margin-right: -30px;
  }
}
@mixin sidebar-right-blue {
  background: $education;
  padding: 40px;
  color: $white;
  position: relative;

  a {
    color: $white;
  }

  h2 {
    margin-bottom: 0;
  }
  @include bp(xs) {
    margin-left: -30px;
    margin-right: -30px;
  }
  @include bp(s) {
    margin-left: -30px;
    margin-right: -30px;
  }
}
// Sans serif mixin
@mixin sans-serif {
  font-family: 'Open Sans', sans-serif;
}
@mixin theme-selection {
  .themetabs-active {
    ul {
      li {
        a:not(.active) {
          display: none;
        }
      }
    }
  }

  .themetabs-inactive {
    ul {
      li {
        a.active {
          display: none;
        }
      }
    }
  }
}
