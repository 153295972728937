$white: #ffffff;
$black: #000000;
$primary: #b0151e;
$secondary: #7a0806;
$yellow: #EEDE6F;
//new Branding Colours
$primary-blue: rgba(194, 229, 244, 0.8);
$secondary-blue: rgba(235, 248, 250, 1);
$tertiary-blue: #B9E0E3;
$light-blue: #F2F8F8;
$primary-red: #802F2D;