a {
    position: relative;
    &:hover {
        color: $primary-red;
    }
    &:visited {
        color: inherit;
    }
    &.btn.active,
    &.btn.btn-default {
        border: 2px solid transparent;
        margin-top: 10px;
        margin-right: 10px;
        background-color: $primary-red;
        color: white;
        padding-left: 20px;
        padding-right: 35px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 500;
        &:hover,
        &:focus {
            border: 2px solid $primary-red;
            background-color: white;
            color: $primary-red;
            &:after {
                background-image: url("../images/icons/right_arrow_red.png");
            }
        }
        &:after {
            position: absolute;
            top: 11.5px;
            right: inherit;
            right: 10px;
            content: ' ';
            margin-left: 5px;
            width: 17px;
            height: 17px;
            background-image: url("../images/icons/right_arrow_white.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: inline-block;
            font-size: 19px;
            @include bp(s) {
                top: 10.5px;
                right: inherit;
                right: 10px;
            }
            @include bp(m) {
                top: 10.5px;
                right: inherit;
                right: 10px;
            }
            @include bp(l) {
                top: 10.5px;
                right: inherit;
                right: 10px;
            }
        }
    }
}

details {
    summary {
        color: black;
        &:hover {
            color: $primary-red;
        }
    }
}